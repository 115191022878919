@import '../../_config.sass';

.UserCard {
  display: none;
  @media (min-width: $bp-desktop) {
    box-sizing: border-box!important;
    display: block;
    width: 100%;
    padding: 1.5rem;
    border-radius: .75rem;
    border: 1px solid #DDD; }
  &__avatar {
    max-width: 128px;
    max-height: 128px;
    height: 128px;
    width: 128px;
    margin: 0 auto 2rem auto;
    &__img {
      border-radius: 100%;
      width: 128px;
      height: 128px;
      object-fit: cover; } } }
