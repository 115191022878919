@import "../config";
.wpp_iconMobile {
    position: absolute;
    display: block;
    width: 60%;
    height: 60%;
    line-height: 55px;
    text-align: center; }


.whatsappIconBelow {
    display: block;
    position: fixed;
    bottom: 8px;
    right: 29px;
    z-index: 200; }

.conteinerWppMobile {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 50px;
    z-index: 900;
    width: max-content;
    @media (max-width: $bp-cell) {
        right: 2%;
        z-index: 10; } }

.whatsappImageMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    height: 9vw;
    width: 9vw;
    text-align: center;
    background: rgb(0,168,45);
    box-shadow: 0 5px 8px rgb (0 0 0 / 30%);
    line-height: 55px;
    cursor: pointer; }

.animationMobile {
    position: relative;
    top: 0;
    right: 0;
    border: 1px solid rgb(0,168,45)!important;
    background: none;
    display: inline-block;
    border-radius: 100%;
    width: 9vw;
    height: 9vw;
    text-decoration: none;
    animation-name: pulse;
    animation-duration: 2.2s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite; }

@keyframes pulse {
    50% {
        transform: scale(1,1);
        opacity: 1; }
    100% {
        transform: scale(2.0,2.0);
        opacity: 0; } }

@media (max-width: 600px) {

    .conteinerWppMobile {
        bottom: 3%;
        right: 20px;
        width: max-content; }

    .whatsappImageMobile {
        height: 50px;
        width: 50px; }


    .wpp_iconMobile {
        width: 60%;
        height: 60%; }

    .animationMobile {
        top: 0;
        right: 0;
        width: 50px;
        height: 50px; }

    .wbcomWhatsappImageTop {
        display: block;
        position: fixed;
        bottom: 32px!important;
        right: 29px; }

    .whatsappIconTop {
        display: block;
        position: fixed;
        bottom: 32px!important;
        right: 29px;
        z-index: 9999; } }
