@import '../config';

@mixin mobileContainer {
    width: 90%;
    height: 100vh;
    max-width: 100vw;
    margin: 0 auto; }

.IntermediateStep {
    @include mobileContainer;
    display: flex;
    flex-flow: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    &__title {
        text-align: center; }
    &__container {
        box-sizing: border-box;
        max-width: 100%;
        width: 100%;
        margin: 1rem 0;
        padding: 1rem 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 100px;
        border: 1px solid #E2E2E2;
        div {
            div {
                div {
                    div {
                        &:nth-child(3) {
                            margin: 0 5px; } } } } } }

    &__dates,
    &__people {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        div {
            label {
                margin-bottom: 1px; } } }
    &__dates {}
    &__people {
        .input_wrapper {
            display: flex;
            align-items: center;
            color: $colorNaranja;
            font-weight: 500;
            font-size: .85rem;
            position: relative;
            width: 100%;
            border-bottom: 1px solid $colorNaranja; }
        .person_select {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            box-sizing: border-box;
            cursor: pointer;
            width: 100%;
            margin-left: 8px;
            padding-right: 8px;
            @media (min-width: $bp-desktop) {
                justify-content: center;
                min-height: none;
                max-width: 200px;
                margin: 0;
                padding: 0;
                height: auto; } }
        .personas_wrapper {
            display: flex;
            flex-direction: column; }
        .close {
            display: none;
            width: 25px;
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer; }
        hr {
            margin-top: 15px;
            margin-bottom: 15px; }
        .opciones_personas {
            display: flex;
            flex-wrap: no-wrap;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .options {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                justify-content: space-between; }
            .contador {
                font-size: 14px; } }
        .round_boton {
            color: #fff;
            margin-left: 10px;
            margin-right: 10px;
            background: $colorNaranja;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            border-width: 0px;
            font-weight: bold; }
        .lista_personas {
            position: absolute;
            top: 120%;
            right: 0%;
            width: 100px;
            background: #fff;
            padding: 20px;
            display: none;
            position: absolute;
            box-shadow: 0px 1px 6px -3px #111;
            z-index: 99;
            width: 70vw;
            @media (min-width: $bp-desktop) {
                top: 110%;
                width: 100px; }
            .close {
                display: block; } } }
    &__cta {
        @include CTAOnLanding;
        background: $colorNaranja;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        padding: 14px 24px;
        margin: 0;
        width: 100%;
        &:visited {
            color: #fff; } }
    &__disabled {
        @include CTAOnLanding;
        background: #666;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        padding: 14px 24px;
        margin: 0;
        width: 100%;
        &:visited {
            color: #fff; } }
    &__desc {
        font-weight: normal;
        font-size: .875rem;
        line-height: 120%;
        text-align: center;
        color: #000; }
    &__list {
        display: flex;
        flex-flow: column;
        list-style: none;
        margin: 1rem 0;
        width: 90%;
        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #E3E3E3;
            padding-bottom: .5rem;
            margin-bottom: .5rem;
            font-weight: normal;
            &:last-child {
                padding: 0;
                border: none;
                font-weight: 500; }
            &__desc,
            &__amountº {
                @include stepDescription;
                font-weight: inherit;
                font-size: 1.125rem;
                line-height: 120%;
                color: #666; } } }
    .DateInput_input {
        margin: 0;
        padding: 0; }
    .DateInput_input {}
    .DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__showClearDates.DateRangePickerInput__showClearDates_2 {
        padding: 0;
        display: flex;
        justify-content: space-between;
        &:nth-child(2) {
            margin: 0 5px; } } }
