@import "../../config";

.DniInput {
  display: flex;
  flex-flow: column;
  max-width: 300px;
  &__placeholder {
    max-width: 300px;
    max-height: 200px;
    z-index: 2;
    &__svg {
      background: #E4E4E4;
      padding: 4rem;
      border-radius: 4px;
      height: 200px;
      width: 300px; } }
  &__imgPreview {
    max-width: 300px;
    max-height: 200px;
    border-radius: 4px;
    z-index: 3;
    &__img {
      height: 200px;
      width: 300px;
      border-radius: 4px;
      object-fit: cover; } }
  &__photo {
    display: none; }
  &__btn {
    @include btn-primary;
    margin-top: 1.125rem; } }
