@import '../config';

.alert-error {
  box-sizing: border-box;
  width: 100%;
  font-family: $font-family-base;
  font-weight: 700;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: .5rem;
  align-items: center;
  font-size: .9375rem;
  line-height: 120%;
  color: $colorNaranjaError;
  padding: .75rem;
  background: rgba(255, 0, 0, 0.07); }

.alert-success {
  box-sizing: border-box;
  width: 100%;
  font-family: $font-family-base;
  font-weight: 700;
  display: flex;
  grid-template-columns: 20px 1fr;
  grid-gap: .5rem;
  align-items: center;
  font-size: .9375rem;
  line-height: 120%;
  color: $colorVerde;
  padding: .75rem;
  margin: 10px 0;
  background: rgba(0, 255, 0, 0.07); }
