.cardDetail {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    width: 90%;
    box-shadow: 2px 2px 5px #999;
    border-radius: 12px;
    margin-top: 12px;
    animation: opacity .3s ease-out forwards;
    opacity: 0;
    color: #000;
    &:hover {
        color: #000; }
    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        &__img {
            border-radius: 12px 12px 0 0;
            width: 100%;
            height: 200px;
            object-fit: cover; }
        &__price {
            position: absolute;
            right: 5%;
            bottom: 22%;
            background: #fff7e9;
            border-radius: 4px;
            padding: 4px 10px; }
        &__title {
            width: 90%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: center;
            margin: 10px auto; } }
    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        &__detail {
            width: 100%;
            margin: 2px 0!important;
            border-bottom: 1px solid #fff7e9;
            padding-left: 8px;
            text-align: start; }
        &__detail:last-child {
            margin-bottom: 23px!important; } } }
@keyframes opacity {
    0% {
        opacity: 0; }

    100% {
        opacity: 1; } }
