@import "../../config";

.Book {
  width: 100%;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15);
  border-radius: 1.25rem;
  padding: 2rem;
  box-sizing: border-box;
  display: none;
  background: #fff;
  @media (min-width: $bp-desktop) {
    display: block; }
  &__title {
    @include stepSubtitle;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 100%;
    margin-bottom: .75rem; }
  &__cta {
    @include btn-primary;
    width: 100%;
    font-size: 1.125rem;
    line-height: 120%;
    max-width: inherit;
    text-align: center;
    &__outline {
      @include btn-outline(1.125rem, 1rem, 2rem);
      width: 100%;
      line-height: 120%;
      max-width: inherit;
      text-align: center; }
    &__small {
      color: $colorNaranja;
      font-size: .8rem;
      background: none;
      margin-top: 1rem;
      margin-bottom: 1rem; } }
  &__ctas {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 0 32px; }
  &__desc {
    @include stepDescription;
    font-weight: normal;
    font-size: .875rem;
    line-height: 120%;
    margin-bottom: 2rem;
    text-align: center;
    color: #000;
    margin-bottom: 1rem!important; }
  &__list {
    display: flex;
    flex-flow: column;
    list-style: none;
    margin-bottom: 1rem;
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E3E3E3;
      padding-bottom: .5rem;
      margin-bottom: .5rem;
      font-weight: normal;
      &:last-child {
        padding: 0;
        border: none;
        font-weight: 500; }
      &__desc,
      &__amount {
        @include stepDescription;
        font-weight: inherit;
        font-size: 1.125rem;
        line-height: 120%;
        color: #666; } } } }

.BookMobile {
  box-sizing: border-box;
  height: 80px!important;
  width: 100vw!important;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10001!important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  @media (min-width: $bp-desktop) {
    display: none; }
  &__container {
    display: flex;
    justify-content: space-between;
    width: 90vw!important;
    margin: 0 auto;
    padding: 1rem 0!important;
    &__pricecontainer {
      font-size: 1rem!important;
      font-weight: 500;
      color: #000;
      margin-right: 1rem!important;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
      &__price,
      &__reviews {
        font-size: 1.25rem; }
      &__reviews {
        display: flex;
        align-items: center;
        font-size: .85rem;
        img {
          height: .8rem;
          width: .8rem; } } }
    &__cta {
      @include CTAOnLanding;
      background: $colorNaranja!important;
      color: #fff;
      font-size: 1rem!important;
      font-weight: 500!important;
      padding: 14px 24px !important;
      margin: 0!important;
      margin-left: 1rem!important;
      &:visited {
        color: #fff; } } } }

