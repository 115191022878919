@import "../../config";
.responsiveUserStep {
  background: #fff;
  padding-top: 100px; }
.UserStep {
  padding: 0 20px;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  height: 130vh;
  @media (min-width: $bp-desktop) {
    padding: 20px 0;
    height: 60vh; }
  &__container {
    display: grid;
    grid-template-columns: 1fr!important;
    grid-gap: 30px!important;
    margin-bottom: 30px!important;
    @media (min-width: $bp-desktop) {
      grid-template-columns: 40% 60%!important;
      margin-bottom: 50px!important; }
    &__photo {
      &__title {
        @include stepTitle;
        margin-bottom: 1rem!important;
        color: #000;
        font-size: 1.5rem!important; }
      &__description {
        @include stepDescription;
        margin-bottom: 2rem!important;
        color: #000;
        font-size: 1.2rem!important; } }
    &__cellphone {
      &__error {
        padding: 1.2rem 0;
        width: 100%; }
      &__title {
        @include stepTitle;
        margin-bottom: 1rem;
        color: #000;
        font-size: 1.5rem!important; }
      &__description {
        @include stepDescription;
        font-size: 1.2rem!important;
        margin-bottom: 2rem!important;
        color: #000; } } }
  .container {
    display: flex;
    flex-flow: column;
    @media (min-width: $bp-desktop) {
      flex-flow: row; }
    .inputContainer {
      @include inputContainerStyle;
      color: #000;
      display: flex;
      flex-flow: column;
      width: 200px;
      @media (min-width: $bp-desktop) {
        margin-right: 20px!important; }
      label {
        @include labelStyle;
        font-size: .8rem!important;
        padding: 0;
        letter-spacing: 0;
        color: $colorNaranja; }
      select {
        @include selectStyle;
        width: 100%!important; }
      input {
        @include inputStyle;
        font-size: 1rem!important;
        padding: 1rem 0 .2rem 0;
        &.errorInput {
          border: 1px solid $colorNaranja!important; }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none!important;
          margin: 0!important; } } }
    #second {
      width: 200px!important;
      input {
        width: 100%!important; } } } }

