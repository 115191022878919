@import "../../../../assets/styles/_config";
.notificationMessage,
.notificationMessageMenu, {
    background: red!important;
    width: 20px!important;
    height: 20px!important;
    border-radius: 100%!important;
    position: absolute!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    right: 0!important;
    top: 0!important;
    font-size: 14px!important;
    line-height: 0!important;
    border: none!important;
    padding: 0!important;
    margin: 0!important;
    color: #fff!important; }
.notificationMessageMenu {
    right: 10%!important;
    top: 50%!important;
    transform: translate(0,-50%)!important; }
