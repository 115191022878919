@import "../../../../../assets/styles/_config";

.tooltip {
    text-align: center;
    justify-content: center;
    display: flex;
    padding: 5px 10px;
    zoom: 1.3;
    align-items: center;
    border: 2px solid #FF4E00;
    background: #fff;
    border-radius: 50px;
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: -10px;
    margin-right: -10px;
    &__title {
        font-size: .8rem; }
    &__container {
        width: 100%; } }
// .leaflet
//  // display: grid
//  // grid-template-columns: 1fr 60%
//  // grid-gap: 10px
//  // max-width: 300px
//  // &__container
//  //     display: flex
//  //     flex-flow: column
//  //     justify-content: space-between
//      // &__title,
//      // &__description
//      //     font-family: $font-family-base
//      // &__title
//      //     font-size: 1.125rem
//      //     font-weight: 700
//      //     margin-bottom: .5rem
//      //     padding-bottom: .5rem
//      //     border-bottom: 2px solid $colorNaranja
//      // &__btn
//      //     @include btn-primary
//      //     font-size: .75rem!important
//      //     padding: .5rem!important
//      //     display: flex
//      //     align-items: center
//      //     justify-content: center
//      //     margin-top: 5px
//      //     transition: all 200ms ease-in-out
//      //     transform: scale(1)
//      //     &:hover
//      //         background: $colorNaranjaHover
//      //         transition: all 50ms ease-in-out
//      //         transform: scale(.95)
//      //     &:active
//      //         background: $colorNaranjaHover
//      //         transition: all 50ms ease-in-outº
//      // &__imgcontainer
//      //     width: 100%
//      //     height: 150px
//      //     &__img
//      //         object-fit: cover
//      //         width: 100%
//      //         height: 100%
