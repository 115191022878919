.ProfileHousingCard {
  display: flex;
  flex-flow: column;
  width: 100%;
  &__title {
    display: inline-block;
    font-size: 1rem;
    line-height: 125%;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    color: #000!important; }
  &__housing {
    width: 100%;
    margin-bottom: .5rem;
    color: #000!important;
    &__img {
      width: 100%;
      object-fit: cover;
      border-radius: .5rem;
      aspect-ratio: 4 / 3; } }
  &__housingData {
    display: flex;
    margin-bottom: .5rem;
    font-size: .9rem;
    line-height: 125%;
    color: #666; }
  a {
    color: #000;
    &:visited {
      color: #000; } } }

