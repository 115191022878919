@import "../../../assets/styles/_config.sass";
.notificationHousingNone {
    display: none; }
.notificationHousing {
    max-width: 400px;
    width: 100%;
    height: 100px;
    background: #fff;
    // border: 1px solid orange
    box-shadow: 0px 0px 15px 0px rgba(#000,0.75);
    border-radius: 16px;
    cursor: pointer;
    animation-name: welcome;
    animation-duration: 0.3s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    @media (max-width: $bp-cell) {
        height: 64px; }
    // @media (max-width: $bp-cell)
    //     padding: 14px 24px
    &__close {
        position: absolute;
        right: 0;
        top: 14px;
        color: red;
        z-index: 10;
        width: 25px;
        height: 25px;
        z-index: 20; }
    &__container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;
        align-items: center;
        padding: 5px;
        padding: 0 50px;
        &__text {
            color: #000;
            font-size: 16px; }
        &__text:nth-child(3) {
            color: #FF4E00!important;
            position: absolute;
            right: 50px;
            bottom: 5px;
            font-size: 12px; }
        &__text:nth-child(2) {
            color: #9CA3AF;
            width: 70%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 16px; } } }
@media (max-width: $bp-cell) {
    @keyframes welcome {
        0% {
            top: -150px; }

        100% {
            top: 0; } } }

@media (min-width: $bp-cell) {
    @keyframes welcome {
        0% {
            right: -150px; }

        100% {
            right: 0; } } }
