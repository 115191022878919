.displayNone {
    display: none; }
.ModalGlobalPrimary {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 10;
    background: rgba(000,000,000,0.4);
    backdrop-filter: blur(2px);
    &__container {
        position: relative;
        max-width: 600px;
        max-height: 500px;
        min-height: 300px;
        width: 90%;
        height: auto;
        background: #fff;
        border: 1px solid #ff4e00;
        border-radius: 12px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        transform: scale(1);
        animation: zoomActive .2s linear 0s;
        &__close {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 16px;
            right: 16px; } } }
@keyframes zoomActive {
    0% {
        transform: scale(0); }
    50% {
        transform: scale(0.5); }
    100% {
        transform: scale(1); } }
