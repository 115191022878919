@import "../_config";

.ReportModal {
  width: 650px;
  box-sizing: border-box;
  &__header {
    border-bottom: 1px solid $colorNaranja!important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0!important;
    margin: 0!important;
    position: relative;
    &__title {
      margin: 0!important;
      font-size: 1.25rem;
      color: $colorNaranja!important;
      font-weight: 700; }
    &__cross {
      position: absolute;
      top: 50;
      right: 4%;
      width: 13px;
      color: $colorNaranja!important;
      cursor: pointer; } }
  &__section {
    padding: 0px 0px 20px 0px!important;
    box-sizing: border-box;
    * {
      box-sizing: border-box; }
    &__btn {
      grid-area: 2 / 1 / span 1 / span 2;
      @include btn-primary;
      width: 40%;
      margin: 20px auto 0 auto;
      padding: 1rem 2rem!important;
      display: flex;
      align-items: center;
      justify-content: center;
      &__text {
        color: #fff!important;
        margin: 0!important; } }
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center!important;
      width: 100%;
      &__radioButtons {
        width: 100%!important;
        display: flex;
        flex-flow: column;
        border-bottom: 1px solid $colorNaranja;
        border-right: 1px solid $colorNaranja; }
      &__form {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid $colorNaranja;
        padding-bottom: 20px;
        &__text {
          font-size: 1.25rem;
          color: $colorNaranja!important;
          margin: 1rem 0!important;
          font-weight: 500;
          width: 90%; }
        &__input,
        &__textarea {
          font-size: 1rem!important; }
        &__input {
          @include inputStyle;
          background: $colorBlanco!important;
          border-radius: .5rem!important;
          border: 2px solid $colorNaranja!important;
          padding: 10px!important;
          max-width: 90%;
          width: 100%;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none!important;
            margin: 0!important; }
          &:not([value=""]) {
            border: 2px solid $colorNaranja!important; } }
        &__textarea {
          @include textareaStyle;
          background: $colorBlanco!important;
          max-width: 90%;
          width: 100%;
          resize: none;
          border-radius: .5rem!important;
          padding: 10px!important;
          color: $colorNaranja;
          border: 2px solid $colorNaranja!important;
          &:not([value=""]) {
            border: 2px solid $colorNaranja!important; } } } } } }

