
@import "../config";
@import "../button_orange";
.UserContainer {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 250px 800px;
    @media (max-width: $bp-cell) {
        grid-template-columns: 1fr;
        margin-bottom: 100px; }
    &__sideBar {
        width: 100%;
        background: #2B2B2B;
        padding-top: 40px;
        @media (max-width: $bp-cell) {
            display: none; } } }
.user_profile_page {

    .main {
        padding-top: 100px;
        margin: 0!important;

        .boton_nararnja {
            @include button_orange;
            max-width: 250px; }

        .presentation {
            margin-bottom: 25px;
            word-break: break-all; }

        .join {
            color: #999999;
            font-size: 12px;
            font-family: $font-family-base;
            text-transform: uppercase; }

        .separador {
            color:#E3E3E3 {}
            border-width: 1px;
            margin-top: 50px;
            margin-bottom: 50px; }

        h1 {
            font-size: 50px;
            line-height: 75px;
            text-align: left;
            font-family: $font-family-base;
            color: #333333;
            font-weight: lighter; }
        h2 {
            color: #333333;
            line-height: 45px;
            font-family: $font-family-base;
            font-weight: lighter; }

        p {
            line-height: 31px;
            font-size: 20px;
            font-family: $font-family-base;
            text-align: left;
            font-weight: lighter; }

        .profile_propety {
            line-height: 31px;
            font-size: 20px;
            font-family: $font-family-base;
            text-align: left;
            font-weight: lighter;
            color: #666666;
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            img {
                float: left;
                width: 20px;
                margin-right: 20px; } }

        .wrapper_evaluations {
            margin-top: 100px;

            .from_evaluations {
                display: flex;
                margin-right: 5px;
                margin-bottom: 20px;
                margin-top: 20px;

                .from_alohados {
                    margin-right: 20px;
                    padding: 5px;
                    font-weight: lighter; }

                .from_alohadores {
                    padding: 5px;
                    font-weight: lighter; }

                .select {
                    font-weight: bold;
                    border-bottom: solid;
                    border-bottom-color: $colorNaranja;
                    border-bottom-width: 2px; } }


            .evaluation {
                margin-top: 30px;

                .evaluation_text {
                    font-size: 15px;
                    line-height: 21px;
                    font-family: $font-family-base;
                    text-align: left; }


                .header_evaluation {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .evaluation_name {
                        font-size: 17px;
                        line-height: 20px;
                        font-family: $font-family-base;
                        font-weight: bold; }

                    .evaluation_date {
                        font-size: 15px;
                        line-height: 18px;
                        font-family: $font-family-base;
                        font-weight: lighter;
                        color: #999999; }

                    img {
                        float: left;
                        margin-right: 17px;
                        margin-bottom: 20px; } } } } } }
