@import "../config";
.userMenuAvatar {
    background: #fff7e9;
    color: #ff4e00;
    border-radius: 100px;
    text-align: center;
    font-family: "Roboto",sans-serif;
    font-style: normal;
    font-weight: 700!important;
    font-size: .9375rem;
    line-height: 120%;
    cursor: pointer;
    width: 100%;
    height: 100%; }
.housingListContainer {
    height: 100vh;
    position: absolute;
    top: 120px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    left: 0;
    @media (max-width: $bp-cell) {
        width: 100%;
        right: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        top: 114px;
        height: auto;
        position: fixed;
        z-index: 50; }
    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
        width: 83%;
        max-width: 1200px;
        margin: 0 auto;
        align-items: flex-end;
        @media (max-width: $bp-cell) {
            width: 90%;
            align-items: center; } } }
@media only screen and (max-width: $bp-cell) {
    .userMenuLogin {
        position: relative;
        transform: translateX(3px) translateY(12px)!important;
        top: -341%!important;
        right: -45%!important; }
    .userMenuOut {
        transform: translateX(23px) translateY(20px)!important; } }

.loginBlock {
    padding-left: 3px!important;
    width: 7rem!important; }

.homeBlock {
    padding-left: 10px!important;
    width: 7.7rem!important; }

.alohadorBlock {
    padding-left: 0.6rem!important;
    width: 9.3rem!important; }

.block {
    display: flex!important;
    color: $colorNaranja;
    font-size: 1rem!important;
    font-family: Roboto;
    font-weight: 500;
    line-height: 18,75px; }

.block__navIcon {
    line-height: 20px!important;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 1rem !important;
    margin-left: 5px;
    letter-spacing: 0.6px; }
.iconHome {
    margin-left: 10px!important; }
.none {
    display: none; }

.LinkActive {
    border-radius: 100px!important;
    color: white!important;
    display: flex!important;
    align-items: start!important; }

.navbar[show='false'] {
    z-index: 100;
    @media only screen and (max-width: $bp-cell) {
        display: none; } }

.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    z-index: 100;
    height: 100px;
    @media only screen and (max-width: $bp-cell) {
        width: 100vw;
        z-index: 20;
        height: 80px;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 0 6px;
        margin: 0;
        background: $colorNaranja;
        box-shadow: 0px -7px 11px rgba(43, 43, 43, 0.2);
        border-radius: 34px 34px 0 0; }
    * {
        box-sizing: border-box; }
    .navbar__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 83%;
        max-width: 1200px;
        margin: 0 auto;
        @media only screen and (max-width: $bp-cell) {
            display: none!important; } } }

.navbar[landing='true'] {
    z-index: 100;
    width: 83%;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    @media only screen and (max-width: $bp-cell) {
        z-index: 20;
        height: 80px;
        position: fixed;
        bottom: 0;
        left: 0; } }
.navbar__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    // padding: 2rem 0
    @media only screen and (max-width: $bp-cell) {
        height: 80px!important; }
    @media only screen and (max-width: $bp-cell) {
        display: none!important; } }

.logo {
    height: 2.5rem;
    display: block; }
.menu_navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    // div,
    .links,
    .linksBlack {
        background: $colorBlanco;
        color: $colorNaranja;
        display: inline;
        padding: 1.125rem 1.25rem;
        margin-left: 10px;
        border-radius: 100px;
        text-align: center;
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 700!important;
        font-size: .9375rem;
        line-height: 120%;
        cursor: pointer; }
    // div
    //     padding: 0
    //     height: auto
 }    //     line-height: 1

.navbar[landing='false'] {
    z-index: 100;
    background: $colorNaranja;
    box-sizing: border-box;
    @media only screen and (max-width: $bp-cell) {
        height: 80px!important;
        margin-bottom: -1px!important; } }

.navbar__mainSearch {
    width: 500px!important; }

.usermenubutton {
    margin: 0!important;
    margin-left: 10px!important;
    padding: 0!important;
    display: flex!important;
    align-items: center!important;
    background: none!important;
    position: relative;
    * {
        margin: 0!important; }
    &__imgcontainer {
        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
        width: 3.25rem!important;
        height: 3.25rem!important;
        position: relative;
        padding: .5rem!important;
        &__img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
            position: relative; }
        svg {
            height: 75%;
            width: 75%; }
        &__name {
            position: relative;
            margin-left: 10px!important;
            color: $colorBlanco!important; } }
    .button_profile {
        padding: 0!important;
        display: flex;
        align-items: inherit;
        justify-content: center;
        background: none!important;
        position: relative; } }

.padding_0 {
    padding: 0!important; }

.Mobile {
    z-index: 100;
    display: none;
    width: 100%;
    @media only screen and (max-width: $bp-cell) {
        display: flex!important;
        justify-content: space-between;
        align-items: center;
        margin: 0 10.9%; }
    &__link {
        border-radius: 100%;
        color: white;
        width: 2.8rem;
        height: 2.8rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: relative;
        &__logo {
            height: 2rem!important;
            width: 2rem!important;
            font-family: $font-family-titles;
            text-transform: lowercase;
            font-size: 2.5rem;
            line-height: 2rem;
            padding: 3px; }
        &__imgcontainer {
            height: 2rem!important;
            display: flex;
            justify-content: center;
            align-items: center;
            &__home {
                width: 1.7rem;
                height: 1.7rem; }
            &__img {
                color: black;
                max-height: 1.8rem!important;
                max-width: 1.8rem!important;
                object-fit: cover; }
            &__avatar {
                border-radius: 100%;
                width: 100%;
                height: 100%;
                // width: 2.8rem
                // height: 2.8rem
                // max-height: 2.8rem!important
 } }                // max-width: 2.8rem!important
        &__profilecontainer {
            height: 3rem!important;
            width: 3rem!important;
            border-radius: 100%;
            padding: 3px!important;
            display: flex;
            justify-content: center;
            align-items: center;
            &__icon {
                color: #FF4E00; }
            &__img {
                border-radius: 100%;
                height: 100%;
                width: 100%;
                object-fit: cover; } }
        &__text {
            font-family: $font-family-base;
            font-size: .625em; } } }
.linksBlack {
    background: #000!important;
    color: #fff!important; }
