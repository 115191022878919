@import "../../config";

.FilterCheckbox {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  &__checkbox {
    display: none;
    box-sizing: border-box; }
  &__checkbox:checked + &__title {
    background: $colorNaranja;
    color: $colorBlanco; }
  &__title {
    @include stepSubtitle;
    box-sizing: border-box;
    font-size: 1rem!important;
    cursor: pointer;
    font-weight: 500;
    padding: .75rem 1.4rem;
    font-size: 14px!important;
    width: 100%;
    height: 100%;
    color: $colorNaranja;
    background-clip: padding-box!important;
    border-bottom: 1px solid $colorNaranja;
    &:hover {
      background-clip: padding-box!important;
      background: $colorNaranja;
      color: #FFF; } } }
.FilterCheckboxLast {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    &__checkbox {
      display: none;
      box-sizing: border-box; }
    &__checkbox:checked + &__title {
      background: $colorNaranja;
      color: $colorBlanco; }
    &__title {
      @include stepSubtitle;
      box-sizing: border-box;
      font-size: 1rem!important;
      cursor: pointer;
      font-weight: 500;
      padding: .75rem 1.4rem;
      font-size: 14px!important;
      width: 100%;
      height: 100%;
      color: $colorNaranja;
      border-radius: 0 0 10px 10px;
      &:hover {
        border-radius: 0 0 10px 10px;
        background-clip: padding-box;
        background: $colorNaranja;
        color: #FFF; }
      @media only screen and (max-width: $bp-cell) {
        border-radius: 0; } } }
