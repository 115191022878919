@import "../_config";

.WelcomeModal {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 20px 0!important;
    &.dark {
        background: $colorNegroNew; }
    &__header {
        padding: 20px 0 0 0!important;
        border: none!important;
        &__cross {
            position: absolute;
            top: 50;
            right: 4%;
            width: 13px;
            color: $colorNaranja!important;
            cursor: pointer; } }
    &__section {
        padding: 20px 60px 10px 60px!important;
        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
        &__iconContainer {
            height: 150px;
            width: 150px;
            margin: 20px 0;
            &__avatar {
                height: 100%;
                width: 100%;
                object-fit: cover; } }
        &__title {
            font-family: $font-family-base;
            font-weight: 700;
            font-size: 1.5rem;
            color: $colorNaranja;
            &.dark {
                color: #ffffff; }
            b {
                color: inherit;
                font-family: $font-family-titles;
                font-size: 1.5em; } }
        &__text {
            color: $colorNaranja!important;
            font-family: $font-family-base;
            margin-bottom: 10px;
            &.dark {
                color: #ffffff!important; } } }
    &__buttons {
        display: flex;
        flex-flow: column;
        align-content: center;
        justify-content: center;
        &__btn {
            @include btn-primary;
            font-size: 1.5rem!important;
            padding: .5rem 4rem!important;
            margin: 0 auto 10px auto;
            width: 50%; }
        &__text {
            margin: 0 auto 30px auto;
            color: $colorNaranja!important;
            font-family: $font-family-base;
            text-decoration: underline!important;
            &.dark {
                color: #ffffff!important; } } } }
