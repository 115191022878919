.globalButtonPrimary,
.globalButtonSecondary {
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(43, 43, 43, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    border-radius: 100px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #fff;
    background: #ff4e00;
    position: relative;
    &:disabled {
        cursor: not-allowed;
        pointer-events: all !important;
        background: #999; } }
.globalButtonSecondary {
    border: 1px solid #ff4e00!important;
    background: #fff;
    color: #ff4e00; }
