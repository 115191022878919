@import "../config";
.btnIcon {
    width: 2.8rem!important;
    height: 2.8rem!important;
    background: $colorNaranja!important;
    border-radius: 100%;
    margin: -20px 0 0 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: $bp-desktop) {
        display: none; }
    &__Icon {
        width: 1.4rem!important;
        height: 1.4rem!important;
        z-index: 2;
        &__filter {
            width: .7rem!important;
            height: .7rem!important;
            position: absolute;
            z-index: 10;
            right: 10px;
            top: 10px; } } }
.modalFilter {
    position: fixed;
    height: 100vh;
    width: 100%!important;
    z-index: 15;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    background: rgba(0,0,0,0.5)!important; }
.modalFilter.isOpen {
    display: flex;
    background: none; }
.filtersContainer {
    width: 100%; }
.modal-container {
    position: absolute;
    top: 100px;
    max-width: 95%;
    max-height: 100%;
    background: #fff;
    border-radius: 34px;
    width: 95%;
    height: auto;
    padding: 26px 0 43px 0; }
.modalHeader {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 20px 15px 20px;
    border-bottom: 1px solid $colorNaranja;
    &__title {
        font-family: Casta;
        font-weight: 800;
        font-size: 38px;
        line-height: 35px; }
    &__btn {
        margin-right: -2%;
        background: none;
        &__img {
            background: none!important; } } }
