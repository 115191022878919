@import "../../config";
.ListFilter {
  font-family: $font-family-base;
  font-weight: 700;
  font-size: 14px;
  color: $colorNegro;
  background: none;
  text-align: left;
  border-radius: 10px 10px 0 0;
  border: 1px solid transparent!important;
  padding: .5rem 20px;
  position: relative;
  z-index: 10!important;
  display: flex;
  align-items: center;
  white-space: nowrap;
  transform: translateX(-1rem);
  left: 16px;
  width: min-content;
  @media only screen and (max-width: $bp-cell) {
    width: 100%;
    border-radius: 0;
    font-weight: 500;
    font-size: 1rem;
    line-height: 18px;
    margin: .5rem 0; }
  &.active {
    z-index: 90!important;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, .15); }
  &__icon {
    margin-bottom: 2px;
    margin-left: 10px;
    height: .5rem;
    @media only screen and (max-width: $bp-cell) {
      position: absolute;
      right: 10px;
      margin-bottom: 2px;
      margin-left: 10px;
      height: .5rem; }
    &.active {
      transform: rotate(180deg); } }
  &__options {
    display: none; }
  &__options.active {
    z-index: 10!important;
    display: block;
    position: absolute;
    top: calc(100% - 2px);
    left: 0px;
    border-radius: 0 0 10px 10px;
    background: #fff;
    width: calc(100% + 2px);
    border-top: 1px solid $colorNaranja;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, .15);
    @media only screen and (max-width: $bp-cell) {
      //left: 0
      width: 100%; } } }
