@import "../config";

.BONavbar {
    box-sizing: border-box;
    background: $colorNaranja;
    color: #FFF;
    display: flex;
    align-items: center;
    height: 116px;
    * {
        box-sizing: border-box; }
    &__upperside {
        width: 50%;
        display: flex;
        align-items: center;
        padding: 20px;
        &__links {
            height: 50px;
            &__logo {
                height: 50px;
                object-fit: cover; } }
        &__userData {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            &__name {
                font-size: 1.5rem;
                font-weight: 700; }
            &__role {
                font-size: .75rem; } } }
    &__downside {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 20px;
        &__title {
            margin-bottom: 1rem;
            align-self: flex-start;
            font-weight: 700;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            width: 100%;
            padding: 1rem 0;
            text-align: center; }
        &__menu {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__links {
                font-weight: 500;
                color: #fff!important; } } } }
