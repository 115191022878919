@import "../../config";

.Dropzone {
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    min-height: 350px;
    height: 100%;
    background: $colorBlanco;
    border-radius: 50px;
    padding: 2rem 0;
    &__title {
      @include stepSubtitle;
      color: $colorNaranja;
      margin-bottom: 2rem;
      font-size: 3.25rem;
      font-family: $font-family-titles; }
    &__list {
      list-style: none;
      max-width: 80%;
      margin-left: 10%;
      @media (min-width: $bp-desktop) {
        max-width: 55%;
        margin-left: 4.5rem; }
      &__description {
        list-style-image: url('../../../static/list-style-small.svg');
        @include stepDescription;
        margin-bottom: 1.5rem;
        color: $colorNaranja;
        font-weight: 500; } }
    &__btn {
      @include btn-primary;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 1.25rem 2rem;
      cursor: pointer;
      &__text {
        margin-left: .625rem; } }
    input {
      display: none; } }
