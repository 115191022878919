@import "../_config";


.EditModal {
  position: static;
  background: #fff;
  display: flex;
  flex-flow: column;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100vw;
  top: 50%!important;
  left: 50%!important;
  @media (min-width: $bp-desktop) {
    display: flex;
    width: 800px;
    height: auto;
    position: static;
    padding: 2rem 3rem;
    border-radius: 30px;
    transform: translate(0%, 0%); }
  &::-webkit-scrollbar {
    display: none; }
  &__header {
    border-bottom: 1px solid $colorNaranja!important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0!important;
    margin: 0!important;
    position: relative;
    @media (min-width: $bp-desktop) {
      padding: 20px 0!important;
      justify-content: start;
      text-align: start; }
    &__titles {
      display: flex;
      flex-flow: column;
      padding: 0!important;
      &__edit {
        font-family: $font-family-titles;
        color: $colorNaranja!important;
        font-size: 20px;
        width: 100%!important;
        text-align: initial;
        @media (max-width: $bp-cell) {
          align-items: center!important;
          justify-content: center!important;
          display: flex; } }
      &__title {
        font-family: $font-family-titles;
        margin: 0!important;
        font-size: 1.2rem;
        color: #000!important;
        font-weight: 700;
        @media (min-width: $bp-desktop) {
          font-size: 38px!important;
          width: 100%;
          font-size: 1.25rem; } } }
    &__cross {
      position: absolute;
      top: 50;
      right: 8.5%;
      width: 13px;
      color: $colorNaranja!important;
      cursor: pointer;
      @media (min-width: $bp-desktop) {
        right: 0;
        top: 0;
        width: 20px;
        color: #000!important; } } }
  &__body {
    padding: 0!important;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none; }
    &__title {
      @include stepSubtitle;
      font-size: 1.25rem;
      line-height: 120%;
      color: #333333;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #E3E3E3; }
    &__container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 5px;
      padding: 1rem 1rem;
      justify-content: center;
      @media (min-width: $bp-desktop) {
        display: flex;
        justify-content: space-between!important;
        padding: 0; }
      &__part {
        width: 100%;
        margin-top: 3rem;
        margin-bottom: 5px;
        &__enlace {
          display: flex;
          justify-content: start;
          align-items: center;
          width: 100%;
          padding: 0;
          @media (min-width: $bp-desktop) {
            padding-bottom: 10px!important; }
          img,svg {
            width: 25px;
            margin-right: 10px;
            g {
              rect {
                color: $colorNaranja!important;
                -webkit-stroke-color: $colorNaranja!important;
                -webkit-fill-color: $colorNaranja!important; }
              circle {
                stroke: rgb(255,122,64);
                -webkit-text-stroke:  rgb(255,122,64);
                -webkit-text-stroke-color: rgb(255,122,64);
                color: rgb(255,122,64); }
              path {}
              color: rgb(255,122,64);
              stroke: $colorNaranja;
              fill: #fff;
              stroke: rgb(255,122,64);
              -webkit-text-stroke:  rgb(255,122,64);
              -webkit-text-stroke-color: rgb(255,122,64); }
            path {
              color: rgb(255,122,64);
              stroke: $colorNaranja;
              fill: #fff;
              stroke: rgb(255,122,64);
              -webkit-text-stroke:  rgb(255,122,64);
              -webkit-text-stroke-color: rgb(255,122,64); } } }
        @media (max-width: $bp-cell) {
          margin: 0; }
        a {
          text-decoration: underline!important;
          font-family: $font-family-base;
          font-size: 17px;
          color: #000000;
          padding: 5px;
          @media (max-width: $bp-cell) {
            padding: 0; } }
        a:visited {
          color: #000000; } } } }
  p {
    margin: 0!important;
    padding: 0!important; } }
