@import "../config";

.CustomToggle {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: row-reverse;
    @media (min-width: $bp-desktop) {
        padding-right: 1rem;
        flex-flow: row; }
    &__title {
        @include stepSubtitle;
        font-size: .75rem;
        font-weight: 500;
        color: #000;
        text-transform: uppercase;
        text-align: left;
        margin: 0;
        margin-right: 10px;
        padding-left: 5px;
        @media (min-width: $bp-desktop) {
            margin: 0;
            margin-left: 10px;
            white-space: nowrap; } }
    &__switch {
        position: relative;
        display: inline-block;
        min-width: 40px;
        height: 24px; }
    &__checkbox {
        z-index: 100;
        height: 100%;
        width: 100%;
        opacity: 0; }
    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: none!important;
        border: 1px solid $colorNaranja;
        border-radius: 24px;
        transition: .4s;
        &:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 4px;
            bottom: 4px;
            background-color: $colorNaranja;
            transition: .4s;
            border-radius: 50%; } }
    &__checkbox:checked + &__slider {
        background-color: $colorNaranja!important;
        border-color: #fff!important; }
    &__checkbox:checked + &__slider:before {
        background-color: #fff;
        transform: translateX(16px); } }
