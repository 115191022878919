@import "../../config";

.SmallCard__imgcontainer>.swiper-button-prev,
.SmallCard__imgcontainer>.swiper-button-next {
  display: none;
  background: #FFFFFF9A;
  border-radius: 50%;
  height: 40px!important;
  width: 40px!important;
  opacity: 0!important; }
.SmallCard__imgcontainer:hover>.swiper-button-prev,
.SmallCard__imgcontainer:hover>.swiper-button-next {
  opacity: 1!important; }
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px!important; }

.swiper-button-prev:after,
.swiper-button-next:after {
  color: $colorNaranja!important;
  font-size: 1.5rem;
  font-weight: bold; }

.swiper-button-prev:after {
  transform: translateX(-2px); }
.swiper-button-next:after {
  transform: translateX(2px); }

.swiper-pagination-bullet {
  background: $colorNaranja!important;
  border: 1px solid $colorNaranja;
  opacity: 1; }

.swiper-pagination-bullet-active {
  background: #fff!important;
  box-shadow: 0px 4px 6px rgba(43, 43, 43, 0.3); }
