@import "../config";

.Reservation {
  width: 100%;
  border-radius: 20px;
  background: #ffffff;
  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &__title,
    &__text {
      color: #000;
      text-align: center; }
    >button {
      background: #fff;
      margin-top: 20%;
      color: #000; } }

  &__imgcontainer {
    width: 100%;
    height: 175px;
    z-index: 3;
    &__img {
      border-radius: 20px 20px 0 0;
      height: 100%;
      width: 100%;
      object-fit: cover; } }
  &__datacontainer {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    padding-bottom: 0!important;
    &__dates,
    &__persons,
    &__address {
      font-size: .75rem;
      color: #666;
      margin-bottom: .25rem; }
    &__title {
      font-size: 1.5rem;
      font-weight: 700;
      color: #333;
      margin-bottom: .25rem; }
    &__buttonList {
      box-sizing: border-box;
      border-top: 1px solid #E3E3E3;
      margin-top: .5rem;
      padding: .75rem .25rem 1rem .25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 10px;
      width: 100%;
      // @media (min-width: $bp-desktop)
      //   grid-template-columns: 1fr 1fr
      &__buttons {
        display: flex;
        justify-content: space-between;
        gap: 12px; }
      &__btn {
        box-sizing: border-box;
        @include btn-primary;
        font-size: .75rem!important;
        padding: .5rem!important;
        &:disabled {
          background: #999 !important;
          cursor: not-allowed; }
        &__link {
          background: none;
          font-size: .75rem;
          color: $colorNaranja;
          white-space: nowrap; } } } } }
