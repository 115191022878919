@import "../_config";

.ConfirmModal {
    width: 100%;
    box-sizing: border-box;
    &__header {
        border: none!important; }
    &__section {
        padding: 20px 20px 10px 20px!important;
        text-align: center;
        @media (min-width: $bp-desktop) {
            padding: 20px 20px 10px 20px!important; }
        &__icon {
            font-size: 50px;
            color: $colorNaranja; }
        &__whatsapp {
            margin-top: 50px;
            text-align: center;
            width: 100%;
            display: block;
            img {
              width: 50px; } }
        &__row {
          display: flex;
          flex-flow: row;
          justify-content: space-around;
          max-width: 300px !important;
          margin: auto; }

        &__titleNaranja {
          font-family: "CastaExtraBold", sans-serif;
          font-size: 2rem;
          color: #FF4E00 !important;
          margin: 0 !important;
          line-height: 100% !important; } }
    &__buttons {
        display: flex;
        flex-flow: row;
        align-content: center;
        justify-content: center; }
    &__inputContainer {
      @include inputContainerStyle;
      color: $colorNaranja;
      @media (min-width: $bp-desktop) {
        margin-right: 20px!important; }
      label {
        @include labelStyle; }
      select {
        @include selectStyle;
        width: 100%!important; }
      input {
        @include inputStyle;
        &.errorInput {
          border: 1px solid $colorNaranja!important; }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none!important;
          margin: 0!important; } } } }
