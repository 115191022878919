.boxItemActive,
.boxItem {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 0 4px 0 15px;
    &:hover {
        background: #ccc;
        cursor: pointer; }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;
        height: 100%;
        width: calc(100% - 70px);
        border-bottom: 1px solid #ccc;

        &__head {
            width: 100%;
            &__name {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                font-size: 17px;
                &__date {
                    font-size: 12px;
                    position: relative;
                    &__readed {
                        display: none; }
                    &__UnReaded {
                        position: absolute;
                        right: 50%;
                        border-radius: 100%;
                        transform: translate(50%,0);
                        top: 20px;
                        background: #FF4E00;
                        width: 20px;
                        height: 20px; } } } }
        &__message {
            width: 80%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; } } }
.boxItemActive {
    background: #ccc; }
