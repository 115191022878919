@import "../config";

.HousingList {
  display: none;
  @media (min-width: $bp-desktop) {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 250px 1fr;
    // background: #000
 }    // background: #F7F7F8!important
  * {
    box-sizing: border-box; }
  &__emptyTitle {
    font-family: $font-family-base;
    font-weight: bold;
    color: #000;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $bp-cell) {
      margin-bottom: 100px; } }
  &__loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh; }
  &__sideBar {
    width: 152%;
    // background: #2B2B2B
    display: flex;
    justify-content: center; }
  &__body {
    background: #F7F7F8;
    padding: 0;
    height: 88vh; }
  &__container {
    width: 100%!important;
    padding: 20px 50px 0 50px;
    background: #F7F7F8;
    z-index: 10;
    height: 100%;
    overflow-y: scroll;
 }    // scrollbar-base-color: transparent transparent!important
  // &__container::-webkit-scrollbar
  //   display: none
  &__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F7F7F8;
    border-bottom: 1px solid #C4C4C4;
    width: 100%;
    /* Aquí iría el buscador también por eso las propiedades de __headline */
    &__title {
      font-family: $font-family-base;
      font-weight: bold;
      font-weight: 700;
      color: #000!important;
      padding: 2rem 0 2rem 0;
      margin: 0;
      font-size: 24px;
      justify-content: center;
      align-items: center;
      &__sum {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        padding: 25px;
        background: $colorNaranja;
        border-radius: 100%;
        color: #fff;
        font-size: 35px;
        font-weight: 400;
        margin-left: 10px;
        transition: all .15s ease-in-out;
        box-shadow: none;
        &:hover {
          transition: all .15s ease-in-out;
          box-shadow: 0px 4px 6px rgba(43, 43, 43, 0.3); } } }
    &__addHouse {
      font-family: $font-family-base;
      font-weight: normal;
      font-weight: 500;
      color: #000!important;
      padding: 2rem 0 2rem 0;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center; } }
  &__table {
    background: #F7F7F8!important;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    line-height: 100%;
    border-radius: 0 0 20px 20px!important;
    border-collapse: separate;
    border-spacing: 0px 20px;
    margin-bottom: 85px;
    height: 200px!important;
    overflow-y: scroll;
    &__tr {
      width: 100%;
      &__th,
      &__td {
        width: 100px;
        box-sizing: border-box;
        vertical-align: middle;
        overflow-wrap: anywhere; }
      &__th {
        font-family: $font-family-base;
        color: #2B2B2B;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        font-size: .75rem;
        line-height: 1.125rem;
        white-space: nowrap;
        padding: 1rem .5rem 1rem 0rem; }
      &__td.widetd {
        width: 20px;
        white-space: nowrap;
        border-radius: 0 20px 20px 0!important; }
      &__td:first-child {
        border-radius: 20px 0 0 20px!important;
        box-sizing: border-box;
        padding-left: 10px; }
      &__td {
        font-family: $font-family-base;
        font-weight: 400;
        color: #2B2B2B;
        padding: 0rem .5rem 0rem 0rem;
        font-size: .75rem;
        box-sizing: border-box;
        line-height: 16px;
        &__buttons {
          width: 150px;
          margin: 10px auto;
          justify-content: space-around;
          box-sizing: border-box;
          display: flex;
          box-sizing: border-box;
          position: relative;
          .bottomHoverEdit,
          .bottomHoverBag,
          .bottomHoverDelete, {
            position: relative; }
          .bottomHoverEdit:hover::after {
            content: "Editar"; }
          .bottomHoverBag:hover::after {
            content: "Reservas"; }
          .bottomHoverDelete:hover::after {
            content: "Eliminar"; }
          .bottomHoverEdit:hover::after,
          .bottomHoverBag:hover::after,
          .bottomHoverDelete:hover::after, {
            bottom: -1.75rem;
            right: 10px;
            background: #000;
            padding: 5px 10px;
            border-radius: 5px;
            position: absolute;
            color: #fff;
            z-index: 2; }
          .bottomHoverEdit:hover::before,
          .bottomHoverBag:hover::before,
          .bottomHoverDelete:hover::before, {
            content: "";
            height: 0px;
            width: 0px;
            position: absolute;
            bottom: -6px;
            right: 13px;
            border: 10px solid transparent;
            border-top: 0;
            border-bottom: 10px solid #000;
            border-radius: 10px;
            z-index: 1; } }

        &__btn {
          display: flex;
          justify-content: center;
          padding: 10px 10px;
          border-radius: 100%;
          background: none;
          margin: 0;
          box-sizing: border-box;
          white-space: nowrap;
          margin: 10px 0;
          @media (max-width: $bp-cell) {
            display: -webkit-flex!important;
            display: flex;
            flex-direction: row-reverse;
            padding: .1rem 1rem .1rem 0rem!important; } }
        &__btn:hover {
          background:#DDDDDD {} }
        img {
          width: 25px;
          height: 25px; }
        &__container {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: start; }
        &__title {
          width: 100%;
          color:#2B2B2B!important {}
          box-sizing: border-box; }
        &__image {
          width: 80px;
          height: 60px;
          box-sizing: border-box;
          img {
            width: 80px;
            height: 60px;
            object-fit: cover;
            box-sizing: border-box;
            border-radius: 15px; } } } }
    &__tr:last-child {
      border: none;
      box-sizing: border-box; } }
  &__Checkbox {
    display: flex;
    align-items: center;
    width: 100%;
    &__CheckboxContainer {
      display: flex;
      align-items: center;
      position: relative;
      &__checkbox {
        display: none; }
      &__square {
        height: 1rem;
        width: 1rem;
        border: 1px solid #fff;
        position: absolute;
        top: 0;
        left: 0; }
      &__square::after {
        content: "";
        background: url('../../static/tick-orange.svg') no-repeat;
        background-size: 100%;
        background-position-y: 55%;
        height: 1rem;
        width: 1rem;
        color: $colorNaranja;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: 200ms ease-in-out 0s; }
      &__checkbox:checked + &__title > &__square::after {
        transform: translate(-50%, -50%) scale(1); }
      &__title {
        @include stepSubtitle;
        font-size: .75rem!important;
        cursor: pointer;
        width: 100%;
        color: #fff;
        margin-left: 1.5rem;
        line-height: calc(1rem + 2px); } } } }
.th_primary {
  padding-left: 10px; }
.hover {
  background: #fff!important; }
.hover:hover {
  box-shadow: 0px 9px 20px rgba(43, 43, 43, 0.2), 1px 17px 44px rgba(3, 2, 41, 0.07);
  border-radius: 30px; }
.MobileHousingList {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  padding: 1rem;
  margin-bottom: 2rem;
  min-height: calc(100vh - 65px);
  width: 100%!important;
  @media (min-width: $bp-desktop) {
    display: none; }
  * {
    box-sizing: border-box; }
  &__container {
    display: flex;
    flex-flow: column; }
  &__buttons {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 100px 80px;
    margin: 1rem 1rem 0rem 1rem;
    justify-content: space-around;
    // width: 95%
    &__btn {
      display: inline;
      font-size: .85rem;
      font-family: $font-family-base;
      font-weight: 400;
      color: #FFF;
      background: $colorNaranja;
      border-radius: 20px;
      font-weight: 500;
      padding: .75rem 1rem!important;
      margin: 0;
      align-self: center;
      position: relative;
      width: 100%; }
    &__PendingConfirm {
      display: flex;
      justify-content: center;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      right: 0px;
      background-color: rgb(255,249,235);
      color: $colorNaranja;
      border-radius: 100%;
      align-items: center; } }
  &__title {
    font-size: 2.75rem;
    font-family: $font-family-titles;
    color: $colorNaranja; }
  &__card {
    background: #ffffff;
    display: flex;
    flex-flow: column;
    border-radius: 20px;
    &__imgcontainer {
      width: 100%;
      height: 250px;
      &__img {
        border-radius: 20px 20px 0 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
    &__datacontainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: .25rem;
      padding: 1rem 1rem 2rem 1rem;
      color: $colorNegro;
      &__title {
        color: $colorNaranja;
        font-weight: 700;
        font-size: 1.5rem;
        border-bottom: 3px solid $colorNaranja;
        padding-bottom: .5rem;
        margin-bottom: 1rem; }
      &__group {
        display: grid;
        grid-template-columns: 1fr 40%;
        align-items: center!important;
        grid-gap: 20px;
        &__text {
          color: inherit;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          font-weight: 500;
          &__left {
            justify-content: flex-start;
            text-align: left!important; }
          &__right {
            justify-content: flex-end;
            text-align: right!important; } } } } } }
.reservations {
  background-color: rgba($colorNaranja,0.2 ); }
