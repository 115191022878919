@import "../_config";
.reservationModal {
    &__header {
        position: relative;
        &__title {
            color: #000;
            text-align: center;
            border-bottom: 1px solid $colorNaranja; }
        &__close {
            position: absolute;
            right: 1rem;
            top: -1.5rem;
            width: 30px;
            cursor: pointer;
            color: #000; } }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center; }
    &__button {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        background: #FF4E00;
        border-radius: 100px;
        margin: 8px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        color: #FFFFFF;
        padding: 0.6em 1em;
        text-align: center;
        border-color: #FF4E00;
        width: 90%;
        margin: 14px auto !important; } }
