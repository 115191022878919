@import "../_config";

.MessengerModal {
    box-sizing: border-box;
    width: 85vw;

    @media (min-width: $bp-desktop) {
        width: 500px; }
    &__header {
        border-bottom: 1px solid $colorNaranja!important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 12px!important;
        margin: 0!important;
        position: relative;
        &__title {
            margin: 0!important;
            font-size: 1.25rem;
            color: $colorNaranja!important;
            font-weight: 700; }
        &__cross {
            position: absolute;
            top: 50;
            right: 4%;
            width: 16px;
            color: $colorNaranja!important;
            cursor: pointer; } }
    &__section {
        padding: 20px 30px!important;
        margin: 20px 20px;
        box-sizing: border-box;
        * {
            box-sizing: border-box!important; }
        &__form {
            display: flex;
            flex-flow: column;
            align-items: center;
            width: 100%;
            &__title {
                font-family: $font-family-titles;
                font-size: 2rem;
                color: $colorNaranja!important;
                margin: 0!important;
                line-height: 100%!important; }
            &__text {
                color: $colorNaranja!important;
                margin: 0!important;
                font-weight: 700; }
            &__textarea {
                background: $colorBlanco;
                max-width: 90%;
                min-height: 150px;
                width: 100%;
                resize: none;
                border-radius: .5rem;
                border-color: $colorNaranja;
                font-size: 1.25rem;
                padding: 10px;
                color: $colorNaranja; }
            &__btn {
                @include btn-primary;
                width: 40%;
                margin: 20px 0;
                display: flex;
                justify-content: center;
                align-items: center; } } } }

