@import "../../config";

.EasyCharge {
  display: flex;
  color: #ffffff;
  background: $colorGrisNew;
  box-sizing: border-box;
  padding: 3rem 0 0 0;
  height: 20vh;
  @media (min-width: $bp-desktop) {
    height: 50vh; }
  &__headline {
    display: flex!important;
    flex-flow: column!important;
    justify-content: center!important;
    align-items: flex-start!important;
    width: 100%!important;
    box-sizing: border-box;
    margin: 0!important;
    @media (min-width: $bp-desktop) {
      max-width: 1200px!important;
      margin: 0 auto!important; }
    &__title {
      box-sizing: border-box;
      font-family: $font-family-titles;
      font-size: 2rem;
      padding: 0 1rem!important;
      color: $colorNaranja;
      line-height: 95%;
      width: 100%;
      @media (min-width: $bp-desktop) {
        padding: 0!important;
        font-size: 4rem;
        width: 50%; }
      &__orange {
        color: $colorNaranja!important;
        box-sizing: border-box;
        margin-bottom: 0!important;
        @media (min-width: $bp-desktop) {
          margin-bottom: 3rem; } } } } }
