@import "../../assets/styles/_config";
.editorialDesktop {
    background: #FFFFA4;
    @media only screen and (max-width: $bp-cell) {
        display: none; }
    &__nav {
        >div::nth-child(2) {
            >a {
                background: #000!important; } } }
    &__header {
        height: 100vh;
        width: 100%;
        background-image: url("./../../assets/static/Editorial/header-editorial.webp");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        @media only screen and (max-width: $bp-cell) {
            display: none; }
        &__description {
            display: flex;
            justify-content: space-between;
            height: 100px;
            width: 90%;
            margin: 0 auto;
            align-items: center;
            &__logo {
                width: 170px;
                height: 40px; }
            &__title {
                color: #fff;
                margin: 0;
                font-size: 2rem;
                font-weight: 400; } } }

    &__container {
        margin: 0 auto;
        max-width: 1200px;
        padding-bottom: 180px;
        &__imgGrid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 350px 350px;
            max-width: 100%;
            gap: 35px;
            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 24px; } }
        &__imgMaxContent {
            position: relative;
            height: 90vh;
            width: 100%;
            overflow: hidden;
            border-radius: 24px;
            &__img {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                transform: scale(1.5);
                object-fit: cover; } }
        &__titleDesktop {
            font-size: clamp(120pt, 155pt, 8vw);
            line-height: 70%;
            text-align: center;
            font-family: $font-family-titles;
            font-weight: 800; }
        &__seconTitleDesktop {
            font-size: 115pt;
            line-height: 70%;
            text-align: center;
            font-family: $font-family-titles;
            font-weight: 800;
            margin-top: 100px; }
        &__subtitle {
            font-size: 3rem;
            font-family: $font-family-times;
            line-height: 62px;
            text-align: center;
            font-weight: 400;
            margin: 80px auto; }
        >h3 {
            font-size: 3rem;
            font-family: $font-family-times;
            line-height: 100%;
            text-align: center; }
        &__colums,
        &__columsMargin,
        &__columsMarginMax {
            display: flex;
            justify-content: space-between;
            width: 73%;
            margin: 50px auto;
            >p {
                font-size: 18pt;
                font-family: $font-family-times;
                font-weight: 400;
                line-height: 32px;
                position: relative;
                width: max-content; }
            >p:nth-child(2) {
                display: flex;
                justify-content: flex-end;
                width: fit-content; }
            >h3 {
                font-size: 3rem;
                font-family: $font-family-times;
                line-height: 100%;
                margin: 0; }

            >h2 {
                font-size: 155pt;
                line-height: 70%;
                text-align: center;
                font-family: $font-family-titles;
                font-weight: 800; }

            &__separate {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                >h3 {
                    font-size: 3rem;
                    font-family: $font-family-times;
                    line-height: 62px; }
                >p {
                    font-family: $font-family-times;
                    font-size: 24pt;
                    font-weight: 400;
                    line-height: 32px;
                    width: max-content; } } }
        &__columsMargin {
            margin: 80px auto!important;
            width: 80%;
            >h3 {
                font-size: 2.8rem; } }
        &__columsMarginMax {
            margin: 80px auto!important;
            gap: 35px; }
        &__columsImg {
            width: 100%;
            height: 750px;
            overflow: hidden;
            border-radius: 30px;
            &__body,
            &__bodyTop {
                width: 100%;
                height: 100%;
                border-radius: 30px;
                object-fit: cover; }
            &__bodyTop {
                object-position: center top; }
            &__imgScale {
                object-fit: cover;
                width: 100%;
                height: 100%;
                transform: scale(1.3); } }

        &__figure {
            width: 51%;
            height: 800px;
            margin: 0 auto;
            position: relative;
            &__img {
                width: 100%;
                height: 100%;
                border-radius: 24px;
                object-fit: cover;
                aspect-ratio: 3/4; }
            &__logo {
                position: absolute;
                right: 5%;
                bottom: 5%;
                width: 41%; } }
        &__img {
            height: 100vh;
            margin: 0 auto;
            width: 100%;
            border-radius: 20px;
            object-fit: cover; }
        &__logoFooter {
            width: 300px;
            margin: 0 auto;
            padding-top: 120px;
            display: block; } } }
.linksBlack {
    background: #000!important; }
