@import "../config";
.bannerTransferMobile {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $colorNaranja!important;
    gap: 1rem;
    position:static {}
    &__logo {
        color:  $colorNaranja!important;
        background: #fff;
        border-radius: 100%;
        height: 60px;
        width: 60px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.2rem;
        font-weight: 800;
        @media (max-width: $bp-tablet) {
            display: none; } }
    &__title {
        margin: 0;
        color:  $colorNaranja!important;
        font-family: "CastaExtraBold", sans-serif;
        @media (max-width: $bp-tablet) {
            text-align: center; } } }
