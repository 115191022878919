.cardDetailUserValuations {
    width: 90%;
    margin: 20px auto 0 auto;
    border: 1px solid #000;
    padding: 16px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 250px;
    gap: 12px; }
