@import "../config";
.user_menu {
  display: flex!important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 26px!important;
  padding: 0rem!important;
  text-align: left!important;
  position: absolute;
  min-width: 200px!important;
  white-space: nowrap;
  top: 0;
  right: 0;
  z-index: 200!important;
  background: $colorBlanco!important;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px 0px rgba(43, 43, 43, 0.3);
  transform: translateX(1px) translateY(-1px);
  @media only screen and (max-width: $bp-cell) {
    overflow-y: scroll;
    height: 700px;
    left: -1px;
    min-height: 101vh;
    width: 100%;
    height: 100vh!important;
    position: fixed;
    border-radius: 0px!important;
    background: #fff!important; }

  .principal {
    position: relative;
    box-sizing: border-box;
    text-align: center!important;
    text-decoration: none!important;
    color: $colorNegroNew!important;
    font-size: 15px!important;
    line-height: 31px!important;
    width: 100%!important;
    padding: 0.25rem 1rem!important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    transition: all 100ms ease-in-out;
    gap: 4px;
    font-family: $font-family-RobotoBold;
    @media only screen and (max-width: $bp-cell) {
      gap: 0;
      border-radius: 0px!important;
      align-items: flex-start!important;
      justify-content: flex-start;
      flex-direction: column;
      line-height: 1!important;
      padding: 0!important;
      width: 80%!important;
      color: #fff!important;
      font-family: $font-family-RobotoBold;
      height: auto!important;
      text-align: left!important;
      margin: 0 10%!important;
      padding: 1rem 0!important; } }
  .principal.mobile {
    display: none;
    @media only screen and (max-width: $bp-cell) {
      display: flex;
      align-items: center; } }
  .last {
    color: $colorNaranja; }

  .separador {
    margin-top: 12px!important;
    margin-bottom: 12px!important;
    padding: 0px!important;
    border: 1px solid #E3E3E3!important; } }

.unread {
  position: relative;
  display: flex;
  &::after {
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 100px;
    background-color: $colorNaranja;
    position: absolute;
    top: 20%;
    left: 75%; } }

.user_menu__top {
  position: relative;
  background: transparent!important;
  border-radius: 0!important;
  color: $colorNegroNew;
  // border-bottom: 1px solid $colorNaranja
  display: flex!important;
  flex-flow: column;
  align-items: center;
  padding: 20px 0 0 0!important;
  width: 100%!important;
  min-width: 200px;
  @media only screen and (max-width: $bp-cell) {
    flex-direction: row;
    padding: 14px 30px 14px 25px!important;
    background: $colorNaranja!important;
    justify-content: space-between;
    background: #2b2b2b!important;
    border-color: rgba(255, 255, 255, .6);
    width: 80%!important;
    margin: 0 auto!important;
    padding: 16px 0!important; }
  &__avatar {
    color: $colorNaranja;
    background: #fff!important;
    padding: 0!important;
    width: 120px!important;
    height: 120px!important;
    border-radius: 100%;
    display: grid!important;
    place-items: center!important;
    position: center;
    @media only screen and (max-width: $bp-cell) {
      width: 42px!important;
      height: 42px!important; }
    img {
      object-fit: cover;
      border-radius: 50%;
      width: 7rem!important;
      height: 7rem!important;
      @media only screen and (max-width: $bp-cell) {
        width: 100%!important;
        height: 100%!important; } }
    svg {
      width: 50%;
      height: 50%; } }
  &__close {
    background: transparent!important;
    padding: 0!important;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 25px;
    width: 25px;
    z-index: 9999!important;
    color: $colorNegroNew!important;
    cursor: pointer;
    @media only screen and (max-width: $bp-cell) {
      position: static;
      display: flex;
      align-items: center; } }
  &__username {
    color: $colorNaranja;
    padding: 20px 20px 10px 20px!important;
    font-size: 2rem!important;
    font-family: $font-family-titles!important;
    @media only screen and (max-width: $bp-cell) {
      color: #fff; } } }
.principalWhite {
  position: relative;
  box-sizing: border-box;
  text-align: center!important;
  text-decoration: none!important;
  color: $colorNegroNew!important;
  font-size: 15px!important;
  line-height: 31px!important;
  width: 100%!important;
  padding: 0.25rem 1rem!important;
  display: flex;
  justify-content: center;
  transition: all 100ms ease-in-out;
  // border-bottom: 1px solid $colorNaranja
  border-bottom: 1px solid #2b2b2b!important;
  border-radius: 0!important;
  @media only screen and (max-width: $bp-cell) {
    border-radius: 0px!important;
    align-items: center!important;
    justify-content: flex-start;
    width: 80%!important;
    color: #fff!important;
    line-height: 1!important;
    height: auto!important;
    text-align: left!important;
    background: #fff;
    color: #000!important;
    margin: 0 10%!important;
    padding: 1rem 0!important;
    font-family: $font-family-RobotoBold!important; }
  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
    @media only screen and (max-width: $bp-cell) {
      border-radius: 0px!important;
      border-bottom: 1px solid; } } }
.principalBorderNone {
  border: none!important; }
@media only screen and (max-width: $bp-cell) {
  .mobileNone {
    display: none!important; } }
