.FormEditReservation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    max-height: 800px;
    gap: 24px;
    &__select {
        max-width: 200px;
        width: 100%;
        border: none;
        border-bottom: 1px solid orange; }
    >div {
        max-width: 200px; } }
