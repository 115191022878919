@import "../config";
.modalNone {
    display: none; }
.reservationModal {
    position: fixed;
    height: 400px;
    width: 500px;
    background: #fff;
    border-radius: 12px;
    padding: 2rem 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 16px 2px rgba(0,0,0,0.5);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    z-index: 50;
    @media (max-width: $bp-tablet) {
        width: 90%; }
    &__header {
        position: relative;
        &__title {
            color: #000;
            text-align: center;
            border-bottom: 1px solid $colorNaranja; }
        &__close {
            position: absolute;
            right: 1rem;
            top: -1rem;
            width: 25px;
            cursor: pointer; } }
    &__body {
        margin: 0 auto;
        width: 90%;
        color: #000;
        text-align: center; }
    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        background: #FF4E00;
        border-radius: 100px;
        margin: 8px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        color: #FFFFFF;
        padding: 0.6em 1em;
        text-align: center;
        border-color: #FF4E00;
        width: 90%;
        margin: 14px auto !important; } }
