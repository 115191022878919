@import "../config";

.ValuationContainer {
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  &__top {
    display: flex;
    grid-gap: 1rem;
    margin-bottom: 1rem;
    &__c {
      display: flex;
      flex-flow: column;
      justify-content: center;
      &__name,
      &__date {
        @include stepDescription;
        font-size: 1rem;
        line-height: 125%; }
      &__name {
        color: #000;
        font-weight: 600; }
      &__date {
        color: $colorGris;
        font-size: .75rem;
        margin-top: .25rem; } }
    &__img {
      width: 70px;
      height: 70px;
      img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        object-fit: cover; } } }
  &__text {
    @include stepSubtitle;
    color: #000;
    line-height: 150%;
    font-size: .9375rem; } }
