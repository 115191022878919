@import "../../config";

.Prefooter {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('../../../static/RentLanding/prefooter.jpg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: $mobile-height!important;
  position: relative;
  @media screen and (min-width: $bp-desktop) {
    justify-content: flex-end;
    height: 100vh!important; }
  &__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center; }
  &__title {
    color: #fff;
    font-family: $font-family-titles;
    font-weight: 500;
    font-size: 3.5rem;
    line-height: 95%;
    margin-bottom: 0em;
    text-align: center;
    @media screen and (min-width: $bp-desktop) {
      margin-bottom: .25em;
      font-size: 4.5rem; } }
  &-cta {
    @include CTAOnLanding;
    color: #fff!important;
    font-weight: 400;
    margin-bottom: 2rem!important;
    margin-top: 1rem!important;
    &-desktop,
    &-register {
      @include CTAOnLanding;
      color: #fff!important;
      font-weight: 400;
      margin-bottom: 2rem!important;
      margin-top: 1rem!important; }
    &-desktop {
      display: none;
      @media (min-width: $bp-desktop) {
        display: inline; } }
    &-register {
      display: inline;
      @media (min-width: $bp-desktop) {
        display: none;
        margin-bottom: 15%!important; } } }
  &__description {
    color: #fff;
    font-family: $font-family-titles;
    color: $colorAmarilloNew;
    font-size: 2rem;
    margin-bottom: 2rem;
    bottom: 0;
    position: absolute;
    @media (min-width: $bp-desktop) {
      font-size: 3rem; } } }
