@import "../config";

.Card {
  display: flex;
  flex-flow: column;
  color: #000;
  &__img {
    background-image: url('../../static/PlaceCards/place1.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 378px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    &__price {
      background: #ffffff;
      font-family: $font-family-base;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.25rem;
      text-align: center;
      text-transform: uppercase;
      padding: .5em 1em;
      border-radius: 22.5px;
      margin: 1.125rem; } }
  &__title {
    @include stepTitle;
    margin-top: 1.25rem;
    font-size: 1.5rem;
    line-height: 120%;
    color: #000; }
  &__description {
    @include stepDescription;
    margin-top: .5rem; } }
