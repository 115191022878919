@import "../../config";
.PhotosResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; } }
.Photos {
  max-width: 1200px;
  margin: 0 auto;
  justify-content: flex-start;
  height: 90%;
  padding: 20px;
  position: relative;
  @media (min-width: $bp-desktop) {
    padding: 20px 0 100px 0; }
  &__containerError {
    max-width: 100%!important;
    margin: 1.2rem 0;
    display: flex; }
  &__title {
    display: flex;
    align-items: center;
    &__h2 {
      font-size: 2rem;
      color: #000; } }
  &__container {
    display: flex;
    margin-bottom: 50px; } }
