.boxSendMessage,
.boxSendMessageHost {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 60px;
    background: rgba(204, 204, 204, 0.8);
    @media (max-width: 600px) {
        padding: 10px 10px 40px;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 20;
        background: rgba(204, 204, 204, 1); }
    &__input {
        border: 1px solid #ccc;
        border-radius: 8px;
        width: 100%;
        padding: 9px 12px 11px;
        font-size: 15px;
        resize: none;
        overflow: hidden;
        height: auto;
        z-index: 10; }
    &__button {
        background: none;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 12px;
        margin-bottom: 10px; } }
.boxSendMessageHost {
    @media (max-width: 600px) {
        padding: 10px 10px;
        padding-bottom: 80px;
        margin-bottom: -100px; } }
