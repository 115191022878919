@import "config";

@mixin button_orange {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: $colorNaranja;
    border-radius: 100px;
    margin: 8px;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 23px;
    color: #FFFFFF;
    padding: 0.6em 1em;
    text-align: center;
    border-color: $colorNaranja;

    // &:hover
    //     border: 2px;
    //     border-color: #999;
 }    //     border-style: solid;
