@import "../config";

.FavoriteSaveBtn {
  @include btn-outline(1rem, 0.1rem, 1rem);
  font-size: 0.8rem;
  text-align: center;
  max-width: 100px;
  margin-left: 20px;
  max-height: 30px;
  @media (min-width: $bp-desktop) {
    margin-left: 20px; }
  &.liked {
    @include btn-primary-props(1rem, 0.1rem, 1rem);
    font-size: 0.8rem; } }

