.Feature {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  &__icon,
  &__text {
    color: #000;
    font-size: 1rem; }
  &__icon {
    font-size: 1.25rem;
    margin-right: .5rem;
    &.small {
      font-size: .85rem; } }
  &__text {
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90%;
    &.small {
      font-weight: 400; } } }
