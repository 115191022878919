@import '../../_config.sass';

.UserData {
  box-sizing: border-box!important;
  display: block;
  width: 100%;
  &__hide-on-desktop {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    @media (min-width: $bp-desktop) {
      display: none; } }
  &__aloha {
    width: 100%;
    display: flex;
    flex-flow: column;
    position: relative;
    margin-bottom: 2rem;
    @media (min-width: $bp-desktop) {
      margin-bottom: 3rem; }
    &__title {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: .5rem;
      line-height: 120%;
      font-family: $font-family-titles;
      width: calc(100% - 64px);
      @media (min-width: $bp-desktop) {
        width: 100%;
        font-size: 3rem; } }
    &__created {
      font-size: .875rem;
      color: $colorGris;
      margin-bottom: .5rem; }
    &__avatar {
      position: absolute;
      width: 64px;
      height: 64px;
      right: 0;
      @media (min-width: $bp-desktop) {
        display: none; }
      &__img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover; } } }
  &__info {
    display: flex;
    flex-flow: column;
    width: 100%;
    &__title {
      font-size: 1.375rem;
      font-weight: 500;
      margin-bottom: 1rem; }
    &__about {
      font-size: 1rem;
      margin-bottom: 2rem; } } }
