@import '../config';
@mixin propertyContainer {
    width: 90%;
    max-width: 100vw!important;
    margin: 0 auto;
    @media (min-width: $bp-desktop) {
        width: 100%;
        max-width: 1200px!important;
        margin: 0 auto; } }
.PropertyComponent {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-sizing: border-box;
    &__separator {
        @include propertyContainer;
        border: 1px solid #E3E3E3;
        margin-bottom: 2rem!important; }
    &__info {
        @include propertyContainer;
        padding: .75rem 0!important;
        display: grid;
        grid-template-columns: 1fr!important;
        @media (min-width: $bp-desktop) {
            padding: 2rem 0 0 0!important;
            grid-template-columns: 1fr 35%!important;
            grid-gap: 3rem!important; } }
    &__reviews {
        @include propertyContainer; }
    &__location {
        @include propertyContainer;
        @media (min-width: $bp-desktop) {
            padding-bottom: 3rem; } }
    &__properties {
        display: none;
        @include propertyContainer;
        @media (min-width: $bp-desktop) {
            display: none; } } }
