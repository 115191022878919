@import "../../config";
.LocationResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; } }
.Location {
  padding: 20px;
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  height: 90vh;
  @media (min-width: $bp-desktop) {
    padding: 20px 0; }
  @media (min-width: $bp-big-display) {
    height: 75vh; }
  &__title {
    display: flex;
    align-items: center;
    // border-bottom: 1px solid #C4C4C4
    // margin-bottom: 20px
    &__h2 {
      color: $colorNaranja;
      width: 400px; } }
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin-bottom: 50px!important;
    @media (min-width: $bp-desktop) {
      grid-gap: 10px;
      grid-template-columns: 1fr 50%; }
    &-left,
    &-right {
      display: flex;
      flex-flow: column;
      width: 100%!important;
      max-width: 95%!important;
      margin: 0 auto;
      @media (min-width: $bp-desktop) {
        width: 100%!important;
        max-width: 100%!important; } }
    &__title {
      @include stepTitle;
      color: #000;
      font-size: 1.5rem!important;
      margin-bottom: 0rem!important;
      font-weight: 700; }
    &__description {
      @include stepDescription;
      color: #000;
      width: 100%;
      margin-bottom: 1rem!important;
      font-weight: 400;
      font-size: 1.2rem;
      @media (min-width: $bp-desktop) {
        width: 65%; } }
    &__inputContainerGrid {
      display: grid;
      grid-template-columns: 1fr; }
    &__inputContainerGridSecondary {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      @media (min-width: $bp-desktop) {
        grid-template-columns: 1fr 1fr;
        width: 82%; } }
    &__inputContainer__special {

      z-index: 2;
      * {
        z-index: 2; } }

    &__inputContainer {

      @include inputContainerStyle;
      margin-bottom: 2rem!important;
      width: 70%;
      @media (max-width: $bp-cell) {
        width: 100%; }

      &__label,
      &__labelSecondary {
        @include labelStyle;
        color: $colorNaranja;
        padding: 0;
        font-size: .8rem!important;
        font-weight: 400;
        letter-spacing: 0; }
      &__labelSecondary {
        padding: 0 0 0 .3rem; }
      &__select {
        @include selectStyle;
        width: 95%;
        color: #000!important;
        padding: 1.2rem 0 .4rem 0;
        font-size: 1rem!important;
        font-weight: 500;
        @media (min-width: $bp-desktop) {
          width: 100%; } }
      &__input {
        @include inputStyle;
        width: 95%;
        font-size: 1rem!important;
        border-style: none;
        color: #000!important;
        background: #fff!important;
        font-weight: 500;
        padding: 1.1rem 0 .3rem 0;
        @media (min-width: $bp-desktop) {
          width: 100%; } } } } }

.FormMap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 200px;
  margin-bottom: 1.25rem;
  z-index: 1;
  @media (min-width: $bp-desktop) {
    width: 100%;
    height: 346px; }
  span {
    display: none; }
  a {
    display: none; }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    width: 70%;
    &__btn,
    &__btn__green {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 120%;
      background: none; }
    &__btn {
      color: $colorGris; }
    &__btn__green {
      color: $colorVerde;
      &::before {
        padding-right: .5rem;
        content: "\2714"; } } } }

.FormMap > div {
  height: 100%; }
