@import "../../config";
.paymentResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; } }
.Payment {
  position: relative;
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: flex-start;
  padding: 20px;
  @media (min-width: $bp-desktop) {
    padding: 20px 0; }
  @media (min-width: $bp-big-display) {
    height: 70vh; }
  &__container {
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-bottom: 2rem;
    @media (min-width: $bp-desktop) {
      margin-bottom: 0;
      width: 30%; }
    &__title {
      @include stepSubtitle;
      color: #000;
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0; }
    &__description {
      @include stepDescription;
      color: #000;
      font-size: 1.2rem;
      width: 100%;
      @media (min-width: $bp-desktop) {
        width: 100%; } }
    &__inputContainer {
      @include inputContainerStyle;
      width: 100%!important;
      margin-top: 1rem;
      &__label {
        @include labelStyle;
        color: $colorNaranja;
        font-size: .8rem;
        font-weight: 400;
        padding: 1rem 0 0 0; }
      &__error {
        margin-top: 1rem;
        width: 91%; }
      &__input {
        @include inputStyle;
        color: #2B2B2B!important;
        width: 100%!important;
        font-size: 1rem;
        font-weight: 500;
        padding: 2.1rem 0 .3rem 0;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none!important; } }
      &__counter {
        color: #999999;
        font-size: 12px;
        float: right; }
      &__select {
        @include selectStyle;
        width: 100%!important; } } } }
