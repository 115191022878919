@import "../config";
@import "../button_orange_alt";


.ConfirmReservations {
  background: $colorNegroNew;
  color: #ffffff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; }
  * {
    box-sizing: border-box; }
  .principal_col {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px; }
  &__container {
    width: 100%;
    max-width: 85%;
    min-height: 70vh;
    margin: 0px auto;
    padding: 50px 0;
    box-sizing: border-box;
    @media (min-width: $bp-desktop) {
      padding: 0;
      margin: 50px auto;
      max-width: 1200px; } }
  &__title {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 33px;
    color: #fff!important;
    margin-bottom: 2rem;
    @media (min-width: $bp-desktop) {
      margin-bottom: 0; } }
  .wrapper_reservations {
    .from_reservations {
      margin: 20px 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: none;
      @media (min-width: $bp-desktop) {
        border-bottom: 1px solid $colorNaranja;
        display: flex;
        cursor: pointer;
        padding-bottom: 2rem; }
      &__select {
        padding: 5px;
        margin-right: 20px;
        font-weight: lighter;
        border-bottom: 1px solid $colorNaranja;
        width: 75%;
        @media (min-width: $bp-desktop) {
          width: auto; } }

      .selected {
        font-weight: bold;
        border-bottom: solid;
        border-bottom-color: #fff;
        border-bottom-width: 2px; } }

    .list_reservations {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      @media (min-width: $bp-desktop) {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 50px; } } } }
