@import "../../config";
.pricesResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; } }
.Prices {
  padding-top: 20px;
  position: relative;
  max-width: 1200px;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  @media (min-width: $bp-desktop) {
    padding: 20px 0;
    height: 80vh; }
  @media (min-width: $bp-big-display) {
    height: 60vh; }
  &__container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px!important;
    margin-bottom: 50px!important;
    @media (min-width: $bp-desktop) {
      grid-template-columns: 1fr 1fr; }
    &__left, &__right {
      display: flex;
      flex-flow: column;
      align-items: flex-start; }
    &__left {
      width: 74%;
      @media (max-width: $bp-desktop) {
        width: 100%; } }
    &__right {
      width: 80%;
      @media (max-width: $bp-desktop) {
        width: 100%; } }
    &__title {
      @include stepSubtitle;
      margin-bottom: 0rem!important;
      color: #000;
      font-weight: 700;
      font-size: 1.5rem; }
    &__descriptionLeft,
    &__descriptionRigth {
      @include stepDescription;
      margin-bottom: 1.5rem!important;
      color: #000;
      font-weight: 400;
      font-size: 1.2rem;
      width: 100%; }
    &__description {
      width: 100%;
      font-size: 1.2rem;
      font-weight: 400;
      &__tip, &__tipRigth {
        color: $colorNaranja;
        font-family: $font-family-base;
        font-weight: 400;
        font-size: .8rem;
        width: 100%; }
      &__tipRigth {
        width: 70%;
        color: $colorNaranja;
        font-weight: 500;
        font-size: .8rem; } }
    &__descriptionLeft {
      width: 100%;
      margin-bottom: 0!important;
      @media (min-width: $bp-desktop) {
        width: 90%; } }
    &__description__blue {
      @include stepDescription;
      color: $colorNaranja;
      margin-top: 1.2rem!important;
      font-size: 1rem;
      font-weight: 600; }
    &__inputContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-bottom: 2.5rem!important;
      &__price,&__priceRigth {
        position: relative;
        width: 80%; }
      &__priceRigth {
        width: 100%;
        @media (min-width: $bp-desktop) {
          width: 70%; } }
      &__input {
        @include inputStyle;
        color: #000!important;
        width: 100%;
        padding: 0rem;
        font-size: 1rem;
        width: 95%;
        @media (min-width: $bp-desktop) {
          width: 82%; } }
      &__input-webkit-outer-spin-button,
      &__input::-webkit-inner-spin-button {
        -webkit-appearance: none; }
      &__input[type="number"] {
        padding: .2rem 0rem 0rem 1rem!important; }
      &__prefix {
        @include inputPrefixStyle;
        display: flex;
        align-items: center;
        padding: .4rem 0rem 1rem 0rem;
        left: 0;
        position: absolute;
        font-size: 1rem; }
      &__select {
        @include selectStyle;
        width: 100%; }
      &__label {
        color: $colorNaranja;
        font-weight: 400;
        font-size: .8rem; }
      &__price {
        margin-top: 1.2rem; } } } }
