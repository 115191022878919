@import "../config";

.Accordion {
    display: flex;
    flex-flow: column;
    &__btn {
        background-color: #eee;
        color: #444444;
        cursor: pointer;
        padding: 1rem;
        display: grid;
        grid-template-columns: 1fr 10%;
        align-items: center;
        border: none;
        outline: none;
        transition: all 200ms ease;
        &:hover,
        &.active {
            background-color: $colorAmarilloNew;
            color: $colorNaranja; }
        &__title {
            font-weight: 500;
            font-size: 1rem;
            text-align: left; }
        &__icon {
            margin-left: auto;
            transition: transform 200ms ease;
            transform: rotate(-90deg);
            &.rotate {
                transform: rotate(0); } } }
    &__content {
        background-color: white;
        overflow: hidden;
        transition: max-height 200ms ease;
        &__text {
            font-size: 1rem;
            padding: 18px;
            b {
                color: $colorNaranja;
                font-weight: 500; }
            a {
                color: $colorNaranja!important;
                font-weight: 500;
                &:hover {
                    border-bottom: 2px solid $colorNaranja; } } } } }
