@import "../config";

.confirmedContainer {
  height: 100%;
  padding-top: 5rem;
  padding-bottom: 10rem;
  @media only screen and (min-width: $bp-desktop) {
    height: 100vh; } }
.Confirmed {
  // position: absolute
  width: 100%;
  /* Box Sizing */
  box-sizing: border-box;
  max-width: 100%!important;
  width: 90%!important;
  margin: 0 auto;
  /* Display */
  display: flex;
  flex-flow: column;
  left: 50%;
  top: 50%;
  height: 100%;
  // transform: translate(-50%, -50%)
  /* Style */
  padding-top: 2rem;
  @media only screen and (min-width: $bp-desktop) {
    max-width: 1100px!important;
    display: grid;
    grid-template-columns: 1fr 40%;
    grid-gap: 70px; }
  /* Boxsize all items */
  * {
    box-sizing: border-box!important; }
  &__content {
    display: flex;
    flex-direction: column;
    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0; } }
  &__container {
    /* Box Sizing */
    width: 100%;
    height: 100%;
    z-index: 1;
    &__title {
      font-size: 2rem!important;
      font-weight: 500;
      line-height: 110%;
      color: #333!important;
      margin-bottom: 1.5rem; }
    &__text {
      font-size: 1.25rem!important;
      font-size: 150%;
      color: #666!important;
      margin-bottom: 1rem!important;
      b {
        color: #333!important; } }
    &__link {
      color: $colorNaranja!important;
      font-weight: 700;
      margin-left: 5px; }
    /** Separador **/
    &__separator {
      border: 1px solid #E3E3E3;
      width: 100%;
      margin: 3.125rem auto; }
    &__details {
      width: 100%!important;
      display: flex;
      flex-flow: column;
      @media only screen and (min-width: $bp-desktop) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 6rem; }
      &__policy,
      &__rules {
        display: flex;
        flex-flow: column;
        &__title {
          font-size: 1.125rem!important;
          font-weight: 700!important;
          line-height: 150%;
          color: #666!important;
          margin-bottom: 1.125rem!important; }
        &__list {
          list-style: none!important;
          &__item {
            font-size: 1rem!important;
            line-height: 110%;
            color: #666!important;
            margin-bottom: 1rem!important; } }
        &__text {
          font-size: 1rem!important;
          vertical-align: text-top!important;
          color: #666!important;
          margin-bottom: 1rem!important; } } } }
  &__detail {
    padding: 3rem!important;
    background: $colorBlanco!important;
    box-shadow: 0 0 .5rem 0 #00000022!important;
    border-radius: 1.25rem!important;
    display: flex;
    flex-flow: column;
    justify-items: center;
    height: min-content!important;
    &__title {
      font-size: 1.125rem!important;
      font-weight: 700!important;
      color: #333!important;
      margin-bottom: .5rem!important; }
    &__location {
      font-size: .9rem!important;
      line-height: 130%!important;
      color: #333!important;
      margin-bottom: 2rem!important; }
    &__map {
      margin-bottom: 2rem!important;
      display: none;
      @media only screen and (min-width: $bp-desktop) {
        display: block;
        height: 100%; }
      span {
        display: none; }
      a {
        display: none; }
      .gmnoprint {
        display: none; } }
    &__map > div {
      height: 100%; }
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: .5rem;
      margin-bottom: 1.5rem!important;
      &__dates,
      &__people {
        font-size: 1.125rem!important;
        line-height: 130%!important;
        color: #333!important; }
      &__people {
        grid-area: 2 / 1 / span 1 / span 2; } }
    &__list {
      display: flex;
      flex-flow: column;
      list-style: none;
      margin-bottom: 1rem;
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E3E3E3;
        padding-bottom: .5rem;
        margin-bottom: .5rem;
        font-weight: normal;
        &:last-child {
          padding: 0;
          border: none;
          font-weight: 500; }
        &__desc,
        &__amount {
          @include stepDescription;
          font-weight: inherit;
          font-size: 1.125rem;
          line-height: 120%;
          color: #666; } } } } }
