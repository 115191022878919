@import '../../config';
.QuantitiesResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; } }
.Quantities {
  padding: 20px;
  max-width: 1200px;
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  width: 100%;
  justify-content: flex-start!important;
  height: 100vh;
  position: relative;
  @media (min-width: $bp-desktop) {
    padding: 20px 0; }
  @media (min-width: $bp-big-display) {
    height: 70vh; }
  &__container {
    width: 100%;
    margin-bottom: 20px;
    @media (min-width: $bp-desktop) {
      width: 80%; }
    &__textcontainer {
      display: grid;
      grid-template-columns: 1fr;
      padding: 10px 0 10px 0;
      @media (min-width: $bp-desktop) {
        grid-template-columns: 1fr 30%; } }
    &__numcontainer {
      display: grid;
      justify-content: start;
      align-items: center;
      grid-row-gap: 1.2rem;
      padding: 1rem 0;
      @media (min-width: $bp-desktop) {
        padding: 0 0 0 1rem;
        justify-content: end; } }
    &__separator {
      border: 1px #C4C4C4;
      margin: 1.2rem 0;
      border-bottom-style: dashed;
      border-width: 2px; }
    &__error {
      margin-top: .5rem;
      @media (min-width: $bp-desktop) {
        width: 50%; } } }
  &__title {
    @include stepTitle;
    font-size: 1.5rem!important;
    font-weight: 700;
    color: #000;
    margin: 0;
    align-items: center;
    display: flex;
    width: 100%!important; }
  &__text {
    @include stepTitle;
    font-size: 1.1rem!important;
    font-weight: 400!important;
    margin: 0rem!important;
    padding-top: .5rem;
    color: #000; }
  &__inputContainer {
    @include inputContainerStyle;
    margin-bottom: 2rem!important;
    &__label {
      @include labelStyle; }
    &__select {
      @include selectStyle;
      width: 70%!important; } }
  &__loadingContainer {
    display: flex;
    border-bottom: 1px solid #C4C4C4;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    &__title {
      color: $colorNaranja;
      font-size: 2rem;
      font-weight: 700;
      width: 100%;
      @media (min-width: $bp-desktop) {
        width: 32%; } }
    &__container {
      width: 100%;
      position: relative;
      background: #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: start;
      border-radius: 15px 0 0 15px;
      @media (min-width: $bp-desktop) {
        width: 50%; }
      &__loading {
        height: 10px;
        width: 7%;
        border-radius: 15px 0 0 15px;
        background: #07EAEA;
        position: relative;
        z-index: 5; } } } }
