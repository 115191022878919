@import "../../../assets/styles/_config.sass";
.successPayment {
    position: absolute;
    bottom: 0!important;
    right: 0!important;
    z-index: -10!important;
    &__title {
        background: none;
        color: transparent;
        z-index: -5!important; } }
