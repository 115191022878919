.buttongtm {
    color: transparent;
    background: transparent;
    width: 100%!important;
    height: 10px!important;
    &__button {
        z-index: -100!important;
        color: transparent;
        background: transparent;
        width: 1px!important;
        height: 1px!important;
        font-size: 1px!important; } }
