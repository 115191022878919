@import "../../config";
.ServicesResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; } }
.Services {
  max-width: 1200px;
  margin: 0 auto 0 auto!important;
  position: relative;
  height: 100vh;
  padding: 20px;
  @media (min-width: $bp-desktop) {
    padding: 20 0; }
  @media (min-width: $bp-big-display) {
    height: 70vh; }
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    justify-content: space-between;
    @media (min-width: $bp-desktop) {
      grid-template-columns: 40% 45%; }
    &-left,
    &-right, {
      display: flex;
      flex-flow: column; }
    &__inputContainer {
      @include inputContainerStyle;
      margin-bottom: 1rem!important;
      width: 100%!important;
      color: #000;
      &__textarea {
        @include textareaStyle;
        padding: .5rem 0 0 0!important;
        width: 100%!important;
        color: #000!important;
        height: 40px;
        font-size: 1rem!important;
        @media (min-width: $bp-desktop) {
          padding: .1rem 0!important;
          width: 75%!important; } }
      &__textarea::placeholder {
        color: #000!important;
        font-weight: 500;
        font-size: 1rem; } }
    &__title {
      @include stepTitle;
      font-weight: 700;
      font-size: 1.5rem!important;
      color: #000;
      margin: 0!important; }
    &__descriptionLeft,
    &__descriptionRigth {
      @include stepDescription;
      width: 70%;
      font-weight: 400!important;
      color: #000;
      font-size: 1.2rem!important;
      padding-bottom: 15px;
      @media (min-width: $bp-desktop) {
        width: 100%; } }
    &__descriptionRigth {
      width: 70%;
      @media (min-width: $bp-desktop) {
        width: 95%; } } }
  .second {
    margin-top: 1.25rem!important;
    color: $colorNaranja;
    font-size: .8rem!important;
    font-weight: 400!important; }
  .Buttons {
    margin-bottom: 4rem!important;
    @media (min-width: $bp-desktop) {
      margin-bottom: 0rem!important; } } }
