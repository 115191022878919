.manualTransfer {
    display: grid;
    grid-template-columns: repeat(30, 1fr);
    padding: 16px 0;
    gap: 1rem;
    overflow-x: scroll;
    width: 85vw;
    position: relative;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
        width: 4px;
        height: 6px;
        border: none;
        background: transparent; }
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-track-piece,
    &::-webkit-scrollbar-corner,
    &::-webkit-resizer {
        display: none; }
    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: #bdbdbd; }
    &::-webkit-scrollbar-track {
        background-image: url("../../../assets/static/track.png");
        // background-image: url("../../../")
        background-color: transparent;
        background-repeat: repeat-y;
        background-size: contain; }
    >div {
        width: 200px; }
    &__button {
        position: fixed;
        width: 10vw!important;
        right: 1%; }
    &__cancel,
    &__buttonEdit {
        padding: 1rem 3rem !important;
        font-size: 1rem !important;
        border-radius: 100px !important;
        font-family: "Roboto", sans-serif !important;
        font-weight: 500 !important;
        color: #fff !important;
        background: #FF4E00 !important;
        box-sizing: border-box;
        border: 1px solid #FFFFFF !important;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0.25rem 1rem !important;
        font-size: 0.75rem !important; }
    &__cancel {
        min-width: 90px; } }

.modalCreateReservation {
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    border-radius: 12px;
    @media only screen and (max-width: 600px) {
        width: 90%; }
    &__container {
        display: flex;
        gap: 24px;
        width: 100%;
        justify-content: center!important;
        @media only screen and (max-width: 600px) {
            flex-direction: column; } } }

.modalEdit {
    >div {
        >div {
            max-width: max-content;
            max-height: max-content;
            justify-content: center!important; } } }
.allowRowEvents {
    background: red;
    &:hover {
        white-space: normal!important;
        overflow: hidden!important;
        text-overflow: inherit!important; } }
