@import "../../config";

.Guarantees {
  background: $colorGrisNew;
  display: grid;
  height: 100vh!important;
  grid-template-columns: 1fr 1fr;
  color: #ffffff;
  @media (max-width: $bp-cell) {
    height: auto!important;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto; }
  &__headline {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("../../../static/RentLanding/guarantee.jpg");
    background-size: cover;
    display: flex!important;
    flex-flow: column!important;
    justify-content: center!important;
    align-items: center!important;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 0!important;
    height: $mobile-height!important;
    @media (min-width: $bp-desktop) {
      height: 100%!important;
      margin-bottom: 3em!important; }
    &__container {
      display: flex!important;
      flex-flow: column!important;
      justify-content: center!important;
      align-items: flex-start!important;
      width: 90%;
      @media (min-width: $bp-desktop) {
        width: 70%; }
      &__text {
        font-family: $font-family-titles;
        font-size: 4rem;
        line-height: 95%;
        &__orange {
          color: $colorNaranja!important;
          margin-bottom: 1rem!important; } }
      &__cta {
        @include CTAOnLanding;
        font-weight: 400!important;
        padding: 1rem 3.25rem!important;
        margin: 0!important;
        &__desktop,
        &__register {
          @include CTAOnLanding;
          font-weight: 400!important;
          padding: 1rem 3.25rem!important;
          margin: 0!important; }
        &__desktop {
          display: none;
          @media (min-width: $bp-desktop) {
            display: inline; } }
        &__register {
          display: inline;
          @media (min-width: $bp-desktop) {
            display: none; } } } } }
  &__description {
    display: flex!important;
    flex-flow: column!important;
    justify-content: center!important;
    align-items: center!important;
    padding: 3rem 0;
    @media (min-width: $bp-desktop) {
      padding: 0; }
    &__container {
      width: 90%;
      @media (min-width: $bp-desktop) {
        width: 66%; }
      &__title {
        font-family: $font-family-titles;
        font-size: 2rem;
        margin-bottom: 1rem!important;
        @media (min-width: $bp-desktop) {
          font-size: 2.5rem;
          margin-bottom: 3rem!important; } }
      &__list {
        width: 100%;
        list-style: none;
        li {
          // list-style-image: url('../../../static/RentLanding/list-style.svg');
          font-family: $font-family-base;
          font-weight: 400;
          font-size: 1em;
          line-height: 150%;
          padding-left: 2rem;
          margin-bottom: .5rem;
          &::before {
            position: absolute;
            margin-left: -2rem;
            content: url('../../../static/RentLanding/list-style.png'); } } } } } }
