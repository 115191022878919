@import "../config";
.FAQContainer {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    min-height: 80vh;
    display: grid;
    grid-template-columns: 250px 1fr;
    @media (max-width: $bp-cell) {
        min-height: 0;
        grid-template-columns: 1fr; }
    &__sideBar {
        width: 100%;
        background: #2B2B2B;
        padding-top: 40px;
        @media (max-width: $bp-cell) {
            display: none; } } }
.FAQ {
    display: none;
    @media only screen and (min-width: $bp-desktop) {
        @include stepContainer;
        display: grid;
        grid-template-columns: 15% 1fr;
        grid-template-rows: 100px 1fr;
        grid-gap: 30px; }
    &__title {
        grid-area: 1 / 1 / span 1 / span 2;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-family-titles;
        font-size: 4.5rem;
        color: $colorNaranja; }
    &__menu {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fit, 50px);
        grid-gap: 10px;
        justify-content: center;
        align-items: center;
        &__question {
            font-family: $font-family-base;
            font-weight: 500;
            text-align: left;
            padding: .5rem 1rem;
            transition: all 50ms ease-in-out;
            cursor: pointer;
            &.active {
                font-weight: 700;
                color: $colorNaranja;
                border-left: 3px solid $colorNaranja;
                transition: all 50ms ease-in-out; }
            &:hover {
                font-weight: 700;
                color: $colorNaranja;
                border-left: 3px solid $colorNaranja;
                transition: all 50ms ease-in-out; } } } }

.FAQMobile {
    @include stepContainer;
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    @media only screen and (min-width: $bp-desktop) {
        display: none; }
    * {
        box-sizing: border-box; }
    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.5rem;
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        &__title {
            text-align: center;
            font-family: $font-family-titles;
            color: $colorNaranja;
            width: 50%; }
        &__btn {
            display: block;
            height: 2rem;
            width: 2rem;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            position: absolute;
            left: 0;
            display: none;
            &::before {
                position: absolute;
                transform: translate(-50%, -50%)!important;
                top: 50%;
                left: 50%;
                content: "<";
                color: $colorNaranja;
                font-size: 2rem; }
            &.active {
                display: block; } } }
    &__menu {
        display: none;
        &.active {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(auto-fit, 50px);
            grid-gap: 30px;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0 10px; }
        &__question {
            font-family: $font-family-base;
            font-weight: 500;
            text-align: center;
            padding: .5rem 1rem;
            justify-self: center;
            transition: all 50ms ease-in-out;
            width: 100%;
            cursor: pointer;
            &.active {
                font-weight: 700;
                color: $colorBlanco;
                background: $colorNaranja;
                transition: all 50ms ease-in-out; }
            &:hover {
                font-weight: 700;
                color: $colorBlanco;
                background: $colorNaranja;
                transition: all 50ms ease-in-out; } } } }
