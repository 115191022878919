.valuationComments {
    border-top: 1px solid #000;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    position: relative;
    gap: 20px;
    &__link {
        color: #000!important; }
    &__content {
        display: flex;
        align-items: center;
        gap: 20px; }
    &__text {
        width: 80%;
        text-align: left; }
    &__button {
        position: absolute;
        right: -10px;
        bottom: -10px;
        background: #FF4E00;
        border-radius: 12px;
        padding: 4px 6px;
        color: #fff; } }
