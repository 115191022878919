@import "../config";

.SmallProgressBar {
    display: grid;
    grid-template-columns: 15% 1fr;
    align-items: center;
    grid-gap: 10px;
    width: 100%;
    &__title {
        color: $colorNaranja;
        font-size: .75rem!important;
        white-space: nowrap; }
    &__bar {
        display: flex;
        align-items: center;
        justify-items: center;
        background: #EBEBEB;
        border-radius: 50px;
        height: .5rem;
        width: 100%;
        &__progress {
            box-sizing: border-box;
            margin: 0 .15rem!important;
            background: $colorNaranja;
            border-radius: 50px;
            height: .25rem;
            width: 1%; } } }
