@import "../../config";

.RadioBlack {
  display: flex;
  margin-bottom: 1rem;
  color: $colorBlanco;
  &.black {
    color: #000000; }
  &__radio {
    display: none!important; }
  &__container {
    display: grid!important;
    flex-flow: column!important;
    justify-content: center!important;
    align-items: center!important;
    justify-content: flex-start!important;
    position: relative!important;
    &__title,
    &__titleDisabled {
      @include stepSubtitle;
      font-size: 1.5rem!important;
      cursor: pointer!important;
      color: inherit!important; }
    &__titleDisabled {
      color: #CCC!important; }
    &__description {
      @include stepDescription;
      font-size: 1.1rem!important;
      color: inherit !important;
      margin-top: .2rem!important;
      width: 100%;
      @media (min-width: $bp-desktop) {
        margin-bottom: 1.5rem!important;
        width: 93%; } }
    &__label {
      display: grid;
      grid-template-columns: auto 3fr;
      cursor: pointer;
      span {
          margin: 0!important; }
      &__text {
        color: #000;
        display: flex!important;
        flex-flow: column!important;
        padding-left: 2.25rem!important; } }

    &__circle,
    &__circleDisabled, {
      height: 1rem!important;
      width: 1rem!important;
      border: 1px solid $colorNaranja;
      background: $colorBlanco;
      border-radius: 50%!important;
      position: absolute; }

    &__circleDisabled {
      background: #CCCCCC;
      border: 1px solid #CCCCCC; }
    &__circleDisabled::after {
      content: "";
      height: .5rem!important;
      width: .5rem!important;
      background: $colorNaranja!important;
      position: absolute!important;
      border-radius: 50%!important;
      top: 50%!important;
      left: 50%!important;
      transform: translate(-50%, -50%) scale(0)!important;
      transition: 200ms ease-in-out 0s!important; }
    &__circle::after {
      content: "";
      height: .5rem!important;
      width: .5rem!important;
      background: $colorNaranja!important;
      position: absolute!important;
      border-radius: 50%!important;
      top: 50%!important;
      left: 50%!important;
      transform: translate(-50%, -50%) scale(0)!important;
      transition: 200ms ease-in-out 0s!important; } }
  &__radio:checked + &__container__label > &__container__circle::after {
    transform: translate(-50%, -50%) scale(1)!important; } }
