@import "../../config";

.ActiveFilters {
  display: flex;
  flex-flow: wrap;
  margin: 1rem;
  padding: 0;
  .none {
    display: none; }
  @media only screen and (min-width: $bp-desktop) {
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-bottom: 1.25rem; }
  &__Pills {
    background: #DDD;
    color: $colorNegroNew;
    font-size: .75rem;
    padding: .25rem .5rem;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: .25rem;
    transition: all 100ms ease-in-out;
    margin: 5px 2px!important;
    @media only screen and (max-width: $bp-cell) {
      font-family: "Roboto",sans-serif;
      line-height: 12px;
      font-weight: 400; }
    &:hover {
      background: #BBB;
      transition: all 100ms ease-in-out; }
    &__close {
      height: .85rem;
      width: .85rem;
      cursor: pointer; } }
  &__reset {
    cursor: pointer;
    font-size: .75rem;
    font-weight: 500;
    color: $colorNegroNew;
    text-decoration: underline!important;
    transition: all 100ms ease-in-out;
    padding: .25rem .5rem;
    border-radius: 20px;
    margin: 0 0!important;
    &:hover {
      background: #BBB;
      transition: all 100ms ease-in-out; } } }
