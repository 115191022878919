@import "../config";

.cerrar {
  display: none; }
.input_wrapperNew  > input {
  @media (max-width: $bp-cell) {
    font-size: 11vw!important; } }
.input_wrapperNew  > div > div {
  @media (max-width: $bp-cell) {
    height: 100%; } }
.input_wrapperNew  > div {
  @media (max-width: $bp-cell) {
    height: 100%; } }
.input_wrapperNew  > div > div > div {
  margin: 0 auto;
  padding: 0;
  margin-top: 2px;
  width: 250px;
  @media (max-width: $bp-cell) {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%!important;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 0; } }

.input_wrapperNew  > div > div > div >  button {
  display: none; }
.input_wrapperNew  > div > div > div> div > input {
  color: #000;
  border: none!important;
  margin: 0 auto!important;
  font-size: 2vw;
  font-weight: bold;
  @media (max-width: $bp-cell) {
    font-size: 6vw;
    width: 100%;
    text-align: center; }
  @media (min-width: $bp-cell) {
    text-align: left; }
  &::placeholder {
    margin: 0 auto!important;
    font-weight: bold;
    color: #000;
    border: none;
    text-align: center;
    font-size: 7.5vw;
    @media (min-width: $bp-cell) {
      font-size: 2vw; } } }
.input_wrapperNew  > div > div > div > div:nth-child(1)::after {
  content: "";
  position: absolute;
  right: 0;
  width: 3px;
  top: 50%;
  transform: translateY(-50%);
  background: #000;
  height: 65%;
  @media (max-width: $bp-cell) {
    height: 100%; } }
.input_wrapperNew  > div > div > div > div:nth-child(2) {
  padding-left: 10px;
  left: 50%!important;
  transform: translateX(-50%);
  padding: 16px;
  border-radius: 0 0 50px 50px;
  height: auto!important;
  top: 118%!important;
  @media (max-width: $bp-cell) {
    top: -130%!important; } }
.DateInput_fangStroke,
.DateInput_fang {
  @media (max-width: $bp-cell) {
    display: none; } }
.input_wrapperNew  > div > div > div> div:nth-child(1)> input {
  position: relative;
  padding-left: 1rem;
  text-align: right;
  font-size: 2vw;
  font-weight: 800;
  min-width: 110px!important;
  @media (max-width: $bp-cell) {
    width: 100%!important;
    font-size: 7.5vw;
    text-align: left!important; } }
.input_wrapperNew  > div > div > div> div:nth-child(3)> input {
  padding-right: 1rem;
  font-weight: 800;
  text-align: left!important;
  font-size: 2vw;
  @media (max-width: $bp-cell) {
    width: 100%!important;
    font-size: 7.5vw;
    text-align: right!important; } }

.input_wrapperNew  > div > div > div> div:nth-child(1)> input::placeholder {
  @media (min-width: $bp-cell) {
    text-align: center; } }

.input_wrapperNew  > div > div > div> div:nth-child(3)> input::placeholder {
  @media (min-width: $bp-cell) {
    text-align: center; } }

.input_wrapperNew  > div > div > div> div:nth-child(1)> input::placeholder,
.input_wrapperNew  > div > div > div> div:nth-child(3)> input::placeholder {
  @media (max-width: $bp-cell) {
    text-align: center!important; } }

.navSearch > span >.input_wrapperNew  > div > div > div> div> input {
  font-size: 1rem; }
.navSearch > span >.input_wrapperNew  > div > div > div> div> input::placeholder {
  font-size: 1rem!important; }
.navSearch > div > input {
  font-size: 1rem!important; }
.navSearch > div> input::placeholder {
  font-size: 1rem!important; }

.MainInputSearch {
  /* Box Sizing */
  box-sizing: border-box;
  max-width: 90vw;
  min-width: 90vw;
  max-height: auto;
  min-height: auto;
  width: 100%;
  height: auto;
  /* Display */
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  /* Styles */
  background: #FFF;
  border-radius: 34px;
  padding: 1rem 1.5rem;
  @media (min-width: $bp-desktop) {
    /* Box Sizing */
    max-width: 950px;
    min-width: 950px;
    max-height: 80px;
    min-height: 80px;
    /* Display */
    grid-template-columns: 40% 20% 1fr 10%;
    grid-gap: 20px;
    align-items: center;
    /* Styles */
    padding: 0;
    &.navbar {
      /* Box Sizing */
      max-width: 600px;
      min-width: 600px;
      max-height: 75px;
      min-height: 60px;
      margin-left: -100px; } }
  * {
    box-sizing: border-box; }
  &__cities {
    display: none;
    @media (min-width: $bp-desktop) {
      margin-left: 3rem; }
    /* Si necesitas editar esto dame una call para ahorrar tiempo jeje, atte. Naka */
    .input_wrapper,
    .input_wrapperNew {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $colorNaranja;
      color: $colorNaranja;
      font-weight: 500;
      padding-bottom: .25rem;
      position: relative;

      @media (min-width: $bp-desktop) {
        width: 100%; }
      @media (max-width: $bp-tablet) {
        flex: 1 0 100%;
        max-width: -webkit-fill-available;
        max-width: -moz-available;
        margin-right: 15px; }
      &:focus {
        outline-width: 0; }
      .input {
        width: 100%;
        border: none;
        font-size: 9vw;
        color: $colorNaranja;
        font-weight: 800;
        background: none;
        &::placeholder {
          color: $colorNaranja;
          font-weight: 800;
          @media (max-width: $bp-tablet) {
            text-align: center; } }
        &:focus {
          outline-width: 0; } }
      .lista_ciudades {
        display: none;
        font-size: 12px;

        @media (min-width: $bp-desktop) {
          font-size: 1rem; } }

      .lista_ciudades.visble {
        z-index: 90;
        display: inline;
        top: 80%;
        height: 187px;
        left: -15px;
        box-shadow: none;
        padding: 24px 12px 12px 24px;
        border-radius: 0 0 30px 30px;
        @media (min-width: $bp-desktop) {
          top: 73%;
          position: absolute; } } } }
  &__cities,
  &__dates,
  &__people {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    @media (min-width: $bp-desktop) {
      padding: 1rem 0;
      max-height: 80px; } }
  &__dates {
    @media (min-width: $bp-desktop) {
      width: 380px; }
    .input_wrapper {
      width: 100%; } }

  &__people {
    .input_wrapper {
      display: flex;
      align-items: center;
      color: $colorNaranja;
      font-weight: 500;
      font-size: .85rem;
      position: relative;
      width: 100%;
      border-bottom: 1px solid $colorNaranja;
      padding-bottom: .25rem;
      @media (min-width: $bp-desktop) {
        width: 65%; } }
    .input_wrapperNew {
      display: flex;
      align-items: center;
      color: #000;
      font-weight: 500;
      font-size: 24px;
      position: relative;
      width: 100%;
      padding-bottom: .25rem;
      @media (min-width: $bp-desktop) {
        width: 65%; } }
    .person_select {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      cursor: pointer;
      width: 100%;
      margin-left: 8px;
      padding-right: 8px;
      @media (min-width: $bp-desktop) {
        justify-content: center;
        min-height: none;
        max-width: 20px;
        margin: 0;
        padding: 0;
        height: auto; } }
    .personas_wrapper {
      display: flex;
      flex-direction: column;
      .close {
        display: none;
        width: 25px;
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer; }
      hr {
        margin-top: 15px;
        margin-bottom: 15px; }
      .opciones_personas {
        display: flex;
        flex-wrap: no-wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .options {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: center;
          justify-content: space-between; }
        .contador {
          font-size: 14px; } }
      .round_boton {
        color: #FFF7E9E1;
        margin-left: 10px;
        margin-right: 10px;
        display: grid;
        place-items: center;
        background: $colorNaranja;
        border-radius: 50%;
        font-size: 20px;
        height: 30px;
        width: 30px;
        border-width: 0px;
        font-weight: bold; } }
    .lista_personas {
      top: 100%;
      left: 0%;
      width: 200px;
      background: #FFF7E9;
      padding: 20px;
      display: none;
      box-shadow: 0px 1px 6px -3px #111;
      z-index: 99;
      width: 70vw;
      border-radius: 0 0 10px 10px;
      @media (min-width: $bp-desktop) {
        position: absolute;
        top: 110%;
        width: 230px;
        .close {
          display: block; } } } }
  &__searchButton {
    display: none;
    @media (min-width: 500px) {
      cursor: pointer;
      box-sizing: border-box;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transform: translateX(30px);
      background: $colorNaranja;
      &__img {
        width: 55%;
        height: 55%;
        object-fit: cover; } } }
  &__container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    &__label {
      color: #000;
      font-weight: 500;
      font-size: 0.94vw;
      padding-bottom: .25rem;
      line-height: 20px;
      position: relative;
      @media (max-width: $bp-cell) {
        margin: 0;
        font-size: 1rem;
        line-height: 30px; } }

    &__alert {
      position: relative; }
    &__alert::after, {
      content: "Por favor, complete las fechas de llagada y salida";
      bottom: -110px;
      right: -210px;
      background: #FFF7E9;
      padding: 5px 10px;
      border-radius: 5px;
      position: absolute;
      color: #000;
      font-weight: 500;
      z-index: 1;
      width: 200px;
      @media (max-width: $bp-cell) {
        bottom: -200px;
        transform: translate(50%,0%); } }
    &__alert::before, {
      content: "";
      height: 0px;
      width: 0px;
      position: absolute;
      bottom: -60px;
      right: -50px;
      border: 10px solid transparent;
      border-top: 0;
      border-bottom: 10px solid #FFF7E9;
      border-radius: 10px;
      z-index: 1;
      @media (max-width: $bp-cell) {
        display: none; } } } }
.MobileMainInputSearch:active {
  background: #CE4103; }

.MobileMainInputSearch {
  box-shadow: 1px 4px 6px 0px rgba(43,43,43,0.3);
  max-width: 90vw;
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 0.8rem 1rem;
  margin-top: 1rem;
  margin-bottom: 10px;
  box-sizing: border-box;
  background: $colorNaranja;
  width: 100%;
  @media (min-width: $bp-desktop) {
    display: none; }
  &__button {
    color: $colorBlanco;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: none;
    font-family: $font-family-base;
    font-weight: 700;
    font-size: 1.25rem; }
  // img
  //   width: 2rem
  //   margin: 0 0
  p {
    color: $colorBlanco;
    align-items: center;
    background: none;
    font-family: $font-family-base;
    font-weight: 700;
    font-size: 1.25rem;
    margin-left: 30%; } }

.MobileMainInputSearchButtonsContainer {
  max-width: 90vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  z-index: 10;
  @media (min-width: $bp-desktop) {
    display: none; }
  &__btn {
    @include btn-primary;
    padding: .5rem auto;
    width: 100%;
    color: #FFFFFF;
    box-sizing: border-box; } }

@media (min-width: $bp-desktop) {
  .MainInputSearch.navbar {
    background: #ffffff;
    font-size: .8rem;
    grid-template-columns: 1fr 25% 1fr 1fr;
    padding-bottom: 0rem;
    .MainInputSearch__cities {
      margin-left: 1.5rem; }
    .MainInputSearch__date {
      width: 100%; }
    .MainInputSearch__cities,
    .MainInputSearch__people {
      width: 100%; }
    .MainInputSearch__people {
      .input_wrapper,
      .input_wrapperNew {
        width: 75%; } }
    .MainInputSearch__searchButton {
      /* Box Sizing */
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      transform: translateX(-10px);
      z-index: 50;
      &__img {
        height: 75%;
        width: 75%;
        object-fit: cover; } } }
  .visibleLateral {
    &::-webkit-scrollbar-track {
      background-color: #fff;
      transition: 0.2s ease-in-out; }
    &::-webkit-scrollbar {
      width: 8px;
      background-color: #F5F5F5; }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #FF4E00CE;
      transition: 0.2s ease-in-out;
      display: block; } } }

.homeInputCity {
  width: 100%; }
.homeInputCity > .input_wrapper {
  background: #fff;
  margin: 0 auto;
  width: 100%;
  height: auto;
  border-radius: 40px 40px 70px 70px;
  max-height: 300px;
  min-height: 50px;
  padding: 0 15%;
  padding-bottom: 12px;
  @media (max-width: $bp-cell) {
    max-height: 240px;
    padding-bottom: 0; } }

.homeInputCity > .input_wrapper > input {
  background: #fff;
  border: none;
  font-size: 6vw;
  text-align: left;
  border-bottom: 1px solid #000;
  width: 100%;
  margin: 0 auto;
  font-weight: bold;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0; }
.homeInputCity > .input_wrapper > input::placeholder {
  font-size: 6vw;
  color: #000;
  font-weight: bold; }
.homeInputCity > .input_wrapper > .visble {
  overflow-y: scroll;
  z-index: 90;
  width: 100%;
  min-height: 100px;
  height: 80%;
  max-height: 200px;
  margin: 16px auto 32px auto;
  top: 0%;
  box-shadow: none;
  font-weight: bold;
  @media (max-width: $bp-cell) {
    max-height: 175px;
    margin-bottom: 0;
    padding: 0; } }
.DayPicker {
  box-shadow: none; }
