@import "../config";
.CheckoutContainer {
  width: 100%;
  height: 100%; }

.mobbexContainer>div>div:nth-child(1) {
  background: red;
  opacity: 0;
  height: 1px;
  width: 1px; }
.Checkout {
  /* Box Sizing */
  box-sizing: border-box;
  max-width: 100%!important;
  width: 100%!important;
  margin: 0 auto!important;
  /* Display */
  display: flex;
  flex-direction: column;
  /* Style */
  padding: 3rem 1rem!important;
  @media only screen and (min-width: $bp-desktop) {
    padding: 0 0!important;
    max-width: 1200px!important; }
  /* Boxsize all items */
  * {
    box-sizing: border-box!important; }
  &__loading {
    width: auto;
    max-width: 50px;
    text-align: center;
    left: 50%;
    position: relative;
    transform: translateX(-25px);
    margin-bottom: 25px; }
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    @media only screen and (min-width: $bp-desktop) {
      grid-template-columns: 60% 1fr;
      grid-gap: 3rem; } }
  &__title {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 2rem;
    @media only screen and (min-width: $bp-desktop) {
      grid-area: 1 / 1 / span 2 / span 1!important; } }
  &__form,
  &__detail {
    display: flex;
    flex-flow: column; }
  &__form {
    &__cardsContainer {
      display: flex;
      flex-flow: column; }
    &__cards {
      height: auto;
      width: 250px;
      margin-top: -1.5rem;
      margin-left: -1.5rem;
      margin-bottom: .5rem; }
    &__title,&__button {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 1rem!important; }
    &__button {
      color: blue;
      text-align: left;
      strong {
        color: #000; }
      &__planConainer {
        &__planCuotas {
          color: #000;
          &__bankTitle {
            color: #000;
            .Radio {
              // color:red!important
              &__radio {
 }                // color:red!important
              &__container {
                &__label {} }
              &__container {
                &__label {
                  &__text {
                    color: #000!important; } }
                &__title {
                  color: #000!important; }
                &__description {
                  color: #000!important; } } } } } } }
    &__two {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 100%!important;
      width: 100%!important; }
    &__inputContainer {
      @include inputContainerStyle;
      padding-top: 54px;
      margin-bottom: 1rem;
      &__label {
        @include labelStyle; }
      &__input {
        @include inputStyle;
        width: 100% !important;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none!important;
          margin: 0!important; } }
      &__select {
        @include selectStyle;
        color: #000;
        width: 100%; }
      &__title {
        font-size: 1.4rem!important;
        font-weight: 800; }
      &__description {
        font-weight: 500;
        font-size: 1.2rem; } } }
  &__detail {
    @media only screen and (min-width: $bp-desktop) {
      padding: 0 1rem!important; }
    &__card {
      display: none;
      @media only screen and (min-width: $bp-desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem!important;
        width: 100%!important; } }
    &__data {
      display: flex;
      flex-flow: column;
      margin: .5rem 0;
      @media only screen and (min-width: $bp-desktop) {
        margin: .5rem 0; }
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.125rem;
        &:not(:last-child) {
          margin-bottom: .5rem; }
        &__text {
          font-weight: 500; } } }
    &__list {
      display: flex;
      flex-flow: column;
      list-style: none;
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E3E3E3;
        padding: .5rem 0;
        margin: .5rem 0;
        font-weight: normal;
        &:last-child {
          padding: 0;
          border: none;
          font-weight: 500; }
        &__desc,
        &__amount {
          @include stepDescription;
          font-weight: inherit;
          font-size: 1.125rem;
          line-height: 120%;
          color: $colorNegroNew;
          >strong {
            font-size: .8rem;
            font-weight: 400;
            color: $colorGris; } }

        &.featured {
          background: $colorAmarilloNew;
          font-weight: 500; }
        &__amount {
          text-align: right; } } }
    &__btn {
      @include btn-primary;
      width: 100%;
      padding: 1.25rem!important;
      margin-bottom: 1.5rem!important;
      display: flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        background: #999 !important;
        cursor: not-allowed;
        pointer-events: none; }

      img {
        width: 1.125rem;
        height: 1.125rem; }
      p {
        font-size: 1.125rem;
        line-height: 100%;
        margin-left: .5rem!important; } }
    &__disabled {
      @include btn-primary;
      pointer-events: none;
      width: 100%;
      padding: 1.25rem!important;
      margin-bottom: 1.5rem!important;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #999 !important;
      cursor: not-allowed;
      font-size: 1.125rem;
      line-height: 100%;
      margin-left: .5rem!important;
      img {
        width: 1.125rem;
        height: 1.125rem; } }
    &__link {
      padding: 1.25rem!important;
      margin-bottom: 1.5rem!important;
      font-size: .85rem;
      background: none!important;
      color: $colorNaranja!important;
      &:hover {
        text-decoration: 1px solid $colorNaranja!important; } }
    &__mp {
      display: flex;
      flex-flow: column;
      align-items: center;
      gap: 1rem;
      & > button.Checkout__detail__btn {
        color: #32b3ff!important;
        border: solid 2px #32b3ff!important;
        background: none!important;
        margin: 0!important;
        &:hover {
          color: #FFF!important;
          background: #32b3ff!important; } }
      img {
        max-width: 100%; } }
    &__legal {
      font-size: .9rem;
      color: #999; } }
  .no-border-left {
    border-left: none!important; } }
.wppSendDisabled {
  cursor: not-allowed;
  pointer-events: none; }
