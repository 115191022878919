@import '../../../assets/styles/_config.sass';

.modalNotFound {
    position: relative;
    width: 90%;
    height: 90%;
    max-width: 700px;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 54px 0;
    border-radius: 24px;
    // background-image: url('../../../assets/static/backgrounds/backgroundMountains.png')
    &__close {
        position: absolute;
        right: 24px;
        top: 24px;
        cursor: pointer; }
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__titleError,
        &__title {
            text-align: center;
            color: #fff; }
        &__titleError {
            font-size: 154px;
            font-weight: 700;
            font-family: $font-family-titles; }
        &__title {
            font-weight: 500;
            font-size: 17px;
            margin: 0 50px!important; } } }
