@import "../config";

.EmptyState {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc( 100vh - 104px );
    &__container {
        display: flex;
        flex-flow: column;
        align-items: center;
        &__imgcontainer {
            height: auto;
            width: 350px!important;
            &__img {
                height: 100%;
                width: 100%;
                object-fit: cover; } }
        &__title {
            font-family: $font-family-titles;
            font-size: 3rem;
            color: currentColor;
            text-align: center; }
        &__link {
            color: currentColor!important;
            text-decoration: underline!important;
            font-size: 1.5rem; } } }
