@import "../config";
.SideMenuBefore {
    // background: #2B2B2B
    width: 100%;
    margin-right: -2px; }
.SideMenu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &__link {
        background: #2B2B2B;
        width: 100%;
        &__exit {
            padding-top: 30px;
            display: flex;
            justify-content: start;
            align-items: center;
            margin-left: 65px;
            img {
                margin-right: 10px; }
            p {
                color: #fff;
                font-family: Roboto;
                line-height: 18px;
                font-size: 16px;
                font-weight: 500; } }
        &__icon {
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 10px 0;
            margin-left: 65px;
            &__svg {
                color: #fff;
                width: 30px;
                height: 30px;
                margin-right: 10px;
                margin-left: 0px;
                circle {
                    color: #fff; } }
            p {
                color: #fff;
                font-family: Roboto;
                line-height: 20px;
                font-size: 18px;
                font-weight: 500; } } } }
.activeFavorites {
    border-radius: 30px 0px 0px 30px;
    background: #fff;
    width: 150px;
    margin-right: -131px;
    p {
        color: #000; } }

.iconActiveFavorites {
    display: flex;
    justify-content: center;
    color: #000;
    background: #FFF;
    border-radius: 100px;
    margin-left: -65px;
    width: 100%;
    padding: 20px 0;
    svg {
        color: #000!important;
        stroke: #000!important;
        circle {
            color: #000; }
        path {
            stroke: #000; } } }

.activeHelp {
    border-radius: 30px 0px 0px 30px;
    background: #fff;
    width: 150px;
    margin-right: -131px;
    p {
        color: #000;
        padding-right: 65px; } }
.iconHelpActive {
    display: flex;
    justify-content: center;
    color: #000;
    background: #FFF;
    border-radius: 100px;
    width: 100%;
    padding: 20px 0;
    margin-left: -65px;
    &__svg {}
    color: #000;
    svg {
        margin-left: 15px;
        margin-right: 10px;
        color: #000!important;
        stroke: #000!important;
        circle {
            color: #000; }
        path {
            stroke: #000; } }
    p {
        margin-right: -20px!important; } }

.activeHousing {
    border-radius: 30px 0px 0px 30px;
    background: #F7F7F8;
    width: 150px;
    p {
        color: #000; } }

.iconActive {
    display: flex;
    justify-content: center;
    color: #000;
    background: #F7F7F8;
    border-radius: 100px;
    margin-left: -65px;
    width: 100%;
    padding: 20px 0;
    &__svg {
        color: #000; }
    svg {
        margin-left: 15px;
        margin-right: 10px;
        color: #000!important;
        stroke: #000!important;
        circle {
            color: #000; }
        path {
            stroke: #000; } }
    p {
        margin-right: -20px!important; } }

.borderBottom {
    background: #2B2B2B;
    width: 90%;
    padding: 10px 0;
    margin-left: -1px;
    border-radius: 0 30px 0  0; }
.borderTop {
    background: #2B2B2B;
    width: 90%;
    padding: 10px 0;
    margin-top: 0;
    margin-left: -1px;
    border-radius: 0  0 30px  0; }
