@import "../config";

.Headline {
  width: 100%;
  text-align: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../../static/RentLanding/headline-bg.jpg');
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100vh;
  @media (min-width: $bp-desktop) {
    //background: none
    height: 100vh; }
  &__video {
    display: blocks;
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    object-fit: cover; }
  &__link {
    display: block;
    position: absolute;
    top: 15%;
    @media (min-width: $bp-desktop) {
      display: none; }
    &__logo {
      height: 40px; } }
  .Headline__container {
    position: relative;
    display: flex!important;
    flex-direction: column!important;
    align-content: center!important;
    justify-content: center!important;
    align-items: center!important;
    height: 100vh;
    @media (min-width: $bp-desktop) {
      height: $desktop-height; }
    &__cc {
      margin: 0!important;
      display: flex;
      align-items: center;
      flex-direction: column;
      @media (min-width: $bp-desktop) {
        margin-bottom: 7.5%!important; } } }
  .Headline__title {
    font-family: $font-family-titles;
    color: $colorBlanco;
    font-size: 4.5rem;
    line-height: 110%;
    font-weight: 500;
    margin-bottom: 2rem;
    @media (min-width: $bp-desktop) {
      font-size: 4.5rem;
      margin-bottom: 2rem; } }
  .Headline__text {
    font-family: $font-family-titles;
    color: $colorAmarilloNew;
    font-size: 2rem;
    margin-bottom: 2rem!important;
    position: absolute;
    bottom: 0;
    @media (min-width: $bp-desktop) {
      font-size: 2.5rem;
      margin-bottom: 4rem!important; } }
  .Headline__cta {
    @include CTAOnLanding;
    padding: 1rem 2rem;
    margin-top: 2rem!important;
    @media (min-width: $bp-desktop) {
      margin-bottom: 30vh!important; } }
  .Headline__cta__desktop {
    @include CTAOnLanding;
    margin-top: 2rem!important;
    display: none;
    @media (min-width: $bp-desktop) {
      display: inline; } }
  .Headline__cta__register {
    @include CTAOnLanding;
    display: inline;
    margin-top: 2rem!important;
    @media (min-width: $bp-desktop) {
      display: none; } } }
