@import "../../assets/styles/_config";
.editorialMobile {
    background: #FFFFA4;
    padding-bottom: 150px;
    @media only screen and (min-width: $bp-cell) {
        display: none; }
    &__container {
        width: 90%;
        margin: 0 auto;

        &__titles {
            width: 90%;
            margin: 0 auto;
            &__title,
            &__h3,
            &__text,
            &__textMax {
                font-family: $font-family-times;
                width: max-content;
                font-size: 1rem;
                &__space {
                    margin-top: 12px;
                    display: block; } }
            &__textMax {
                display: block;
                width: 90%;
                font-size: 4vw;
                margin: 0 auto!important; }

            &__title,
            &__secondTitle {
                font-size: 65px;
                line-height: 52px;
                font-family: $font-family-times;
                font-weight: 800;
                padding-top: 50px; }
            &__h3 {
                font-size: 1.6rem;
                line-height: 120%;
                margin-bottom: 24px; }
            &__secondTitle {
                margin-top: 180px;
                margin-left: -14px; } }

        &__imagesScale {
            position: relative;
            height: 270px;
            width: 100%;
            overflow: hidden;
            margin: 45px auto 45px auto;
            border-radius: 24px;

            &__img {
                width: 100%;
                height: 100%;
                border-radius: 24px;
                position: absolute;
                left: 0;
                top: 0;
                transform: scale(1.5);
                object-fit: cover; } }
        &__logo {
            display: block;
            margin: 0 auto;
            width: 200px; }
        &__images {
            width: 90%;
            height: auto;
            object-fit: cover;
            border-radius: 24px;
            margin: 24px auto;
            display: block;
            &__imgSmall,
            &__imgSmallSecond,
            &__imgBig {
                object-fit: cover;
                border-radius: 24px;
                display: block;
                margin: 0 auto;
                width: 80%;
                height: 380px; }
            &__imgBig {
                height: 260px;
                width: 100%; }
            &__imgSmallSecond {
                margin: 34px auto!important;
                width: 90%; } }
        &__imagesDoble {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2.5%;
            margin: 30px 0;
            width: 100%;
            &__imgFloatLeftTop,
            &__imgFloatLeftBottom {
                object-fit: cover;
                border-radius: 24px;
                margin: 0 0 50% 0;
                width: 100%;
                height: 120px; }
            &__imgFloatLeftBottom {
                margin: 50% 0 0 0; } } } }
