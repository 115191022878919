.prev::after, .next::after {
    content: "none";
}

@media (max-width: 800px) {
    .next {
        right: 11% !important;
        z-index: 100 !important;
    }

}