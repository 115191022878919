@import "../config";
@import "../button_orange_alt";


.user_travel_page {
    .principal_col {
        padding-top: 100px;
        padding-right: 50px;
        @media (max-width: $bp-cell) {
            padding-left: 0px!important;
            padding-right: 0px!important; } }
    main {
        margin-bottom: 200px;
        padding-left: 50px;
        @media (max-width: $bp-cell) {
            padding: 0 10px 0 10px!important; } }
    .my {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 33px;
        color: #333333; }

    .wrapper_travels {
        margin-top: 50px;

        .from_travels {
            display: flex;
            margin-right: 5px;
            margin-bottom: 20px;
            margin-top: 20px;
            cursor: pointer;
            border-bottom: 1px solid #E3E3E3;
            padding-bottom: 2rem;
            @media (max-width: $bp-cell) {
                display:block!important {} } }
        .from_travel {
            margin-right: 20px;
            padding: 5px;
            font-weight: lighter; }
        .select {
            font-weight: bold;
            border-bottom: solid;
            border-bottom-color: $colorNaranja;
            border-bottom-width: 2px; }
        .list_travels {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;
            @media (min-width: $bp-desktop) {
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 50px; } } } }

