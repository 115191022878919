@import "../_config";

.modalActive {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  height: 100vh;
  background: rgba(#2B2B2B, .5);
  z-index: 90;
  width: 100%;
  left: 0;
  &__modal {
    animation: show .200s .200s backwards;
    display: flex;
    justify-content: center;
    position: fixed;
    background: #38D1DA;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    max-height: 500px;
    max-width: 700px;
    z-index: 200;
    transform: translate(-50%,-50%);
    &__container {
      width: 90%;
      height: 90%;
      margin: auto auto;
      position: relative;
      display: flex;
      flex-flow: column;
      justify-content: center;
      &__close {
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        &__img {
          width: 30px;
          height: 30px;
          background: none; }
        &__hand {
          width: 45px;
          height: 45px;
          margin: 1.5rem auto 0 auto;
          @media (min-width: $bp-desktop) {
            width: 60px;
            height: 60px;
            margin: 0 auto 1.2rem auto; } } }
      &__title,
      &__description {
        margin: 0 auto;
        text-align: center;
        width: 100%;
        color: #2B2B2B; }
      &__title {
        line-height: 100px;
        font-weight: 800;
        font-size: 3rem;
        font-family: $font-family-titles;
        @media (min-width: $bp-desktop) {
          font-size: 6rem; } }
      &__description {
        width: 65%;
        font-family: $font-family-base;
        font-weight: 500;
        font-size: 1rem;
        align-self: center;
        @media (min-width: $bp-desktop) {
          font-size: 1.2rem; } }
      &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
        font-family: $font-family-base;
        color: #2B2B2B!important;
        padding: 1rem 1.2rem;
        box-shadow: 0px 4px 6px rgba(43, 43, 43, 0.3);
        background: #fff;
        border-radius: 100px;
        width: 100%;
        margin: 3rem auto 1.5rem auto;
        @media (min-width: $bp-desktop) {
          margin: 3rem auto 0 auto;
          width: 55%; }
        &__icon {
          width: 25px;
          height: 25px;
          margin-right: 1rem; } } }

    @keyframes show {
      0% {
        transform: translate(-50%, -350%); }
      100% {
        transform: translate(-50%, -50%); } } } }
.modalNone {
  display: none; }
