.StarRating {
  box-sizing: border-box;
  width: 100%!important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__title {
    font-size: 1rem;
    line-height: 182.5%;
    text-transform: capitalize;
    white-space: nowrap;
    color: #000;
    width: 40%; } }
