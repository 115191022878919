@import "../../config";
.guestsResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; } }
.Guests {
  max-height: 100vh;
  height: 100vh;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  @media (min-width: $bp-desktop) {
    padding: 20px 0; }
  &__container {
    display: flex;
    flex-flow: column;
    position: relative;
    &__selects {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%!important;
      margin: 1.5rem 0 5rem 0;
      @media (min-width: $bp-desktop) {
        grid-template-columns: 1fr 1fr;
        max-width: 40%!important; } }
    &__inputContainer {
      @include inputContainerStyle;
      color: $colorBlanco;
      width: 100%;
      @media (min-width: $bp-desktop) {
        width: 75%; }
      &__label {
        @include labelStyle;
        color: $colorNaranja;
        padding: 0;
        font-size: .8rem;
        font-weight: 400!important;
        letter-spacing: 0!important; }
      &__select--100 {
        @include selectStyle;
        width: 100%!important;
        color: #000;
        padding: 1.2rem 0 .4rem 0;
        font-size: 1rem;
        @media (min-width: $bp-desktop) {
          width: 22%!important; } }
      &__select--40 {
        @include selectStyle;
        font-size: 1rem;
        padding: 1.2rem 0 .4rem 0;
        width: 100%!important;
        color: #000!important; }
      &__input {
        @include inputStyle;
        width: 100%!important;
        color: #000!important;
        padding: .9rem 0 0 0; }
      &__input__time {
        @include inputStyle;
        padding: .9rem 0 .3rem 0;
        color: #000!important;
        width: 100%!important;
        @media (min-width: $bp-desktop) {
          width: 80%!important; } } }
    &__title, &__subtitle {
      @include stepTitle;
      font-weight: 700;
      font-size: 1.5rem!important;
      line-height: 130%!important;
      color: #000;
      width: 100%;
      @media (min-width: $bp-desktop) {
        width: 60%; } }
    &__subtitle {
      margin: 0; }
    &__description {
      @include stepDescription;
      width: 100%!important;
      color: #000;
      font-size: 1.2rem;
      @media (min-width: $bp-desktop) {
        width: 60%!important; } } }
  .no-border-left {
    border-left: none; }
  .max_width_50 {
    @media (min-width: $bp-desktop) {
      width: 50%!important; } } }
