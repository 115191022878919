@import "../../config";

.PropertyReviews {
  width: 100%;
  &__title {
    @include stepSubtitle;
    display: flex;
    align-items: center;
    font-weight: bold;
    line-height: 120%;
    font-size: 1.5rem;
    @media (min-width: $bp-desktop) {
      line-height: 160%;
      font-size: 1.25rem; } }
  &__progressBars {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    width: 100%!important;
    @media (min-width: $bp-desktop) {
      width: 70%!important;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-gap: 2rem 3rem; }
    &__container {
      display: grid;
      grid-template-columns: 45% 1fr 10%;
      grid-gap: .5rem;
      width: 100%;
      align-items: center;
      justify-content: center;
      @media (min-width: $bp-desktop) {
        grid-template-columns: 45% 1fr 5%; }
      &__title,
      &__number {
        font-size: 1rem;
        line-height: 120%;
        display: flex;
        align-items: center; }
      &__title {
        color: #000; }
      &__number {
        color: #999;
        justify-content: center; }
      &__progressBar {
        height: 2px!important;
        background: #CCC;
        width: 100%;
        border-radius: 2px;
        &__progress {
          height: 2px!important;
          background: $colorNaranja;
          width: 40%;
          border-radius: 2px; } } } }
  &__reviews {
    margin-top: 3rem;
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr;
    @media (min-width: $bp-desktop) {
      grid-template-columns: repeat(2, 1fr); }
    &__container {
      display: flex;
      flex-flow: column;
      &__top {
        display: flex;
        grid-gap: 1rem;
        margin-bottom: 1rem;
        &__c {
          display: flex;
          flex-flow: column;
          justify-content: center;
          &__name,
          &__date {
            @include stepDescription;
            font-size: 1rem;
            line-height: 125%; }
          &__name {
            color: #000;
            font-weight: 600; }
          &__date {
            color: $colorGris;
            font-size: .75rem;
            margin-top: .25rem; } }
        &__img {
          width: 70px;
          height: 70px;
          img {
            border-radius: 50%;
            height: 100%;
            width: 100%;
            object-fit: cover; } } }
      &__text {
        @include stepSubtitle;
        color: #000;
        line-height: 150%;
        font-size: .9375rem; } } }
  &__more {
    display: none;
    grid-gap: 2rem;
    align-items: center;
    margin-top: 5rem;
    @media (min-width: $bp-desktop) {
      display: grid;
      grid-template-columns: 1fr 20% 1fr; }
    &__separator {
      background: #CCC;
      width: 100%;
      height: 1px;
      border-radius: 1px; }
    &__btn {
      background: none;
      border-radius: 100px;
      color: #999;
      border: 1px solid #999!important;
      padding: 1rem; } } }
