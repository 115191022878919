@import "../../config";
.SmallCard {
  color: #000;
  width: 95%;
  box-sizing: border-box;
  padding-bottom: 2rem;
  border-bottom: 1px solid #E3E3E3;
  display: grid;
  grid-template-columns: 1fr 53%;
  grid-gap: 1.75rem;
  margin-bottom: 34px;
  @media only screen and (max-width: $bp-cell) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: .75rem;
    margin: 0 auto;
    margin-bottom: 2rem; }
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      display: grid;
      place-items: center; } }
  &__imgcontainer {
    width: 100%;
    height: 350px;
    border-radius: 20px;
    @media only screen and (max-width: $bp-cell) {
      height: 25vh; }
    &__img {
      border-radius: 20px;
      object-fit: cover;
      width: 100%;
      height: 100%; } }

  &__infocontainer {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    @media only screen and (max-width: $bp-cell) {
      height: auto; }
    &__previaje {
      position: absolute;
      width: 100%;
      max-width: 60px;
      top: 15%;
      right: 0;
      opacity: .75; }
    &__data {
      display: flex;
      flex-flow: column;
      &__type {
        font-family: $font-family-base;
        color: $colorNegroNew;
        font-size: 1rem;
        font-weight: 500;
        text-transform: lowercase;
        margin-bottom: .25rem; }
      &__title {
        font-family: $font-family-base;
        color: $colorNegroNew;
        line-height: 130%;
        font-size: 1.5rem;
        margin-bottom: .25rem;
        font-weight: 700;
        text-transform: uppercase;
        @media only screen and (max-width: $bp-cell) {
          font-size: 1.5rem; } }
      &__description {
        font-family: $font-family-base;
        color: $colorNegroNew;
        font-size: .75rem;
        font-weight: 400;
        text-transform: lowercase; }
      &__review {
        margin-top: 2rem;
        display: block;
        color: $colorNaranja;
        @media only screen and (max-width: $bp-cell) {
          margin-top: .75rem; }
        &__header {
          display: flex;
          &__name {
            font-size: 1.125rem;
            line-height: 110%;
            text-transform: capitalize;
            font-weight: 500;
            font-family: $font-family-base; } }
        &__text {
          margin-top: .5rem;
          font-size: 14px;
          line-height: 1.125rem;
          color: $colorNegro;
          @media only screen and (max-width: $bp-cell) {
            margin-top: .75rem;
            margin-bottom: 1rem; } } } }
    &__price {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $font-family-base;
      color: $colorNegroNew;
      &__loading {
        width: 75px;
        margin: 0 auto; }
      &__reviews {
        display: flex;
        align-items: center;
        font-weight: 700;
        &__number {
          @include btn-primary;
          width: 100%;
          text-align: center;
          line-height: 130%;
          box-shadow: 0px 2px 2px #05030326;
          font-size: 12px!important;
          padding: 1rem 1.25rem!important;
          width: 150px;
          @media only screen and (min-width: $bp-big-display) {
            padding: 1.25rem 1.5rem!important;
            width: 201px;
            font-size: 1rem!important; } }

        &__img {
          margin-left: .25rem;
          width: 1.25rem;
          height: 1.25rem; } }
      &__amount {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        &__numbers,
        &__pernight {
          color: $colorNegro;
          white-space: nowrap; }
        &__numbers {
          font-size: 1.25rem;
          line-height: 110%;
          font-weight: 700; }
        &__pernight {
          font-size: .8rem;
          line-height: 110%;
          font-weight: 400; }
        &__info {
          font-size: 1rem;
          line-height: 110%;
          font-weight: 400; } } } } }
