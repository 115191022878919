.inputGlobal {
    height: 30px;
    position: relative;
    border-bottom: 1px solid #ff4e00;
    width: 100%;
    &__title {
        position: absolute;
        top: -20px;
        left: 4px;
        color: #ff4e00;
        font-size: .8rem;
        font-weight: 400; }
    &__input,
    &__inputMask {
        z-index: -10;
        border: none;
        color: transparent;
        position: absolute;
        right: 0;
        top: 0; }
    &__input::disabled {
        color: #ccc; }
    &__inputMask {
        z-index: 9;
        color: #000;
        font-weight: 500;
        font-size: 1rem; }
    &__input::-webkit-input-placeholder {
        color: transparent; }
    &__view {
        font-weight: 500;
        font-size: 1rem;
        border: none;
        color: #000;
        width: 100%;
        background: transparent;
        padding: 0 14px;
        text-align: right;
        cursor: text; }
    &__view:disabled {
        color: #ccc; }
    &__view[type=number]::-webkit-inner-spin-button,
    &__view[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none; } }
