@import "../../config";

.EasyChargeDescription {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: $colorGrisNew;
  box-sizing: border-box;
  padding: 0rem 0 3rem 0;
  height: 100vh;
  @media (min-width: $bp-desktop) {
    height: 50vh; }
  &__description {
    display: flex!important;
    flex-flow: column!important;
    justify-content: flex-start!important;
    align-items: flex-start!important;
    @media (min-width: $bp-desktop) {
      margin-top: -10%; }
    &__container {
      display: grid;
      width: 100%;
      max-width: 90vw!important;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      padding: 0 1rem;
      @media (min-width: $bp-desktop) {
        padding: 0!important;
        margin: 0 auto!important;
        max-width: 1200px!important;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 4rem; } }
    &__card-title {
      font-family: $font-family-titles;
      margin-bottom: 2.5rem!important;
      font-size: 1.5rem;
      line-height: 95%;
      @media (min-width: $bp-desktop) {
        font-size: 2.5rem; } }
    &__card-text {
      font-family: $font-family-base;
      font-weight: 400;
      font-size: 1em;
      text-align: justify;
      width: 100%;
      @media (min-width: $bp-desktop) {
        width: 85%; } } } }
