@import "../_config";
@import "../button_orange";
@import "../button_gray";

.modalOverlay {
    position: fixed;
    width: 100%; /* Full width (cover the whole page) */;
    height: 100%; /* Full height (cover the whole page) */;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */;
    z-index: 2; }

.modalLogin {
    // position: fixed;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: red;
    background: #ffffff;
    // top: 5%
    // left: 50%
    // transform: translateX(-50%);
    box-shadow: -3px 9px 24px rgba(43, 43, 43, 0.4);
    transition: all 0.3s ease-out;
    z-index: 100;
    box-sizing: border-box;
    border-radius: 30px!important;
    @media (max-width: $bp-tablet) {
        // width: 95%!important
        overflow: hidden;
 }        // overflow-y: scroll

    .succcess_register {
        font-size: 16px; }
    // color: #999999
    //  text-align: center
    //  padding: 25px
    //  display: flex
    //  flex-direction: column
    //  align-items: center

    p {
        color: #999999;
        line-height: 22px;
        text-align: center;
        margin-top: 15px; }

    header {
        position: relative;
        text-align: center;
        padding: 8px 0;
        border-bottom-style: solid;
        border-bottom-color: #CCCCCC;
        border-bottom-width: 1px;
        font-size: 20px;
        line-height: 30px;

        .x {
            position: absolute;
            top: 50;
            right: 0;
            cursor: pointer;
            padding-right: 15px;
            padding-top: 10px;
            width: 13px; } }

    section {
        padding-left: 35px;
        padding-right: 35px;
        @media (max-width: $bp-cell) {
            padding: 0 10px!important; }
        .separador {
            margin: 10px 0;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-grow: 4;
            @media (max-width: $bp-cell) {
                padding: 0 8px!important; }
            .ingresa_email {
                min-width: max-content;
                padding-left: 13px;
                padding-right: 13px;
                color: #999999;
                font-size: 12px; }
            hr {
                flex-grow: 0;
                width: -webkit-fill-available; } }

        .input_continer {
            display: flex;
            flex-direction: row;
            align-items: center;
            @media (max-width: $bp-cell) {
                flex-direction: column;
                padding: 0 8px!important; }
            .recover_wrapper {
                width: 100% !important; }

            .input_wrapper {
                display: flex;
                flex-direction: column;
                max-width: -webkit-fill-available;
                max-width: -moz-available;
                border: 1px solid #E1E1E1;
                box-sizing: border-box;
                width: 50%;
                padding: 15px 20px;
                min-width: 300px;
                margin-top: 20px;
                @media (max-width: $bp-cell) {
                    min-width: none!important;
                    width: 100%!important;
                    margin-top: 0!important; }
                &.error {
                    border: 1px solid $colorNaranja; }

                input {
                    width: 100%;
                    height: 15px;
                    min-height: 15px;
                    margin-top: 5px;
                    border: none;
                    font-size: 13px;
                    &:focus {
                        outline-width: 0; } }
                &__passwordWraper {
                    width: 100%;
                    display: flex;
                    flex-flow: row; }
                i:hover {
                    color: $colorNaranja;
                    cursor: pointer; } }

            label {
                font-size: 11px;
                font-weight: bold;
                text-transform: uppercase; } } }

    .register__terms {
        margin-top: .5rem!important;
        font-size: .75rem;
        color: #999999;
        text-align: left; }
    .fake_link {
        margin-top: 20px;
        margin-bottom: 20px;
        color: $colorNaranja;
        cursor: pointer;
        &:hover {
            text-decoration: underline; } }
    .register {
        color: $colorNaranja;
        font-size: 12px;
        float: left;
        margin-top: 20px;
        margin-bottom: 50px;
        cursor: pointer;
        @media (max-width: $bp-cell) {
            margin: 20px 0!important; } }

    .recover_password {
        color: $colorNaranja;
        font-size: 12px;
        float: right;
        margin-top: 20px;
        margin-bottom: 50px;
        cursor: pointer;
        @media (max-width: $bp-cell) {
            margin: 20px 0!important; } }
    .social_login_container {
        display: flex;
        padding-top: 15px;
        justify-content: center;
        cursor: pointer;
        @media (max-width: $bp-cell) {
            flex-flow: column;
            align-items: center; }
        img {
            max-width: 310px;
            @media (max-width: $bp-cell) {
                max-width: 100%; } } }
    .button_orange {
        @include button_orange;
        width: 100%;
        margin: 14px auto!important; }

    .button_gray {
        @include button_gray;
        width: 100%;
        border-style: solid !important;
        border-color: #666666 !important;
        border-width: 2px !important;
        border-radius: 100px !important;
        margin-right: 5px;
        margin-left: 5px;
        padding: 0.6em 1em !important;
        margin: 14px !important; } }
