@import '../config';
.Error404 {
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background: url('../../static/Landing/backgrounds/5d20kdvFCfA.png') no-repeat;
  background-size: cover;
  &__container {
    display: flex;
    margin: 0 auto;
    &__text {
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 50rem;
      margin: 0 auto;
      &__subtitle {
        height: 18rem;
        font-family: "CastaExtraBold", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20rem;
        line-height: 110%;
        letter-spacing: 0.2rem;
        color: #fff;
        text-transform: uppercase;
        text-align: center; }
      &__title {
        @include titles($colorNegro);
        font-size: 1.5rem!important;
        color: #fff;
        text-align: center; }
      &__description {
        @include normalText($colorNegro);
        margin-bottom: 3rem; }
      &__btn {
        background: #fff!important;
        border-radius: 100px!important;
        color: $colorNaranja!important;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        font-family: $font-family-base;
        font-weight: 700;
        font-size: 1rem;
        margin: 10rem auto 0 auto;
        justify-content: space-around;
        width: 14.2rem;
        padding: 1.2rem 1.5rem; } }
    &__img {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin: 0 auto;
      height: 500px;
      margin-bottom: 75px;
      img {
        width: 100%;
        height: 100%;
        margin-top: 5rem; } } } }

@media only screen and (max-width: $bp-cell) {
  .Error404 {
    max-height: 100vh;
    min-height: 100vh;
    width: 100%;
    &__container {
      &__text {
        width: 100%;
        margin: 0 auto;
        &__subtitle {
          height: 10rem;
          font-size: 10rem; }
        &__title {
          font-size: 1.5rem!important; }
        &__description {
          margin-bottom: 3rem; }
        &__btn {
          padding: 15px 20px;
          font-size: 1rem;
          //margin: 10rem auto 0 auto
          width: 200px; } }
      &__img {
        width: 100%;
        margin: 0 auto;
        height: 500px;
        img {
          width: 100%;
          height: 100%;
          margin-top: 5rem; } } } } }
