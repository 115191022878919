@import "../../config";
.hostingMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: $bp-desktop) {
    display: none; } }
.Info {
  width: 100%;
  /** HEADLINE **/
  &__headline {
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: $bp-desktop) {
      display: flex;
      // grid-template-columns: 1fr 15%
      grid-gap: 10px; }
    &__data {
      display: flex;
      flex-flow: column;
      &__location {
        font-family: $font-family-base;
        font-weight: 500;
        line-height: 1rem;
        font-size: .75rem;
        letter-spacing: .1rem;
        text-transform: uppercase;
        color: $colorGris;
        margin-bottom: .25rem;
        @media (min-width: $bp-desktop) {
          margin-bottom: 1rem; } }
      &__title {
        display: flex;
        flex-flow: column;
        @media (min-width: $bp-desktop) {
          flex-flow: row;
          align-items: center;
          margin-bottom: 1.25rem!important; }
        &__name {
          display: block;
          line-height: 160%;
          margin: .75rem 0;
          color: $colorNaranja;
          font-weight: 500;
          white-space: nowrap;
          text-transform: capitalize;
          font-size: .75rem;
          @media (min-width: $bp-desktop) {
            display: none; } }
        &__text {
          @include stepTitle;
          font-weight: 700!important;
          font-size: 1.75rem!important;
          display: flex;
          vertical-align: center;
          margin-bottom: .75rem;
          @media (min-width: $bp-desktop) {
            width: 60%;
            font-size: 2rem!important; } }
        &__content {
          display: flex;
          @media (min-width: $bp-desktop) {
            flex-flow: row; } } }
      &__quantities,
      &__mainServices,
      &__reviews {
        @include stepDescription;
        line-height: 160%;
        color: $colorNegro;
        font-size: .85rem!important;
        @media (min-width: $bp-desktop) {
          font-size: 1rem!important; } } }
    &__avatar {
      display: none;
      @media (min-width: $bp-desktop) {
        display: flex; }
      &__container {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        &__img {
          height: 90px;
          width: 90px;
          img {
            border-radius: 50%!important;
            height: 90px;
            width: 90px;
            object-fit: cover; } }
        &__name {
          @include stepDescription;
          line-height: 160%;
          margin-top: .5rem!important;
          color: $colorNaranja;
          font-weight: 500;
          white-space: nowrap;
          text-transform: capitalize;
          font-size: .75rem;
          a {
            color: $colorNaranja;
            &:visited {
              color: $colorNaranja; } } } } } }
  /** TEXTOS IMPORTANTES **/
  &__important {
    display: flex;
    flex-flow: column;
    &__info {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 40px 1fr;
      margin-bottom: 1.5rem;
      &:last-child {
        margin-bottom: none; }
      &__icon,
      &__text {
        display: flex;
        flex-flow: column;
        justify-content: center;
        &__title,
        &__description {
          @include stepDescription;
          line-height: 160%; }
        &__title {
          font-weight: bold;
          color: $colorNegro; }
        &__description {
          color: #666; } }
      &__icon {
        align-items: center; } } }
  &__description {
    &__title,
    &__text {
      @include stepSubtitle;
      color: #333;
      line-height: 150%;
      font-size: .9375rem; }
    &__title {
      margin-bottom: 1rem;
      font-weight: bold; } }
  &__btnShare {
    @include btn-outline(1rem, 0.1rem, 1rem);
    font-size: 0.8rem;
    text-align: center;
    max-width: 100px;
    margin-left: 0px;
    max-height: 30px;
    @media (min-width: $bp-desktop) {
      margin-left: 20px; } } }
