@import "../config";

.MainContainer {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* Display */
  display: flex;
  flex-flow: column;
  /* Size */
  height: 100vh;
  * {
    box-sizing: border-box; }
  .btn {
    @include btn-primary;
    box-sizing: border-box;
    border: 1px solid #FFFFFF!important;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: .25rem 1rem!important;
    font-size: .75rem!important;
    &__text {
      text-align: center; } }
  &__right {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none; }
    &__titleContainer {
      display: flex;
      &__title {
        font-family: $font-family-titles;
        font-size: 2rem;
        padding: 3rem 3rem 1.25rem 3rem;
        margin: 0 auto; } }
    &__container {
      display: block;
      padding: 0 3rem; } }
  &__form {
    display: flex;
    flex-flow: column;
    &__group {
      @include inputContainerStyle;
      margin-bottom: 20px;
      width: 100%!important; }
    &__groupCheckbox {
      @include inputContainerStyle;
      margin-bottom: 20px;
      width: 100%!important;
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 25%!important;
      padding: 1rem; }
    &__label {
      @include labelStyle;
      color: #000; }
    &__input {
      @include inputStyle;
      color: #000;
      width: 100%!important;
      &::placeholder {
        color: $colorNegroNew!important; } }
    &__select {
      @include selectStyle;
      width: 100%!important;
      -moz-appearance: menulist!important;
      -webkit-appearance: menulist!important;
      appearence: menulist!important;
      color: #000; }
    &__btn {
      @include btn-primary;
      box-sizing: border-box;
      border: 1px solid #FFFFFF!important;
      margin-top: 15px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &__text {
        text-align: center; }
      &__outline {
        @include btn-outline(1rem, 1rem, 3rem);
        box-sizing: border-box;
        border: 1px solid $colorNaranja!important;
        color: $colorNaranja;
        margin-top: 15px;
        margin-right: 15px;
        text-align: center;
        align-items: center;
        justify-content: center;
        &__text {
          text-align: center; } } } }
  &__btn {
    @include btn-primary;
    box-sizing: border-box;
    border: 1px solid #FFFFFF!important;
    margin-top: 15px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &__text {
      text-align: center; } }
  .ActionBar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    &__input {
      @include inputStyle;
      &::placeholder {
        color: #000!important; } }
    &__btns {
      display: flex;
      justify-content: flex-end;
      width: 50%;
      &__btn {
        @include btn-primary;
        box-sizing: border-box;
        border: 1px solid #FFFFFF!important;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 15px;
        &__text {
          text-align: center; } } } }
  .UserData {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 40px;
    box-sizing: border-box;
    padding-bottom: 50px;
    &__section {
      display: flex;
      flex-flow: column;
      align-items: center;
      width: 100%;
      border-radius: 20px;
      padding: 1rem;
      border: 1px solid $colorNaranja;
      img {
        max-width: 80%; }
      &__title {
        display: flex;
        align-items: center;
        font-size: $font-family-base;
        margin-bottom: 20px;
        width: 100%;
        &__avatar {
          width: 10%;
          border-radius: 50%; }
        &__text {
          margin-left: 20px;
          font-weight: 500; } } }
    &__dataGroup {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: $font-family-base;
      border-bottom: 1px solid $colorNaranja;
      padding-bottom: 10px;
      margin-bottom: 20px;
      &__title {
        font-weight: 500; }
      &__content {
        display: flex;
        font-weight: 400;
        min-width: 50%;
        justify-content: flex-end; } }
    &__actions {
      grid-area: 1 / 1 / span 1 / span 2;
      display: flex;
      justify-content: space-between;
      width: 100%;
      &__btn {
        @include btn-primary;
        box-sizing: border-box;
        border: 1px solid #FFFFFF!important;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 15px;
        &__text {
          text-align: center; } } } } }
