@import "../../../assets/styles/_config.sass";
.successRegister {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__container {
        max-width: 800px;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 12px;
        border: 1px solid #ccc;
        padding: 54px;
        text-align: center;
        &__title {
            padding-bottom: 24px; } } }
