@import "../config";
.DateRangeBlack {
    .DateInput {
        margin: 0;
        padding: 0;
        background: #fff;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;
        margin-bottom: 50px;
        width: 50%;
        margin-right: 1rem; }

    .DateRangePicker {
        box-sizing: border-box;
        width: 100%!important; }

    .DateRangePickerInput {
        box-sizing: border-box;
        width: 100%!important;
        display: flex;
        justify-content: space-between;
        background-color: #2b2b2b;
        // border-bottom-color: $colorNaranja
        // border-bottom-style: solid;
 }        // border-bottom-width: 1px;

    .DateInput_input {
        box-sizing: border-box;
        font-size: .85rem;
        line-height: 100%!important;
        font-family: $font-family-base;
        background-color: #fff;
        color: #000;
        font-weight: 500;
        padding: 0!important;
        width: 100%;
        padding-bottom: .25rem!important;
        height: 50px;
        // border-bottom: 1px solid $colorNaranja !important;
        &::placeholder {
            color: #000; }
        @media (min-width: $bp-desktop) {
            width: 85%!important; }
        .DateRangePickerInput_arrow {
            display: none; } }

    .DateRangePickerInput_clearDates {
        margin: 0!important;
        padding: 2.5px 5px!important;
        color: #fff; }

    .DateRangePickerInput_arrow {
        display: none!important; }

    .CalendarDay__selected_span {
        background: #FF4E00cc;
        border: 1px double #ffffff;
        color: #fff;

        &:active {
            background: $colorNaranja !important;
            border: 1px double #fff; }
        &:active {
            background: $colorNaranja !important;
            border: 1px double #fff; } }

    .CalendarDay__selected {
        background: $colorNaranja;
        border: 1px double #fff;

        &:active {
            background: $colorNaranja !important;
            border: 1px double #fff; }
        &:hover {
            background: $colorNaranja !important;
            border: 1px double #fff; } }

    .CalendarDay__hovered_span {
        padding-bottom: .25rem;
        top: 41px !important;
        background: #FF4E00cc !important;
        border: 1px double #fff;
        color: #fff!important;

        &:active {
            background: $colorNaranja !important;
            border: 1px double #fff; }

        &:hover {
            background: $colorNaranja !important;
            border: 1px double #fff;
            col {} } }
    @media (max-width: $bp-tablet) {
        .DayPickerNavigation_button__verticalDefault {
            width: 45% !important; }

        .DayPickerNavigation__verticalDefault {
            bottom: 20px !important;
            margin-left: 4px !important; }

        .DayPicker_transitionContainer__vertical {
            height: 360px !important; }

        .DateRangePicker_picker {
            height: 400px !important; } } }
