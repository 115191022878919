@import "../_config";

.BankPromotionModal {
    width: 100%;
    box-sizing: border-box;
    max-height: 50vh;
    overflow-y: scroll;
    &__header {
        border: none!important; }
    &__section {
        padding: 20px 20px 10px 20px!important;
        text-align: center;
        @media (min-width: $bp-desktop) {
            padding: 20px 20px 10px 20px!important; }
        &__icon {
            font-size: 50px;
            color: $colorNaranja; } }
    &__buttons {
        display: flex;
        flex-flow: row;
        align-content: center;
        justify-content: center; } }
