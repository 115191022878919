@import "../config";

.Working {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../../static/working.jpg') no-repeat center center;
    background-color: $colorNaranja;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    box-sizing: border-box;
    max-width: 100vw!important;
    min-width: 100vw!important;
    width: 100%!important;
    max-height: 100vh;
    min-height: 100vh;
    height: 100%!important;
    box-sizing: border-box;
    position: relative;
    @media only screen and (min-width: $bp-desktop) {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../../static/working.jpg') no-repeat center center;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-color: $colorNaranja;
        width: 100%!important;
        max-width: 100%!important;
        min-width: 100%!important;
        min-height: 100vh;
        margin: 0px !important; }
    &__textContainer {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        display: flex;
        flex-flow: column;
        align-items: center;
        margin: 0 auto;
        width: 90%;
        text-align: center;
        @media only screen and (min-width: $bp-desktop) {
            width: 50%; }
        &__title {
            font-size: 3rem;
            font-family: $font-family-titles;
            color: $colorBlanco;
            @media only screen and (min-width: $bp-desktop) {
                font-size: 5rem; } }
        &__description {
            margin-top: 2rem;
            font-size: 1.5rem;
            font-family: $font-family-titles;
            color: $colorAmarilloNew;
            @media only screen and (min-width: $bp-desktop) {
                font-size: 2rem; } } } }
