.ProfileValuationContainer {
  display: block;
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    gap: .75rem;
    &__icon,
    &__text {
      font-size: 1.375rem;
      font-weight: 500; }
    &__icon {
      font-size: 1rem; } }
  &__tabs {
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid #DDD;
    margin-bottom: 1rem;
    &__tab {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      border-radius: .5rem;
      border: none;
      background: transparent;
      padding: 10px;
      margin-top: 6px;
      margin-bottom: 6px;
      margin-right: 6px;
      -webkit-transition: box-shadow 0.2s ease, -webkit-transform 0.1s ease, transform 0.1s ease;
      -moz-transition: box-shadow 0.2s ease, transform 0.1s ease;
      transition: box-shadow 0.2s ease, -ms-transform 0.1s ease, -webkit-transform 0.1s ease, transform 0.1s ease;
      position: relative;
      outline: none;
      color: #717171;
      cursor: pointer;
      &.select {
        color: #000;
        &::after {
          content: "";
          position: absolute;
          background-color: #000;
          bottom: -6px;
          left: 0px;
          width: calc(100%);
          height: 2px; } }
      &:hover {
        background: #F7F7F7; } } }
  &__reviews {
    display: flex;
    flex-flow: column; } }
