@import "../../config";
.ProgressBar {
    width: 100%;
    border-bottom: 1px solid #C4C4C4;
    padding: 25px 0;
    margin-bottom: 40px;
    &__container {
      &__titles {
        display: grid;
        align-items: center;
        grid-gap: 10px;
        @media (min-width: $bp-desktop) {
          grid-template-columns: 350px 1fr; }
        &-description {
          @include stepDescription;
          color: $colorBlanco;
          font-size: 1rem;
          margin-bottom: .5rem; }
        &-title {
          font-family: $font-family-base;
          color: $colorNaranja;
          font-size: 1.2rem!important;
          font-weight: 700;
          margin: 0;
          display: flex;
          align-items: center;
          @media (min-width: $bp-desktop) {
            font-size: 1.5rem!important; } } }
      &__btn-simple {
        color: $colorNaranja;
        font-size: 1rem;
        padding-bottom: .25em; } }
    &__bars {
      display: grid;
      grid-template-columns: repeat(14, 1fr);
      border-radius: 15px;
      border-left: #38D1DA solid 5px;
      width: 90%;
      position: relative; }
    &__bar {
      display: flex;
      background: #EBEBEB;
      height: 12px;
      width: 100%;
      &__progress {
        background: #38D1DA;
        height: 12px;
        width: 1%; }
      &__progress:before {
        content: '';
        background: #EBEBEB;
        position: absolute;
        right: -9px;
        top: 0;
        width: 10px;
        height: 12px;
        border-radius: 0 15px 15px 0; } } }
