@import "../../config";
.CovidResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; } }
.Covid {
  max-width: 1200px;
  margin: 0 auto;
  height: 75vh;
  position: relative;
  padding: 20px;
  @media (min-width: $bp-desktop) {
    padding: 20px 0; }
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding-bottom: 20px;
    @media (min-width: $bp-desktop) {
      grid-template-columns: 1fr 1fr; }
    &-left,
    &-right, {
      display: flex;
      flex-flow: column;
      grid-row-gap: 5px; }
    &__title {
      padding-top: 30px;
      @include stepTitle;
      font-weight: 700;
      font-size: 1.5rem!important;
      color: #000;
      margin: 0!important;
      @media (min-width: $bp-desktop) {
        padding-top: 0; } }
    &__description,
    &__descriptionSecondary {
      @include stepDescription;
      width: 98%;
      color: #000;
      font-weight: 400;
      font-size: 1.2rem;
      @media (min-width: $bp-desktop) {
        width: 65%;
        margin-bottom: 2rem!important; } }
    &__descriptionSecondary {
      width: 80%; } } }
