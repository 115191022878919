.ProfileUserHousing {
  display: flex;
  flex-flow: column;
  width: 100%;
  &__title {
    font-size: 1.375rem;
    margin-bottom: 1.5rem;
    font-weight: 500; }
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    @media (min-width: 1024px) {
      grid-template-columns: 50% 50%; } } }
