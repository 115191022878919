@import "../config";

.BackOfficeToggle {
    background: none;
    text-transform: uppercase;
    text-align: left;
    border-radius: 10px 10px 0 0;
    border: 1px solid transparent!important;
    padding: .5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-family: $font-family-base;
      font-weight: 700;
      font-size: 15px;
      color: #000!important;
      white-space: nowrap; }
    .switch {
      margin-left: 10px;
      position: relative;
      display: inline-block;
      min-width: 40px;
      height: 24px;
      input {
        opacity: 0;
        width: 0;
        height: 0; }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        -webkit-transition: .4s;
        border-radius: 24px;
        border: 1px solid $colorNaranja;
        transition: .4s;
        &:before {
          position: absolute;
          content: "";
          height: 16px;
          width: 16px;
          left: 4px;
          bottom: 3px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 50%; } } }

    input:not(:checked) + .slider {
        &:before {
            background-color: $colorNaranja; } }

    input:checked + .slider {
        background-color: $colorNaranja;
        &:before {
            -webkit-transform: translateX(16px);
            -ms-transform: translateX(16px);
            transform: translateX(16px); } } }
