@import "../config";

.WeDontWannaGetSued {
  padding: 24px 0;
  background: #000;
  &__container {
    max-width: 80%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    margin: 0 auto;
    @media only screen and (min-width: $bp-desktop) {
      flex-flow: row;
      max-width: 1200px; }
    &__textContainer {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
      @media only screen and (min-width: $bp-desktop) {
        margin-bottom: 0px;
        width: 60%;
        margin-right: 10%; }
      &__title {
        @include stepTitle;
        font-size: 1rem!important;
        font-weight: 400!important;
        color: #fff;
        text-decoration: underline!important;
        text-align: center;
        &:visited {
          color: #fff; } } } } }
