@import "../config";

.toggleButtons {
  background: none;
  text-align: left;
  border-radius: 10px 10px 0 0;
  border: 1px solid transparent!important;
  padding: .5rem 0;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 1.2rem;
  .title {
    font-family: $font-family-base;
    font-weight: 500;
    font-size: 1rem;
    color: $colorNegro;
    white-space: nowrap;
    margin: 0;
    width: 200px;
    @media only screen and (max-width: $bp-cell) {
      font-weight: 500;
      font-size: 1rem;
      line-height: 18px; } }
  .switch {
    position: relative;
    display: inline-block;
    min-width: 40px;
    height: 24px;
    input {
      opacity: 0;
      width: 0;
      height: 0; }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #DDDDDD;
      -webkit-transition: .4s;
      border-radius: 24px;
      transition: .4s;
      &:before {
        position: absolute;
        content: "";
        height: 24px;
        width: 23px;
        left: 1px;
        bottom: 0;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%; } } }

  input:not(:checked) + .slider {
    &:before {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.08); } }
  input:checked + .slider {
    background-color: $colorNaranja;
    &:before {
      border: 1px solid rgba(0, 0, 0, 0.08);
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px); } } }
