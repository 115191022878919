.displayNone {
    display: none; }
.globalModal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    &__container {
        position: relative;
        width: 90%;
        height: 90%;
        max-width: 700px;
        max-height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 54px 0;
        border-radius: 24px;
        &__close {
            position: absolute;
            right: 24px;
            top: 24px;
            cursor: pointer;
            font-size: 20px;
            padding: 0;
            margin: 0;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center; } } }
