@import "../config";
@import "../button_orange";

.user {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    position: relative;
    width: 100%;
    @media (min-width: $bp-desktop) {
        grid-template-columns: 350px 500px;
        margin: 2rem 0 2rem 2rem; }
    &__linkPass {
        position: absolute;
        bottom: 600px; }
    &__imgContainer {
        display: flex;
        flex-flow: column;
        align-items: center;
        gap: 1rem;
        width: 100%;
        height: 100%;
        padding: 1rem;
        @media (max-width: 375px) {
            padding: 0;
            display: grid; }
        @media (min-width: $bp-desktop) {
            padding: 0; }
        &__password {
            display: flex;
            gap: 10px;
            align-items: center;
            margin: 0 auto;
            background: none;
            font-size: 1rem;
            font-weight: 700;
            z-index: 10;
            color: #000;
            text-decoration: none!important;
            @media (max-width: $bp-cell) {
                margin: 1rem auto; } }
        &__profile {
            position: relative;
            width: 100%;
            min-width: 100%;
            min-height: 200px;
            background: $colorNaranja;
            border-radius: 20px;
            &__avatar {
                width: 100%;
                border-radius: 20px;
                object-fit: cover;
                height: 300px; }
            &__uploadFile {
                position: absolute;
                bottom: 0;
                left: 0;
                background: transparent!important; }
            &__input {
                display: none; }
            &__change {
                position: absolute;
                bottom: 1rem;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                &__label {
                    cursor: pointer;
                    z-index: 10;
                    color: #fff;
                    font-size: 1.2rem;
                    font-weight: 700;
                    text-shadow: rgb(0, 0, 0 / 0.4) 2px 2px 10px; }
                &__icon {
                    path {
                        stroke: #fff; } } } }

        &__evaluation {
            display: flex;
            align-items: center;
            justify-content: space-around;
            background: #96F9FF;
            width: 100%;
            height: 70px;
            border-radius: 20px;
            &__text {
                font-family: $font-family-titles!important;
                font-weight: 800;
                font-size: 1.5rem!important;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 4px; }
            &__img {
                background: #000;
                border: 8px solid #000;
                border-radius: 100%;
                width: 40px;
                height: 40px; } } }
    &__iconsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 150px;
        position: absolute;
        top: .4rem;
        right: 1rem;
        @media (min-width: $bp-desktop) {
            top: -.8rem;
            right: -120px; }
        &__title {
            font-weight: 500;
            font-size: 1rem;
            color: #000!important; }
        &__icons {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $colorNaranja;
            border-radius: 100%;
            height: 50px;
            width: 50px;
            &__user {
                width: 20px;
                // g
                //     circle,
                //     path
                //         color: #fff
                //         -webkit-text-stroke-color: #fff
 }                //         -webkit-text-fill-color: #fff
            &__check {
                width: 10px; } } }
    &__componentsSmall,
    &__componentsBig {
        width: 100%;
        @media (min-width: $bp-desktop) {
            padding-bottom: 2rem;
            width: 500px; } }
    &__button,
    &__buttonBack {
        box-shadow: 0px 4px 6px rgba(43, 43, 43, 0.3);
        padding: 1rem 3rem!important;
        box-sizing: border-box;
        text-align: center;
        font-size: 1rem!important;
        border-radius: 100px!important;
        font-family: "Roboto",sans-serif!important;
        font-weight: 500!important;
        color: $colorNaranja!important;
        background: #fff!important;
        margin-top: 15px;
        margin-bottom: 15px;
        border: 1px solid $colorNaranja!important;
        width: 150px;
        margin-left: 0;
        @media (min-width: 376px) {
            margin-left: 1rem; }
        @media (min-width: $bp-desktop) {
            margin-left: 0;
            bottom: .8rem;
            right: 280px; } } }
