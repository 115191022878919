@import "../config";

.Radio {
    display: flex;
    margin: 0!important;
    color: $colorNegroNew;
    padding: 25px;
    &:hover,
    &.active {
        background: $colorAmarilloNew;
        color: $colorNaranja!important; }
    &__container {
        display: grid!important;
        flex-flow: column!important;
        justify-content: center!important;
        align-items: center!important;
        justify-content: flex-start!important;
        position: relative!important;
        &__title {
            @include stepSubtitle;
            font-size: 1.125rem!important;
            margin: .25rem 0!important;
            cursor: pointer!important;
            text-align: left!important;
            align-self: center;
            color: currentColor!important; }
        &__description {
            @include stepDescription;
            font-size: 1rem!important; }
        &__label {
            display: grid;
            grid-template-columns: auto 3fr;
            cursor: pointer; }
        &__text {
            display: flex!important;
            flex-flow: column!important;
            padding-left: 1.75rem!important; }
        &__circle {
            height: 1.25rem!important;
            width: 1.25rem!important;
            border: 1px solid #C4C4C4;
            border-radius: 50%!important;
            margin-top: .25rem!important;
            position: absolute; }
        &__circle::after {
            content: "";
            height: .75rem!important;
            width: .75rem!important;
            background: $colorNaranja!important;
            position: absolute!important;
            border-radius: 50%!important;
            top: 50%!important;
            left: 50%!important;
            transform: translate(-50%, -50%) scale(0)!important;
            transition: 200ms ease-in-out 0s!important; } } }
