@import "../../config";
.legalResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; } }
.Legal {
  padding: 20px;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  height: 100vh;
  @media (min-width: $bp-desktop) {
    padding: 20px 0 0 0; }
  @media (min-width: $bp-big-display) {
    height: 70vh; }
  &__title {
    @include stepTitle;
    color: #000;
    font-size: 1.5rem!important;
    margin: 0; }
  &__subtitle {
    @include stepSubtitle;
    margin-bottom: 1em;
    color: #000;
    line-height: 120%;
    font-size: 1.12rem;
    font-weight: 400!important; }
  &__description {
    @include stepDescription;
    width: 100%;
    color: #000;
    font-size: 1.12rem;
    font-weight: 400!important; }
  &__buttons {
    margin-top: 30px;
    @media (min-width: $bp-desktop) {
      margin: 0; } }
  a {
    color: $colorNaranja;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8rem;
    font-weight: 500;
    @media (min-width: $bp-desktop) {
      justify-content: start; }
    img {
      width: 30px;
      height: 30px;
      margin-right: 5px; }
    &:hover {
      color: $colorNaranja; }
    &:visited {
      color: $colorNaranja; } } }
