@import "../config";

.Footer {
  width: 100%;
  background: #fff;
  color: #000;
  padding: 4vh 0 100px 0;
  @media only screen and (min-width: $bp-desktop) {
    padding: 10vh 0 5vh 0; }
  &__container {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    max-width: 90vw;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    @media only screen and (min-width: $bp-desktop) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      max-width: 1200px; } }
  &__logo {
    // grid-area: 1 / 1 / span 1 / span 2
    margin-bottom: 3rem;
    // display: none
    @media only screen and (min-width: $bp-desktop) {
      grid-area: auto;
      display: block; }
    img {
      max-width: 150px;
      margin: 0;
      @media only screen and (min-width: $bp-desktop) {
        margin-top: -10px; } } }
  &__linkList {
    font-family: $font-family-base;
    font-weight: 400;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    a, p {
      color: #000; }
    a:visited {
      color: inherit; } }
  &__socialMedia {
    max-width: 100px;
    a {
      width: 40px;
      height: 40px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #000;
      border-radius: 100%; }
    &__icon {
      width: 30px;
      height: 30px; } } }

.Search ~ .Footer {
  display: none;
  @media only screen and (min-width: $bp-desktop) {
    display: block; } }
