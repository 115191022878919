@import "../../config";

.Checkbox {
  display: flex;
  align-items: center;
  width: 100%;
  &__CheckboxContainer {
    display: flex;
    align-items: center;
    position: relative;
    &__checkbox {
      display: none; }
    &__square {
      height: 1.300rem!important;
      width: 1.375rem!important;
      background: #fff!important;
      border: 1px solid #000;
      position: absolute!important;
      top: .1rem!important;
      left: 0!important;
      border-radius: 3px; }
    &__square::after {
      content: url('../../../../assets/static/Icons/check.svg');
      height: 1.375rem!important;
      width: 1.375rem!important;
      color: #fff!important;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute!important;
      top: 50%!important;
      left: 50%!important;
      transform: translate(-50%, -50%) scale(0);
      transition: 200ms ease-in-out 0s;
      background-color: $colorNaranja;
      border-radius: 3px;
      border: 1px solid $colorNaranja!important; }
    &__checkbox:checked + &__title > &__square::after {
      transform: translate(-50%, -50%) scale(1); }
    &__title {
      @include stepSubtitle;
      margin-bottom: 1rem;
      font-size: 1rem!important;
      cursor: pointer!important;
      width: 100%!important;
      margin-left: 2rem!important;
      line-height: calc(1.375rem + 2px);
      color: #000;
      font-weight: 500; } } }

