@import "../config";

.userInfo {
    width: 100%;
    @media (min-width: 376px) {
        padding: 0 1rem; }
    @media (min-width: $bp-desktop) {
        padding: 0;
        width: 500px; }
    &__modal {
        display: block;
        position: absolute;
        left: -282px; }
    &__modalNone {
        display: none; }
    &__container {
        width: 100%;
        display: flex;
        flex-flow: column;
        margin-top: 2rem;
        @media (min-width: $bp-desktop) {
            width: 350px;
            margin-top: 0; }
        &__header {
            height: 120px;
            &__init,
            &__title {
                font-family: $font-family-titles;
                font-size: 1.2rem;
                margin: 0; }
            &__init {
                color: $colorNaranja; }
            &__title {
                font-size: 4rem;
                line-height: 3rem; } }
        &__info {
            display: flex;
            flex-flow: column;
            gap: 1.5rem;
            margin-top: 1rem;
            &__contentTextarea {
                margin-top: 1.2rem;
                height: 52px;
                position: relative;
                border-bottom: 1px solid $colorNaranja;
                &__textarea {
                    resize: none;
                    overflow: hidden;
                    font-weight: 500;
                    font-size: 1rem;
                    border: none;
                    color: #000;
                    width: 100%; }
                &__title {
                    position: absolute;
                    bottom: 3rem;
                    color: $colorNaranja;
                    font-size: .8rem;
                    font-weight: 400; }
                &__limit {
                    position: absolute;
                    bottom: -25px;
                    right: 0; } }
            &__error {
                height: 20px;
                margin-top: -20px;
                width: 100%;
                margin-bottom: 20px; }
            &__content {
                margin-top: 1.2rem;
                height: 30px;
                position: relative;
                border-bottom: 1px solid $colorNaranja;
                &__error {
                    height: 20px;
                    margin-top: -20px;
                    margin-bottom: 20px;
                    width: 100%; }
                i {
                    float: right;
                    cursor: pointer; }
                &__title {
                    position: absolute;
                    bottom: 1.8rem;
                    color: $colorNaranja;
                    font-size: .8rem;
                    font-weight: 400; }
                &__textarea {
                    margin-bottom: 2rem; }
                &__description {
                    font-weight: 500;
                    font-size: 1rem;
                    border: none;
                    color: #000;
                    width: 90%; }
                &__error {
                    height: 20px; }
                &__description[type="date"] {
                    width: 100%;
                    background: none; }
                &__icon {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    animation: boing .7s alternate infinite ease-out; }
                @keyframes boing {
                    0% {
                        top: 0; }
                    100% {
                        top: -10px; } } }
            &__btn {
                &__savePass {
                    width: 100%;
                    background: $colorNaranja;
                    color: #fff;
                    border-radius: 100px;
                    padding: 1rem 3rem!important;
                    font-size: 1rem;
                    font-weight: 500; } } } }
    &__password {
        display: block; }
    &__passwordNone {
        display: none; }
    &__btn {
        float: right;
        width: 150px;
        border: 1px solid $colorNaranja!important;
        box-shadow: 0px 4px 6px rgba(43, 43, 43, 0.3);
        padding: 1rem 3rem!important;
        box-sizing: border-box;
        text-align: center;
        border-radius: 100px!important;
        color: #fff!important;
        background: $colorNaranja!important;
        font-family: "Roboto",sans-serif!important;
        font-weight: 500!important;
        margin: 15px .5rem 15px 0;
        font-size: 1rem!important;
        @media (min-width: 376px) {
            margin-right: 1rem; }
        @media (min-width: $bp-desktop) {
            margin-right: 150px; }
        &__text {
            font-family: "Roboto",sans-serif;
            font-weight: 500;
            font-size: 1rem; } } }

.changePass {
    margin-top: 1.5rem;
    @media (min-width: $bp-desktop) {
        margin: 0; } }
