@import "../config";

.Travel {
  width: 100%!important;
  border-radius: 20px!important;
  background: #ffffff;
  box-shadow: 0 0 5px 0 #00000088;
  &__imgcontainer {
    width: 100%;
    height: 175px;
    z-index: 3;
    &__img {
      border-radius: 20px 20px 0 0!important;
      height: 100%;
      width: 100%;
      object-fit: cover; } }
  &__datacontainer {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    padding-bottom: 0!important;
    &__dates,
    &__persons,
    &__address {
      font-size: .75rem;
      color: #666;
      margin-bottom: .25rem; }
    &__title {
      font-size: 1.5rem;
      font-weight: 700;
      color: #333;
      margin-bottom: .25rem; }
    &__buttonList {
      box-sizing: border-box;
      border-top: 1px solid #E3E3E3;
      margin-top: .5rem;
      padding: .75rem .25rem 1rem .25rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
      width: 100%;
      @media (min-width: $bp-desktop) {
        grid-template-columns: 1fr 1fr; }
      &__btn {
        box-sizing: border-box;
        @include btn-primary;
        font-size: .75rem!important;
        padding: .5rem!important; } } } }
