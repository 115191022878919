@import "../../config";

.Numbers {
    display: flex;
    justify-content: flex-end;
    flex-flow: row-reverse;
    &__title {
      @include stepSubtitle;
      font-size: 18px!important;
      width: 100px;
      color: #000;
      padding: .6rem 0 0 0;
      font-weight: 400!important; }
    &__container {
      display: flex;
      align-items: center;
      padding-bottom: 10px; }
    &__btn {
      display: flex;
      align-items: center!important;
      justify-content: center!important;
      height: 2.5rem!important;
      width: 2.5rem!important;
      font-size: 1.2rem!important;
      vertical-align: middle;
      text-align: center;
      background: #000;
      border-radius: 50%!important;
      color: #fff;
      margin-right: .6125rem!important;
      transition: 50ms ease-in-out 0s;
      &:active {
        transform: scale(.9);
        transition: 50ms ease-in-out 0s; } }
    &__text {
      @include stepSubtitle;
      font-size: 1.125rem!important;
      color: #000;
      margin-right: .6125rem!important;
      width: 25px!important;
      text-align: center;
      padding: 5px 0 0 0;
      font-weight: 700; } }
