@import "./src/assets/styles/_config.sass";

.layoutMessages,
.layoutMessagesHost {
    width: 100%;
    height: 100%;
    @media (max-width: 600px) {
        // height: calc( 100vh - 60px )
        height: 100%; }
    &__containerSide {
        box-sizing: border-box;
        max-width: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 250px 1fr;
        height: calc( 100vh - 116px );
        &__sideBar {
            width: 100%;
            background: #2B2B2B;
            padding-top: 40px; } }
    &__loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__title {
            font-size: 2rem; } }
    &__container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        @media (max-width: 600px) {
            height: 100%; }
        &__empty {
            text-align: center;
            width: 100%;
            margin-top: 200px;
            font-family: $font-family-titles;
            font-size: 2rem;
            color: $colorGrisNew; }
        &__section,
        &__sectionDetail {
            width: 100%;
            height: 100%;
            opacity: 1;
            transition: width .3s ease-in-out;

            &__chats {
                overflow-y: scroll;
                height: calc( 100vh - 176px);
                @media (max-width: 600px) {
                    height: 100%;
                    padding: 80px 0;
                    padding-top: 170px; }
                &::-webkit-scrollbar,
                &::-webkit-scrollbar-thumb,
                &::-webkit-scrollbar-track {
                    width: 8px;
                    border: none;
                    background: transparent; }
                &::-webkit-scrollbar-button,
                &::-webkit-scrollbar-track-piece,
                &::-webkit-scrollbar-corner,
                &::-webkit-resizer {
                    display: none; }
                &::-webkit-scrollbar-thumb {
                    border-radius: 6px;
                    background-color: #bdbdbd; }
                &::-webkit-scrollbar-track {
                    background-image: url("../../assets/static/track.png");
                    background-color: transparent;
                    background-repeat: repeat-y;
                    background-size: contain; } }
            &__header,
            &__headerHost {
                border-bottom: 1px solid #ccc;
                padding: 10px;
                display: flex;
                align-items: center;
                gap: 20px;
                height: 60px;
                cursor: pointer;
                @media (max-width: 600px) {
                    padding: 5px;
                    padding-left: 50px;
                    position: fixed;
                    width: 100%;
                    z-index: 20;
                    top: 70px;
                    background: #fff; }
                &__icon {
                    @media (max-width: 600px) {
                        display: none; } } }
            &__headerHost {
                @media (max-width: 600px) {
                    padding-left: 5px!important; } }
            &__cardDetail {
                height: calc( 100vh - 176px );
                overflow-y: scroll;
                &::-webkit-scrollbar,
                &::-webkit-scrollbar-thumb,
                &::-webkit-scrollbar-track {
                    width: 8px;
                    border: none;
                    background: transparent; }
                &::-webkit-scrollbar-button,
                &::-webkit-scrollbar-track-piece,
                &::-webkit-scrollbar-corner,
                &::-webkit-resizer {
                    display: none; }
                &::-webkit-scrollbar-thumb {
                    border-radius: 6px;
                    background-color: #bdbdbd; }
                &::-webkit-scrollbar-track {
                    background-image: url("../../assets/static/track.png");
                    background-color: transparent;
                    background-repeat: repeat-y;
                    background-size: contain; }
                @media (max-width: 600px) {
                    height: calc( 100vh - 60px);
                    padding: 80px 0;
                    padding-top: 170px; } } }
        &__section:nth-child(1) {
            border-right: 1px solid #ccc;
            min-width: 300px;
            width: 40%;
            max-width: 400px;
            @media (max-width: 600px) {
                min-width: 100%;
                width: 100%;
                border-right: none;
                height: 100%;
 } }                // height: calc( 100vh - 50px )
        &__section:nth-child(2) {
            width: 100%;
            min-width: 400px;
            height: calc(100vh - 116px);
            @media (max-width: 600px) {
                display: none; } }
        &__section:nth-child(3) {
            max-width: 400px;
            border-left: 1px solid #ccc;
            animation: sectionNone .3s ease-out forwards;
            @media (max-width: 600px) {
                display: none; } }
        &__sectionDetail {
            border-left: 1px solid #ccc;
            width: 100%;
            max-width: 400px;
            // animation: sectionActive .1s ease-out forwards
            @media (max-width: 600px) {
                display: none; } } } }

.layoutMessagesHost {
    height: calc( 100vh - 50px ); }
// @keyframes sectionActive
//     0%
//         width: 100%
//     50%
//         width: 50%
//     100%
//         width: 0%
//         display: none

@keyframes sectionNone {
    100% {
        width: 100%;
        display: none; }

    50% {
        width: 50%; }
    0% {
        width: 0%;
        display: none; } }
