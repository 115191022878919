.userAvatar {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 50%; }
.userName {
    background: #FF4E00;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover; }
