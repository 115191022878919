@import "../../config";

.Booking {
  /* Box sizing */
  max-width: -webkit-fill-available;
  box-sizing: border-box;
  /* Display */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  /* Style */
  padding: 1rem 0!important;
  &__disabled {
    padding: 1rem 3rem;
    font-size: 1rem;
    border-radius: 100px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #fff;
    background: #666;
    width: 100%;
    font-size: 1.125rem;
    line-height: 120%;
    max-width: inherit;
    text-align: center; }
  &__container {
    /* Box Sizing */
    box-sizing: border-box;
    max-width: 100%;
    width: 100%!important;
    margin-bottom: 1rem!important;
    padding: 1rem .5rem!important;
    /* Display */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: .5rem;
    /* Style */
    border-radius: 100px;
    border: 1px solid #E2E2E2; }
  &__dates,
  &__people {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%!important; }
  &__dates {
    padding-left: 1rem!important; }
  &__people {
    padding-right: 1rem!important;
    .input_wrapper {
      display: flex;
      align-items: center;
      color: $colorNaranja;
      font-weight: 500;
      font-size: .85rem;
      position: relative;
      width: 100%!important;
      border-bottom: 1px solid $colorNaranja; }
    .input_wrapperNew {
      display: flex;
      align-items: center;
      color: #000;
      font-weight: 500;
      font-size: 24px!important;
      position: relative;
      width: 100%!important; }
    .input_wrapperNew > input {
      font-size: 24px!important;
      -webkit-border-bottom-right-radius: 0;
      -webkit-border-bottom-left-radius: 0; }
    .person_select {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      cursor: pointer;
      width: 100%!important;
      margin-left: 20px;
      padding-right: 20px;
      @media (min-width: $bp-desktop) {
        justify-content: center;
        min-height: none!important;
        max-width: 200px!important;
        margin: 0!important;
        padding: 0!important;
        height: auto; } }
    .personas_wrapper {
      display: flex;
      flex-direction: column;
      .close {
        display: none;
        width: 25px;
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer; }
      hr {
        margin-top: 15px;
        margin-bottom: 15px; }
      .opciones_personas {
        display: flex;
        flex-wrap: no-wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .options {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between; }
        .contador {
          font-size: 14px; } }
      .round_boton {
        color: #fff;
        margin-left: 10px;
        margin-right: 10px;
        background: $colorNaranja;
        border-radius: 50%;
        font-size: 25px;
        min-width: 30px;
        border-width: 0px;
        font-weight: bold; } }
    .lista_personas {
      position: absolute;
      top: 100%;
      left: 0%!important;
      width: 200px;
      background: #fff;
      padding: 20px;
      display: none;
      position: absolute;
      box-shadow: 0px 1px 6px -3px #111;
      z-index: 99 !important;
      width: 70vw!important;
      @media (min-width: $bp-desktop) {
        top: 110%;
        width: 200px!important;
        .close {
          display: block; } } } }
  .DateInput_input {
    margin: 0;
    padding: 0; }
  .DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__showClearDates.DateRangePickerInput__showClearDates_2 {
    padding: 0;
    display: flex;
    justify-content: space-between; } }
