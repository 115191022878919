@import "../config";
.favoritesContainer {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    min-height: 80vh;
    display: grid;
    grid-template-columns: 250px 1fr;
    @media (max-width: $bp-cell) {
        grid-template-columns: 1fr;
        padding-top: 100px; }
    &__sideBar {
        width: 100%;
        background: #2B2B2B;
        padding-top: 40px;
        @media (max-width: $bp-cell) {
          display: none; } } }
.FavoritesListing {
  display: flex;
  flex-flow: column;
  margin: 1.25rem auto;
  max-width: 95%;
  width: 100%;
  min-height: 50vh;
  @media only screen and (min-width: $bp-desktop) {
    margin: 2.25rem auto;
    justify-content: flex-start;
    flex-wrap: wrap; }
  &__title {
    font-size: 2rem;
    color: #000;
    margin-bottom: 1.5rem;
    font-family: $font-family-titles;
    @media only screen and (min-width: $bp-desktop) {
      margin-bottom: 2.25rem;
      font-size: 3.25rem; } }
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 50px;
    @media only screen and (min-width: $bp-desktop) {
      grid-template-columns: repeat(4, 15%); } }
  &__img {
    width: 50%; } }

