@import "../config";

$paginationButtonSize: 2rem;

/**
 Old searc
 **/

.Search {
  width: 100%!important;
  display: grid;
  grid-template-columns: 1fr;
  @media only screen and (max-width: $bp-cell) {
    padding-top: 70px; }
  &__buttonViewMap {
    position: fixed;
    bottom: 83px;
    left: 50%;
    transform: translate(-50%, 0px);
    z-index: 11;
    @media only screen and (min-width: $bp-desktop) {
      display: none; } }
  &__navigation {
    width: 100%;
    max-width: 94%;
    margin: 0 auto;
    padding-top: 1rem;
    @media only screen and (min-width: $bp-desktop) {
      margin: 0 0 0 10%;
      max-width: 90%; }

    &__header {
      max-width: 90%;
      margin-left: 20px;
      display: flex;
      align-items: center;
      @media only screen and (max-width: $bp-cell) {
        padding-top: 25px; }
      @media only screen and (min-width: $bp-desktop) {
        margin: 0 0 0 10%;
        max-width: 90%;
        margin: 0 0; }
      &__title {
        width: 100%;
        font-family: $font-family-titles;
        color: $colorNegro;
        font-weight: 800;
        line-height: 52px;
        line-height: 110%;
        margin-bottom: 1.25rem;
        font-size: 40px;
        margin-top: 15px;
        @media only screen and (min-width: $bp-desktop) {
          font-size: 2.5rem; }
        img {
          //color: #fff!important
          //width: 2.7rem
          //height: 2.7rem
          //border-radius: 100%
          //background: $colorNaranja
 } } }          //padding: 0.3rem
    &__filters {
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      padding: .5rem 0;
      margin-bottom: 3.75rem;
      border-top: 1px solid #DDD;
      border-bottom: 1px solid #DDD;
      grid-template-columns: 1fr;
      grid-gap: 15px;
      @media only screen and (min-width: $bp-desktop) {
        // display: grid
        margin-bottom: .75rem;
        width: 100%;
        // grid-template-columns: repeat(7, fit-content(300px))
        grid-gap: 0px;
        margin-right: auto; }
      &__filter {
        box-sizing: border-box;
        position: relative;
        font-family: $font-family-base;
        font-weight: 700;
        font-size: 15px;
        line-height: 110%;
        color: $colorNegro;
        background: none;
        text-align: center;
        text-transform: uppercase;
        border-radius: 10px 10px 0 0;
        white-space: nowrap;
        text-align: left;
        width: 100%!important;
        @media only screen and (min-width: $bp-desktop) {
          margin: 0; }
        &__container {
          position: relative; }
        &__options {
          box-sizing: border-box!important;
          position: absolute;
          top: 100%!important;
          left: 0!important;
          right: 0!important;
          background: #ffffff!important;
          border-style: solid;
          border-width: 1px;
          border-color: $colorNegro;
          border-radius: 0 0 10px 10px;
          z-index: 30;
          ul {
            list-style-type: none;
            li {
              box-sizing: border-box!important;
              padding: 10px;
              cursor: pointer; } } } } } }
  &__container {
    display: grid;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    max-width: 95%;
    margin: 0 auto;
    height: calc( 100vh - 104px );
    grid-template-columns: 1fr;
    @media only screen and (min-width: $bp-desktop) {
      max-width: 100%;
      grid-template-columns: 10% 1fr 40%;
      grid-gap: .5rem; }
    &__subtitle {
      @include mutedOnLanding;
      margin-bottom: .5rem; }
    &__blankspace {
      display: none;
      @media only screen and (min-width: $bp-desktop) {
        display: block; } }
    &__list {
      width: 100%;
      margin-left: auto;
      @media only screen and (min-width: $bp-desktop) {
        padding-bottom: 0;
        margin-bottom: 1rem;
        transform: translateX(-.5rem);
        overflow-y: scroll;
        padding-bottom: 200px;

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-track {
          width: 8px;
          border: none;
          background: transparent; }
        &::-webkit-scrollbar-button,
        &::-webkit-scrollbar-track-piece,
        &::-webkit-scrollbar-corner,
        &::-webkit-resizer {
          display: none; }
        &::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: #bdbdbd; }
        &::-webkit-scrollbar-track {
          background-image: url("../../static/track.png");
          background-repeat: repeat-y;
          background-size: contain; } }
      &__loading {
        margin-bottom: 50px;
        left: 30%;
        position: relative;
        z-index: -1; } }
    &__pagination {
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      @media only screen and (min-width: $bp-desktop) {
        width: 85%; }
      &__list {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &__item {
          margin: 0 .5rem 1.25rem .5rem;
          button {
            background: white;
            border-radius: 50%;
            height: $paginationButtonSize;
            width: $paginationButtonSize;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000; }
          .button-shadow {
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
            color: $colorNaranja; }
          .current {
            color: #ffffff;
            background: $colorNaranja; } } } }
    &__text {
      @include stepDescription;
      font-size: .875rem;
      line-height: 120%;
      color: #000!important; }
    &__more {
      width: 85%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      &__btn {
        @include btn-primary;
        font-size: .75rem!important;
        padding: 1rem 2rem!important; } } }
  &__map {
    // display: none
    height: 100%;
    z-index: 0;
    @media only screen and (max-width: $bp-cell) {
      display: none; } }
  &__mapNone {
    display: none; }
  &__mapMobile {
    display: none;
    @media only screen and (max-width: $bp-cell) {
      display: block;
      height: calc( 100vh - 95px );
      margin: 0 auto;
      padding-bottom: 0;
      width: calc( 100% + 5% );
      margin-left: -2.5%;
      z-index: 10; } }
  &__map > div {
    height: 100%; }
  &__mapMobile > div {
    height: 100%; } }

.InfoWindow {
  display: grid;
  grid-template-columns: 1fr 60%;
  grid-gap: 10px;
  max-width: 300px;
  &__container {
    display: flex;
    flex-flow: column;
    justify-content: space-between; }
  &__title,
  &__description {
    font-family: $font-family-base; }
  &__title {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: .5rem;
    padding-bottom: .5rem;
    border-bottom: 2px solid $colorNaranja; }
  &__btn {
    @include btn-primary;
    font-size: .75rem!important;
    padding: .5rem!important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    transition: all 200ms ease-in-out;
    transform: scale(1);
    &:hover {
      background: $colorNaranjaHover;
      transition: all 50ms ease-in-out;
      transform: scale(.95); }
    &:active {
      background: $colorNaranjaHover;
      transition: all 50ms ease-in-outº; } }
  &__imgcontainer {
    width: 110px;
    height: 150px;
    &__img {
      object-fit: cover;
      width: 100%;
      height: 100%; } } }

.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  width: 500px;
  background-color: rgba(0,0,0,0.25);
  display: none;
  justify-content: center;
  align-items: center;

  modal.isOpen {}
  display: flex; }
.filtersDesktop {
  @media only screen and (max-width: $bp-cell) {
    display: none; } }
