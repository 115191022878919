.modalSuccessTransfer {
    background: #fff;
    width: 90%;
    max-width: 500px;
    border-radius: 24px;
    padding: 1.5rem;
    box-shadow: 2px 2px 5px #999;
    &__list {
        list-style-type: none;
        padding: 0;
        &__li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 10px;
            &:before {
                content: "";
                position: absolute;
                top: 8px;
                left: 0;
                height: 2px;
                border-left: 6px solid black; } } }

    &__title,
    &__subtitle {
        text-align: center; }
    &__buttons {
        display: flex;
        justify-content: center;
        gap: 20px; } }
