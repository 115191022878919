@import "../config";

.UserEditComponent {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  color: #FFF;
  min-height: 100vh!important;
  padding: 1rem;
  @media (min-width: $bp-desktop) {
    padding: 0;
    grid-template-columns: 1fr 1fr; }
  * {
    box-sizing: border-box; }
  &__btns {
    display: flex; }
  &__btn {
    @include btn-primary;
    box-sizing: border-box;
    border: 1px solid #FFFFFF!important;
    margin-top: 15px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    &__text {
      text-align: center; }
    &__outline {
      @include btn-outline(1rem, 1rem, 3rem);
      box-sizing: border-box;
      border: 1px solid $colorNaranja!important;
      color: $colorNaranja;
      margin-top: 15px;
      margin-right: 15px;

      text-align: center;
      align-items: center;
      justify-content: center;
      &__text {
        text-align: center; } } }
  &__left,
  &__right {
    display: flex;
    flex-flow: column;
    width: 100%!important;
    @media (min-width: $bp-desktop) {
      padding: 3rem!important; } }
  &__left {
    @media (min-width: $bp-desktop) {
      justify-content: space-between;
      padding: 0!important;
      padding: 2rem 0 !important; } }
  &__right {
    display: none;
    background: linear-gradient(0deg, #FF4E0010, #FF4E0010), url('../../static/edit_user.png');
    background-position: center;
    background-size: 125%;
    @media (min-width: $bp-desktop) {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 0!important; } }
  &__floating {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    @media (min-width: $bp-desktop) {
      max-width: 600px;
      margin-left: auto; } }
  &__tagline {
    font-family: $font-family-titles;
    color: $colorAmarilloNew;
    text-align: center;
    font-size: 3rem;
    margin-top: 20%; }
  &__title {
    @include stepTitle;
    font-family: $font-family-titles;
    color: $colorNaranja;
    font-size: 3rem!important;
    margin: 2rem auto .5rem auto;
    width: 90%!important;
    &.dni {
      margin: 2rem 0; }
    @media (min-width: $bp-desktop) {
      width: 100%!important;
      margin: 0 0 1.25rem 0!important; }
    strong {
      font-size: 1.25em;
      font-family: $font-family-titles; } }
  &__subtitle {
    @include stepSubtitle;
    margin-bottom: 1em;
    color: #FFF;
    margin: 0 auto;
    width: 90%!important;
    @media (min-width: $bp-desktop) {
      margin: 0;
      width: 100%!important; } }
  &__description {
    @include stepDescription;
    width: 90%;
    color: #FFF;
    margin: 0 auto;
    margin-bottom: .5em;
    @media (min-width: $bp-desktop) {
      margin: 0;
      margin-bottom: 1em;
      width: 80%; } }
  &__dniInputContainer {
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;
    &__title {
      margin-bottom: 10px;
      font-size: 1.25rem;
      font-weight: 500;
      color: $colorNaranja; } }
  &__inputContainer {
    @include inputContainerStyle;
    width: 100%!important;
    margin-bottom: 1rem!important;
    @media (min-width: $bp-desktop) {
      width: 80%!important; }
    &__label {
      @include labelStyle;
      color: $colorNaranja!important; }
    &__input {
      @include inputStyle;
      width: 100%!important;
      color: $colorNaranja!important;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none!important;
        margin: 0!important; }
      &::placeholder {
        color: #FF4E00DD!important; } }
    &__counter {
      color: #999999;
      font-size: 12px; }
    &__select {
      @include selectStyle;
      width: 100%!important; }
    &__textarea {
      @include textareaStyle;
      width: 100%!important;
      color: $colorNaranja!important;
      &::placeholder {
        color: #FF4E00DD!important; } } } }
