.displayNone {
    display: none; }
.modalGlobal {
    position: fixed;
    background: rgba(000, 000, 000,0.4 );
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 10; }
