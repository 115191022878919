@import "../../config";
.rulesResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; } }
.Rules {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  height: 90vh;
  padding: 20px;
  @media (min-width: $bp-desktop) {
    padding: 20px 0; }
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 3rem;
    justify-content: space-between;
    @media (min-width: $bp-desktop) {
      margin-bottom: 0;
      grid-template-columns: 45% 45%; }
    &-left {
      display: flex;
      flex-flow: column; }
    &-right, {
      flex-flow: column;
      display: flex; }
    &__title {
      @include stepTitle;
      font-weight: 700;
      font-size: 1.5rem!important;
      line-height: 130%;
      margin-bottom: 0rem!important;
      color: #000; }
    &__descriptionLeft,
    &__descriptionRigth {
      @include stepDescription;
      width: 100%;
      color: #000;
      font-size: 1.1rem;
      @media (min-width: $bp-desktop) {
        margin-bottom: 1.5rem!important;
        width: 90%; } }
    &__descriptionRigth {
      width: 100%;
      @media (min-width: $bp-desktop) {
        width: 80%; } }
    &__inputContainer {
      @include inputContainerStyle;
      margin: 1rem 0;
      &__label {
        @include labelStyle;
        color: $colorNaranja!important;
        padding-left: 0;
        text-transform: none;
        font-weight: 400;
        font-size: .8rem;
        top: -10px;
        letter-spacing: 0; }
      &__textarea {
        @include textareaStyle;
        width: 100%;
        color: #000!important;
        padding: 0;
        font-size: 1rem;
        padding: 1.2rem 0 0 0!important;
        @media (min-width: $bp-desktop) {
          width: 70%; } }
      &__textarea::placeholder {
        color: #000!important;
        font-size: 1rem; } }
    &__add {
      display: flex;
      justify-content: space-between;
      margin: 2rem 0;
      padding: 1rem;
      width: 80%;
      border: 1px solid #e1e1e1;
      box-sizing: border-box;
      &__text {
        @include stepSubtitle;
        font-size: 1.125rem;
        color: #666;
        line-height: 150%; }
      &__btn {
        background: none;
        font-size: 1.125rem;
        color: #000;
        line-height: 150%; } } } }
