@import "../_config";

.LoginModal {
    background: #FFF9EB;
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1024px;
    border-radius: 30px 0 0 30px;
    position: relative;
    z-index: 10;
    height: 600px;
    &__header {
        border: none!important;
        display: flex;
        align-items: flex-end;
        height: 130px;
        justify-content: center;
        margin-top: 40px !important;
        position: relative;
        z-index: 1;
        @media (min-width: 1000px) {
            margin-top: 0px!important; }
        &__title {
            font-family: "CastaBold";
            margin: 0!important;
            font-size: 3.5rem;
            color: #000!important;
            font-weight: 700; }
        &__cross {
            position: absolute;
            top: 2.5%;
            right: 5%;
            width: 35px;
            color: #fff!important;
            cursor: pointer; } }
    &__section {
        padding: 0 20px 10px 20px!important;
        text-align: center;
        background: #fff;
        @media (min-width: $bp-desktop) {
            border-radius: 30px 0 0 30px;
            max-height: 600px;
            padding: 1.2rem 6.3rem 0 6.3rem!important;
            background: #fff; }
        &__errorContainer {
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            border-radius: 30px 30px 0 0;
            grid-gap: .5rem;
            background: rgba(255, 0, 0, 0.07);
            padding: .8rem;
            width: 100%;
            @media (min-width: 600px) {
                width: 70%; }
            @media (min-width: $bp-desktop) {
                width: 62%;
                border-radius: 30px 0 0 0; }
            &__error {
                display: flex;
                justify-content: center;
                &__text {
                    font-family: $font-family-base;
                    font-weight: 700;
                    font-size: .9375rem;
                    color: $colorNaranjaError!important;
                    margin-left: 1rem!important; }
                &__icon {
                    margin-left: -3.5rem; } } } }
    &__form {
        &__socialContainer {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 10px 0;
            grid-gap: 40px;
            @media (max-width: 600px) {
                display: grid;
                grid-template-columns: 1fr; }
            &__btn {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                cursor: pointer;
                &.facebook {
                    background: #004B87!important;
                    border-radius: 100%!important;
                    width: 3.5rem;
                    height: 3.5rem; }
                &.google {
                    width: 3.5rem;
                    height: 3.5rem;
                    border-radius: 100%!important;
                    background: #fff!important;
                    border: 1px solid #DDDDDD; }
                &.apple {
                    width: 3.5rem;
                    height: 3.5rem;
                    border-radius: 100%!important;
                    background: #000!important; }
                &__text {
                    margin: 0!important;
                    font-size: 15px;
                    color: #fff!important; }
                &__icon {
                    padding: 10px;
                    width: 3rem;
                    height: 3rem; } } }
        &__separator {
            display: flex;
            align-items: center;
            padding: 0 30px 0 30px;
            &__text {
                width: 300px;
                font-size: 14px;
                font-weight: 700;
                color: #000!important; } }
        &__container {
            &.links {
                // margin-bottom: 30px
                display: flex;
                justify-content: center;
 }                // margin-top: 50px
            &__link {
                display: flex;
                align-items: center;
                justify-content: space-around;
                color: #000;
                font-size: .75rem;
                cursor: pointer;
                p {
                    font-family: "Roboto";
                    font-weight: 500;
                    color: $colorNaranja;
                    margin-left: 5px!important; }
                &__checkcontainer {
                    padding: 0 30px 0 30px;
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    &__check {
                        display: flex;
                        align-items: center;
                        &__checkIn {
                            color: #000!important;
                            background: #000!important;
                            cursor: pointer; }
                        input[type=checkbox] {
                            display: none; }
                        input[type=checkbox] + &__label {
                            cursor: pointer; }
                        &__label:before {
                            content: '';
                            background: transparent;
                            border-radius: 5px;
                            display: inline-block;
                            height: 20px;
                            width: 20px;
                            margin-right: 5px;
                            text-align: center;
                            vertical-align: middle; }
                        input[type=checkbox]:checked + &__label:before {
                            content: '✓';
                            font-size: 12px;
                            color: #fff;
                            background: $colorNaranja; }
                        &__pass {
                            color: $colorNaranja!important;
                            font-size: 12px!important;
                            font-family: "Roboto";
                            padding: 20px 0; }
                        &__pass:hover {
                            text-decoration: underline #000!important;
                            cursor: pointer; } } }
                &:hover {
                    text-decoration: underline!important; } } }
        &__inputContainer {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            grid-gap: 20px;
            padding: 0 10px;
            box-sizing: border-box;
            * {
                box-sizing: border-box; }
            &__inputWrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                max-width: -webkit-fill-available;
                max-width: -moz-available;
                box-sizing: border-box;
                border-bottom: 1px solid $colorNaranja;
                margin: 0 20px 0 20px;
                @media (max-width: $bp-cell) {
                    min-width: none!important;
                    width: 100%!important;
                    margin-top: 0!important; }
                &.error {
                    border-bottom: 1px solid red; }
                &__label {
                    width: 100%;
                    font-size: .75rem;
                    text-align: left;
                    text-transform: none!important;
                    color: $colorNaranja; }
                &__input {
                    font-family: "Roboto", sans-serif;
                    background: #fff!important;
                    width: 100%;
                    border: none!important;
                    font-size: 16px;
                    line-height: 18px;
                    color: #000!important;
                    padding: .5rem 0;
                    &:focus {
                        outline-width: 0;
                        background: #fff; }
                    &::placeholder {
                        color: #000; } }
                &__passwordWraper {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-flow: row;
                    align-items: center; }
                i:hover {
                    cursor: pointer; } } }
        &__buttons {
            display: flex;
            flex-flow: row;
            align-content: center;
            justify-content: center;
            margin: 0 30px 0 30px;
            &__btn {
                @include btn-primary;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                font-size: 1.25rem!important;
                padding: 1.25rem 1.5rem!important;
                margin: 30px auto 30px auto;
                font-size: 17px!important;
                line-height: 20px!important; } } } }


.LoginBackground {
    position: relative;
    background-image: url("../../../assets/static/Landing/backgrounds/loginBackground.webp");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat!important;
    height: (calc( 100% + 1px ));
    border-radius: 0 30px 30px 0;
    display: none;
    @media (min-width: 600px) {
        display: block;
        width: 300px; }
    @media (min-width: 1024px) {
        display: block;
        width: 500px; } }
hr {
    border: 1px solid #2B2B2B;
    width: 100%; }
p {
    margin: 0!important; }
