@import "../../config";

.Card {
  display: flex;
  flex-flow: column;
  background-color: $colorBlanco;
  border-radius: 20px;
  &.black {
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,0.5); }
  &__img {
    // background-image: url('../../../static/PlaceCards/place1.png')
    background-size: cover;
    background-repeat: no-repeat;
    height: 378px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 20px 20px 0 0;
    &__price {
      background: $colorBlanco;
      font-family: $font-family-base;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      padding: .5em 1em;
      border-radius: 22.5px;
      margin: 1.125rem; } }
  &__title {
    @include stepTitle;
    margin-top: 1.25rem;
    font-size: 1.5rem;
    line-height: 120%;
    color: #000;
    padding: 0 1rem; }
  &__description {
    @include stepDescription;
    margin-top: .5rem;
    padding: 0 1rem!important;
    padding-bottom: 2rem!important;
    &.black {
      color: #000!important; } } }
