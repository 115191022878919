@import "../../config";
.titleResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; }
  &__Title {
    position: relative;
    max-width: 1200px;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    width: 100%;
    justify-content: flex-start!important;
    height: 100vh;
    padding: 2rem 0;
    @media (max-width: $bp-desktop) {
      padding: 2rem;
      display: grid;
      grid-template-rows: 1fr 1fr; }
    @media (min-width: $bp-big-display) {
      height: 70vh; }
    &__error {
      width: 91%; }
    * {
      box-sizing: border-box!important; }
    .max_width {
      @media (min-width: $bp-desktop) {
        max-width: 100%; }
      &__title {
        color: #2B2B2B;
        font-weight: 800;
        font-family: $font-family-titles;
        font-size: 4rem; }
      &__description {
        color: #2B2B2B;
        font-family: $font-family-base;
        font-size: 1.5rem;
        font-weight: 500;
        margin: 0 auto;
        display: flex;
        justify-items: center;
        justify-content: center; } }
    &__title {
      font-family: $font-family-base;
      font-weight: 700!important;
      width: 100%;
      color: #000;
      font-size: 1.5rem;
      margin-bottom: 0rem;
      @media (min-width: $bp-desktop) {
        width: 100%; } }
    &__description {
      font-size: 1.2rem;
      font-family: $font-family-base;
      font-weight: 400!important;
      color: #000!important;
      margin-bottom: 1.5rem!important; }
    &__inputContainer {
      margin-bottom: 2rem!important;
      width: 100%;
      color: $colorBlanco;
      @media (max-width: $bp-cell) {
        width: 100%; }
      &__label {
        color: $colorNaranja;
        width: 100% !important;
        font-size: .8rem; }
      &__input {
        font-family: $font-family-base;
        font-weight: 500;
        color: #000;
        background: none;
        border: none;
        border-bottom: 1px solid $colorNaranja;
        width: 40%;
        font-size: 1rem;
        padding: 0.2rem 0 .3rem 0;
        margin-top: -1rem;
        @media (max-width: $bp-cell) {
          width: 100%; } }
      &__textarea {
        font-family: $font-family-base;
        font-weight: 500;
        font-size: 1rem;
        color: #000;
        display: flex;
        border: none;
        border-bottom: 1px solid $colorNaranja;
        width: 100%!important;
        resize: none;
        @media (max-width: $bp-cell) {
          width: 100%; }
        &__counter, &__counterDescription {
          color: #999999;
          font-size: 12px;
          display: flex;
          justify-content: end;
          width: 40%;
          @media (max-width: $bp-cell) {
            width: 100%; } }
        &__counterDescription {
          width: 100%; } } }
    &__buttons {
      position: absolute;
      bottom: 100px;
      right: 100px; } } }


