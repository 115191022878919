@import "../../config";

.FirstPhotoRadio {
  width: 80%;
  margin: .5rem 0;
  &__radio {
    display: none; }
  &__radio:checked ~ &__container > &__container__label > &__container__label__text {
    color: $colorBlanco; }
  &__radio:checked ~ &__container > &__container__label > &__container__label__check {
    border-color: $colorBlanco;
    background: $colorNaranja;
    &::after {
      display: block; } }
  &__radio:checked ~ &__container > &__container__label > &__container__label__check > svg > &__container__label__check__path {
    stroke: $colorBlanco; }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__text {
      @include stepSubtitle;
      font-size: 1.125em; }
    &__label {
      display: flex;
      align-items: center;
      cursor: pointer;
      &__text {
        color: #000!important;
        font-size: 1rem;
        font-weight: 500; }
      &__check, &__cross {
        border-radius: 50%;
        height: 1.5rem!important;
        width: 1.5rem!important;
        display: flex;
        background-color: none;
        position: relative;
        justify-content: center;
        align-items: center;
        border: 2px solid #000;
        margin-right: 10px;
        cursor: pointer;
        &::after {
          display: none;
          content: "";
          border: 2px solid #fff;
          background: $colorNaranja;
          position: absolute;
          border-radius: 100%;
          height: 1rem!important;
          width: 1rem!important; } } } } }

