@import "../../config";

.Values {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 50px;
  @media (min-width: $bp-desktop) {
    padding-top: 0;
    grid-template-columns: 1fr 1fr; }
  * {
    box-sizing: border-box; }
  &__left,
  &__right {
    display: flex;
    height: calc(100vh - 65px);
    flex-flow: column;
    width: 100%!important;
    @media (min-width: $bp-desktop) {
      height: calc(100vh - 7.25rem);
      padding: 3rem!important; } }
  &__left {
    margin-bottom: 8rem;
    @media (min-width: $bp-desktop) {
      margin-bottom: 0rem;
      justify-content: space-between;
      padding: 0!important; } }
  &__right {
    display: none;
    background: linear-gradient(0deg, #FF4E0010, #FF4E0010), url('../../../static/AloharForm/values-bg.png');
    background-position: center;
    background-size: 200%;
    @media (min-width: $bp-desktop) {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center; } }
  &__floating {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: relative;
    @media (min-width: $bp-desktop) {
      max-width: 600px;
      margin-left: auto; } }
  &__tagline {
    font-family: $font-family-titles;
    color: $colorAmarilloNew;
    text-align: center;
    font-size: 3rem; }
  &__title {
    @include stepTitle;
    font-family: $font-family-titles;
    color: $colorNaranja;
    font-size: 3rem!important;
    margin: 2rem auto .5rem auto;
    width: 90%!important;
    @media (min-width: $bp-desktop) {
      width: 100%!important;
      margin: 0 0 1.25rem 0!important; }
    strong {
      font-size: 1.25em;
      font-family: $font-family-titles; } }
  &__subtitle {
    @include stepSubtitle;
    margin-bottom: 1em;
    color: #000;
    margin: 0 auto;
    width: 90%!important;
    @media (min-width: $bp-desktop) {
      margin: 0;
      width: 100%!important; } }
  &__description {
    @include stepDescription;
    width: 89%!important;
    color: #000!important;
    margin: 0 auto .5em auto!important;
    @media (min-width: $bp-desktop) {
      margin: 0;
      margin-bottom: 1em;
      width: 80%; } }
  .Buttons {
    justify-content: flex-start;
    width: 90%!important;
    margin: 2rem auto 0 auto;
    @media (min-width: $bp-desktop) {
      margin: 2rem 0 0 0;
      width: 100%!important; }
    * {
      margin: 0!important; } } }
