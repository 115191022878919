@import "../config";

.modalNone {
  display: none; }
.hertzModal {
  position: fixed;
  height: 400px;
  width: 500px;
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 16px 2px rgba(0,0,0,0.5);
  @media (max-width: $bp-tablet) {
    width: 90%; }
  &__container {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    z-index: 200;
    &__header {
      &__title {
        font-family: $font-family-titles;
        font-size: 1.5rem; }
      &__subtitle {
        font-family: $font-family-base; }
      &__title,
      &__subtitle {
        text-align: center; } }
    &__close {
      position: absolute;
      right: 0;
      top: -1rem;
      width: 25px;
      cursor: pointer; }
    &__body {
      display: flex;
      align-items: center;
      gap: 10px;
      &__square:before {
        /* Unicode bullet symbol */
        content: '\2022 ';
        /* Bullet color */
        color: $colorNaranja;
        position: absolute;
        left: -6px;
        transform: translateX(-50%); }
      &__square {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $colorNaranja;
        border-radius: 4px;
        height: 2rem;
        width: 2rem;
        position: relative;
        list-style: $colorNaranja;
        list-style: none; }
      &__square:nth-child(1)::before {
        display: none;
        &__li {
          list-style: none; } }
      &__separator {
        background: $colorNaranja;
        height: 7px;
        width: 7px;
        border-radius: 100%; }
      &__button {
        @include btn-primary; } } } }
