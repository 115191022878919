@import "../config";
.inputNewStyle {
    width: 100%!important; }
.input_wrapperNew {
    color: #000!important;
    border: none!important; }

.inputCity  > ul > li {
    font-size: 1rem;
    color: #000!important;
    border: none!important; }

.DateInput {
    margin: 0;
    padding: 0;
    margin: 0;
    padding: 0;
    background: none;
    position: relative;
    display: flex;
    vertical-align: middle;
    box-sizing: border-box;
    width: 100%; }
.DateRangePicker {
    box-sizing: border-box;
    width: 100%!important; }

.DateRangePickerInput {
    box-sizing: border-box;
    width: 100%!important;
    display: flex;
    justify-content: space-between;
    background: none!important; }

.DateInput_input {
    box-sizing: border-box;
    font-size: .85rem;
    line-height: 100%!important;
    font-family: $font-family-base;
    color: $colorNaranja;
    background: none;
    font-weight: 500;
    padding: 0!important;
    width: 100%;
    padding-bottom: .25rem!important;
    border-bottom: 1px solid $colorNaranja !important;
    &::placeholder {
        color: $colorNaranja; }
    @media (min-width: $bp-desktop) {
        width: 85%!important; }
    .DateRangePickerInput_arrow {
        display: none; } }
.inputNewStyle {
    box-sizing: border-box;
    font-size: .85rem;
    line-height: 100%!important;
    font-family: $font-family-base;
    color: $colorNaranja;
    background: none;
    font-weight: 500;
    padding: 0!important;
    width: 100%;
    padding-bottom: .25rem!important;
    border-bottom: none!important;
    border: none;
    &::placeholder {
        color: $colorNaranja; }
    @media (min-width: $bp-desktop) {
        width: 85%!important; }
    .DateRangePickerInput_arrow {
        display: none; } }

.DateRangePickerInput_clearDates {
    margin: 0!important;
    padding: 2.5px 5px!important; }

.DateRangePickerInput_arrow {
    display: none!important; }

.CalendarDay__selected_span {
    background: #FF4E00cc;
    background: #FF4E00cc;
    border: 1px double $colorBlanco;
    color: $colorBlanco;

    &:active {
        background: $colorNaranja !important;
        background: $colorNaranja !important;
        border: 1px double $colorBlanco; }
    &:active {
        background: $colorNaranja !important;
        background: $colorNaranja !important;
        border: 1px double $colorBlanco; } }

.CalendarDay__selected {
    background: $colorNaranja;
    background: $colorNaranja;
    border: 1px double $colorBlanco;

    &:active {
        background: $colorNaranja !important;
        background: $colorNaranja !important;
        border: 1px double $colorBlanco; }
    &:hover {
        background: $colorNaranja !important;
        background: $colorNaranja !important;
        border: 1px double $colorBlanco; } }

.CalendarDay__hovered_span {
    padding-bottom: .25rem;
    top: 41px !important;
    background: #FF4E00cc !important;
    top: 41px !important;
    background: #FF4E00cc !important;
    border: 1px double $colorBlanco;
    color: $colorBlanco!important;

    &:active {
        background: $colorNaranja !important;
        background: $colorNaranja !important;
        border: 1px double $colorBlanco; }

    &:hover {
        background: $colorNaranja !important;
        background: $colorNaranja !important;
        border: 1px double $colorBlanco;
        col {} } }
@media (max-width: $bp-tablet) {
    .DayPickerNavigation_button__verticalDefault {
        width: 45% !important;
        width: 45% !important; }

    .DayPickerNavigation__verticalDefault {
        bottom: 20px !important;
        margin-left: 4px !important; }
    .DayPickerNavigation__verticalDefault {
        bottom: 20px !important;
        margin-left: 4px !important; }

    .DayPicker_transitionContainer__vertical {
        height: 670px !important;
        height: 670px !important; }

    .DateRangePicker_picker {
        height: 670px !important; } }

.navbar {
    .DateInput_input {
        width: 85%; }
    .DateInput__small {
        width: 50%; }
    .DateRangePickerInput {
        padding: 0!important; } }
.input_wrapperNew > input {
    color: #000!important;
    font-size: 2vw!important;
    @media (max-width: $bp-cell) {
        text-align: start!important;
 }        // font-size: 11vw
    &::placeholder {
        color: #000!important;
        border: none!important;
        @media (max-width: $bp-cell) {
            text-align: start!important; } } }
