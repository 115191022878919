@import '../../config';
.TypeResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; } }
.Type {
  position: relative;
  height: 80vh;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px 20px;
  @media (min-width: $bp-desktop) {
    padding: 20px 0; }
  @media (min-width: $bp-big-display) {
    height: 60vh; }
  &__container {
    width: 100%;
    @media (min-width: $bp-desktop) {
      width: 40%; } }
  &__subtitle,
  &__title {
    @include stepTitle;
    font-size: 1.5rem!important;
    font-weight: normal!important;
    margin-bottom: 1.25rem;
    font-weight: 700!important;
    color: #000; }
  &__subtitle {
    padding-top: 2rem; }
  &__inputContainer {
    @include inputContainerStyle;
    margin-bottom: 1rem;
    &__label {
      @include labelStyle;
      color: $colorNaranja!important;
      padding: 0!important;
      margin: 0!important;
      font-size: .8rem;
      letter-spacing: 0;
      font-weight: 400;
      left: 5px; }
    &__select {
      @include selectStyle;
      width: 100%;
      font-size: 1rem;
      padding: 1.1rem 0 .4rem 0;
      color: #000!important;
      @media (min-width: $bp-desktop) {
        width: 72%; } } } }
