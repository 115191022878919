@import '../../_config.sass';

.PublicProfile {
  box-sizing: border-box!important;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
  margin: 3rem auto 2rem auto;
  max-width: 100%!important;
  width: 90%;
  min-height: 70vh;
  @media (min-width: $bp-desktop) {
    max-width: 1000px!important; }
  &__container {
    display: flex;
    flex-flow: column;
    @media (min-width: $bp-desktop) {
      display: grid;
      grid-template-columns: 30% 1fr;
      grid-gap: 70px;
      align-items: flex-start;
      align-content: flex-start; } } }
