@import "../../config";

.Buttons {
    display: flex;
    flex-flow: column;
    padding-bottom: 6rem;
    width: 100%;
    justify-content: end;
    @media (min-width: $bp-desktop) {
      padding-bottom: 2rem;
      flex-flow: row;
      align-items: center; }
    &__btn-secondary,
    &__btn-primary {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 1rem; }
    &__btn-secondary {
      @include btn-outline(1rem, 1rem, 3rem);
      border: 1px solid $colorNaranja!important;
      color: $colorNaranja;
      background: #fff;
      box-shadow: 0px 4px 6px rgba(43, 43, 43, 0.3);
      @media (min-width: $bp-desktop) {
        padding: 1rem 3rem; } }
    &__btn-primary {
      box-shadow: 0px 4px 6px rgba(43, 43, 43, 0.3);
      text-align: center;
      font-size: 1rem!important;
      border-radius: 100px!important;
      font-family: "Roboto",sans-serif!important;
      font-weight: 500!important;
      color: #fff!important;
      background: #ff4e00!important;
      margin-top: 15px;
      margin-bottom: 15px;
      @media (min-width: $bp-desktop) {
        padding: 1rem 3rem;
        margin: 0;
        margin-left: 15px; }
      &:disabled {
        cursor: not-allowed;
        pointer-events: all !important; } } }
