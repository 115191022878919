@import "../../config";

.Finished {
  @include stepContainer;
  justify-content: flex-start;
  position: relative!important;
  max-width: 1200px!important;
  margin: 0 auto!important;
  padding: 20px;
  @media (min-width: $bp-desktop) {
    padding: 20px 0; }
  &__progressBar::before {
    content: '';
    border-right: 100px solid #38D1DA;
    border-radius: 0 15px 15px 0;
    height: 12px;
    position: absolute;
    right: 0;
    top: 85px;
    z-index: 5;
    @media (min-width: $bp-desktop) {
      top: 57px;
      right: 0px; } }
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    @media (min-width: $bp-desktop) {
      grid-template-columns: 60% 1fr; }
    &-left, &-right {
      display: flex;
      flex-flow: column; }
    &__title {
      @include stepTitle;
      font-size: 1.5rem!important;
      color: #000;
      margin-bottom: 1.5rem; }
    &__description {
      @include stepDescription;
      color: #000;
      font-size: 1.2rem!important;
      @media (min-width: $bp-desktop) {
        width: 70%; } }
    &__btn {
      color: $colorNaranja;
      font-weight: 500;
      font-size: 1.2rem; }
    &__btn-primary {
      @include btn-primary; } } }
