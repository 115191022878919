@import "../../config";
.GalleryResponsive {
  background: #fff; }
.Gallery {
  margin: 0 auto;
  width: 1200px;
  &__addPhoto {
    border: 2px dashed #AEAEAE;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    border-radius: 20px;
    @media (min-width: $bp-desktop) {
      width: 100%;
      height: 170px; }
    &__btn {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      color: #AEAEAE;
      font-size: .8rem!important;
      padding: 1rem 3.5rem!important;
      background: #fff;
      &__svg {
        height: 50px;
        width: 100%;
        @media (min-width: $bp-desktop) {
          width: 40px; }
        path {
          stroke: #AEAEAE; } } } }

  &__big__container {
    display: grid;
    grid-template-columns: 1fr!important;
    grid-gap: 1.25rem;
    width: 100%!important;
    @media (min-width: $bp-desktop) {
      grid-template-columns: 30% 1fr!important; }
    &__column {
      display: flex;
      flex-flow: column;
      align-items: start;
      width: 100%!important;
      border-radius: 50px;
      &__title {
        @include stepSubtitle;
        color: $colorNaranja;
        font-weight: 700;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        text-align: start; }
      &__list {
        text-align: left;
        list-style: none;
        width: 80%!important;
        @media (min-width: $bp-desktop) {
          width: 100%!important; }
        &__description {
          @include stepDescription;
          color: #000;
          font-weight: 700;
          font-size: 1.2rem;
          margin-bottom: 1.5rem;
          position: relative;
          &__text {
            font-size: 1rem;
            line-height: 1.2rem;
            font-weight: 400; } } } } }
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%!important;
    grid-row-gap: 20px;
    @media (min-width: $bp-desktop) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 240px;
      grid-row-gap: 0;
      grid-column-gap: 20px; }
    &__galleryItem {
      display: flex;
      flex-flow: column;
      position: relative;
      overflow: hidden;
      width: 100%;
      &__upperBar {
        display: flex;
        justify-content: space-between; }
      &__firstOne {
        z-index: 10;
        width: 100%;
        height: 20px;
        font-size: .9375rem;
        letter-spacing: .5rem;
        line-height: 1rem;
        text-align: center;
        display: flex;
        text-transform: uppercase;
        font-family: $font-family-base;
        align-items: center;
        justify-content: center;
        color: $colorBlanco;
        font-weight: 500;
        box-shadow: 0px 2px 5px #000;
        background: gold;
        border-top: 5px solid $colorNaranja;
        border-bottom: 5px solid $colorNaranja;
        background: $colorNaranja;
        position: absolute;
        transform: rotate(-45deg);
        top: 10%;
        left: -30%; }
      &__delBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .5rem;
        background: #000;
        border-radius: 100%;
        position: absolute;
        top: 10px;
        right: 10px;
        svg {
          fill: #fff; } }
      &__clipper {
        width: 100%;
        height: 170px;
        position: relative;
        @media (max-width: $bp-cell) {
          width: 100%; }
        &__img {
          border-radius: 20px; }
        &__spinner {
          position: relative;
          width: 100%;
          height: 100%;
          background: #ffffff47;
          bottom: 102%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100px; } }
        &__img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }
      &__inputContainer {
        padding: 1rem 0;
        &__input {
          font-family: $font-family-base;
          font-weight: 500;
          font-size: 1.15rem;
          color: $colorBlanco;
          background: none!important;
          line-height: 120%;
          border: none;
          display: block!important;
          width: 100%;
          padding-bottom: .5rem;
          border-bottom: 1px solid #968280;
          &::placeholder {
            color: #968280; } } } }
    &__add {
      display: flex;
      justify-content: center;
      align-items: center;
      &__btn {
        @include btn-primary;
        display: flex;
        align-items: center; } } }
  input {
    display: none; } }
