@import "../config";

.Login {
  width: 100%!important;
  height: 100%;
  min-height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
  @media (max-width: $bp-cell) {
    padding-top: 100px; }
  &__form {
    margin: 0 auto;
    width: 100%!important;
    max-width: 568px !important;
    border: 1px solid #B0B0B0 !important;
    border-radius: .75rem !important;
    display: flex;
    flex-flow: column;
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 64px;
      border-bottom: 1px solid #EBEBEB !important;
      &__title {
        color: $colorNegroNew;
        font-size: 1rem;
        line-height: 120%;
        font-weight: 700; } }
    &__body {
      padding: 1rem 2rem;
      &__inputcontainer {
        @include inputContainerStyle;
        margin-bottom: .5rem!important;
        width: 100%!important;
        &__passContainer {
          display: flex;
          align-items: center;
          position: relative;
          i {
            position: absolute;
            right: 0;
            bottom: 0; } }
        &__label {
          @include labelStyle;
          width: 100%; }
        &__input {
          @include inputStyle;
          width: 100%;
          font-size: 1rem;
          &.error {
            border: 1px solid $colorNaranja; } } }
      &__btn {
        @include btn-primary;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 120%; }
      &__separator {
        margin: .8rem 0 1rem 0;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-grow: 4;
        &__text {
          min-width: max-content;
          padding-left: .75rem;
          padding-right: .75rem;
          color: #999999;
          font-size: .75rem; }
        hr {
          flex-grow: 0;
          width: -webkit-fill-available; } }
      &__social_container {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media (max-width: $bp-cell) {
          flex-flow: column;
          align-items: center; }
        img {
          max-width: 50%;
          width: 100%;
          @media (max-width: $bp-cell) {
            max-width: 100%; } } }
      &__forgotpassword {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 0;
        &__btn {
          background: none;
          color: $colorNaranja; } } } } }
