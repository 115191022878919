@import "../config";

.FlagArgentina {
    height: 60px;
    background: #96F9FF;
    width: 100%;
    position: absolute;
    transform: rotate(-45deg);
    width: 400px;
    right: -190px;
    bottom: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    &__whitestrip {
        background: #ffffff;
        width: 100%;
        height: 20px; }
    &__container {
        width: 100%!important;
        height: 200px;
        position: relative;
        overflow: hidden;
        margin-top: -200px; } }
