.sendMessage {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 60px);
    box-sizing: border-box;
    @media only screen and (max-width: 600px) {
        padding-bottom: 0;
        position: relative;
        height: 100%; }
    &__loading {
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 80px;
        @media only screen and (max-width: 600px) {
            padding-top: 50%; } }
    &__content {
        height: 95%;
        display: flex;
        // flex-flow: column-reverse
        flex-direction: column;
        overflow-y: scroll;
        overflow: auto;
        padding-bottom: 20px;
        @media only screen and (max-width: 600px) {
            height: calc(100% - 131px);
            // height: calc( 100vh - 138px)
            padding: 170px 0 150px 0; }
        &::-webkit-scrollbar,
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-track {
            width: 8px;
            border: none;
            background: transparent; }
        &::-webkit-scrollbar-button,
        &::-webkit-scrollbar-track-piece,
        &::-webkit-scrollbar-corner,
        &::-webkit-resizer {
            display: none; }
        &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background-color: #bdbdbd; }
        &::-webkit-scrollbar-track {
            background-image: url("../../../../assets/static/track.png");
            background-color: transparent;
            background-repeat: repeat-y;
            background-size: contain; }
        &__container,
        &__containerLeft {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding: 1px 60px;
            @media only screen and (max-width: 600px) {
                padding: 1px 10px; }
            &__message,
            &__messageLeft {
                border-radius: 8px 8px 8px 8px;
                padding: 6px 7px 8px 9px;
                position: relative;
                display: flex;
                background: rgb(255, 112, 49);
                width: auto;
                max-width: 60%;
                justify-content: space-between;
                text-align: right;
                background: rgba(#cccc, 0.8);
                z-index: 2;
                @media only screen and (max-width: 600px) {
                    max-width: 100%; }
                &__textLeft,
                &__textRight {
                    text-align: left;
                    width: fit-content;
                    font-size: 14px; }
                &__textRight {
                    text-align: right; }
                &__date {
                    width: 70px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    height: 100%;
                    font-size: 10px;
                    margin-left: 6px; }
                &__left,
                &__rigth {
                    z-index: 5;
                    position: absolute;
                    top: 0;
                    z-index: 100;
                    display: block;
                    width: 8px;
                    height: 13px;
                    right: 15px; }
                &__left {
                    border-left: 15px solid rgb(255, 112, 49);
                    border-top: 0px solid transparent;
                    border-bottom: 10px solid transparent; }
                &__rigth {
                    right: 15px;
                    border-right: 15px solid rgba(#cccc, 0.8);
                    border-top: 0px solid transparent;
                    border-bottom: 10px solid transparent; } }
            &__messageLeft {
                text-align: left;
                background: rgb(255, 112, 49); }
            &__message::before {
                content: '';
                position: absolute;
                z-index: -1;
                width: 0;
                height: 0;
                top: 0;
                border-right: 10px solid rgba(#cccc, 0.8);
                border-top: 0px solid transparent;
                border-bottom: 10px solid transparent; }
            &__messageLeft::before {
                content: '';
                position: absolute;
                z-index: -1;
                width: 0;
                height: 0;
                right: -6px;
                top: 0;
                border-left: 15px solid rgb(255, 112, 49);
                border-top: 0px solid transparent;
                border-bottom: 15px solid transparent; } }
        &__containerLeft {
            align-items: flex-end; } } }
