@import "../_config";

.RegisterModal {
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    &__header {
        border: none!important;
        border-bottom: 1px solid $colorNaranja!important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0!important;
        margin: 0!important;
        position: relative;
        &__title {
            margin: 0!important;
            font-size: 1.5rem;
            color: $colorNaranja!important;
            font-weight: 700; }
        &__cross {
            position: absolute;
            top: 50;
            right: 4%;
            width: 13px;
            color: $colorNaranja!important;
            cursor: pointer; } }
    &__section {
        padding: 0 20px 10px 20px!important;
        text-align: center;
        @media (min-width: $bp-desktop) {
            padding: 0 20px 10px 20px!important; } }
    &__form {
        &__socialContainer {
            margin-top: 10px;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 40px;
            @media (min-width: $bp-desktop) {
                grid-template-columns: 1fr 1fr; }
            &__btn {
                @include btn-primary;
                width: 100%;
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                padding: .5rem 1.5rem!important;
                &.facebook {
                    background: #4A69AB!important; }
                &.google {
                    background: #5385EA!important; }
                &__text {
                    margin: 0!important;
                    font-size: 15px;
                    color: #fff!important; }
                &__icon {
                    margin-right: 5px!important;
                    padding: 10px;
                    height: 15px; } } }
        &__separator {
            padding: 20px 10px 0 10px;
            display: flex;
            justify-content: center;
            &__text {
                font-size: 1.25rem;
                font-weight: 700;
                color: $colorNaranja!important; } }
        &__container {
            margin-top: 10px;
            padding: 0 30px;
            &.links {
                margin-bottom: 30px;
                display: flex;
                justify-content: space-between; }
            &__text {
                text-align: left;
                color: $colorNaranja;
                font-size: .75rem; } }
        &__inputContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
            padding: 0 10px;
            box-sizing: border-box;
            * {
                box-sizing: border-box; }
            &__inputWrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-width: -webkit-fill-available;
                max-width: -moz-available;
                box-sizing: border-box;
                border-bottom: 1px solid $colorNaranja;
                padding: 10px 0;
                margin-top: 20px;
                margin: 20px 20px 0 20px;
                @media (max-width: $bp-cell) {
                    min-width: none!important;
                    width: 100%!important;
                    margin-top: 0!important; }
                &.error {
                    border-bottom: 1px solid red; }
                &__label {
                    width: 100%;
                    font-size: 1rem;
                    font-weight: bold;
                    text-align: left;
                    text-transform: none!important;
                    color: $colorNaranja;
                    margin-bottom: 15px; }
                &__input {
                    width: 100%;
                    border: none!important;
                    font-size: 13px;
                    color: $colorNaranja!important;
                    &:focus {
                        outline-width: 0; }
                    &::placeholder {
                        color: $colorNaranja!important; } }
                &__passwordWraper {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-flow: row; }
                i {
                    margin-right: 10px; }
                i:hover {
                    color: $colorNaranja;
                    cursor: pointer; } } }
        &__buttons {
            display: flex;
            flex-flow: row;
            align-content: center;
            justify-content: center;
            &__btn {
                @include btn-primary;
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                font-size: 1.25rem!important;
                padding: .75rem 1.5rem!important;
                margin: 10px auto 30px auto; } } } }
