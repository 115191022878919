@import "../config";
@import "./utils/__starRatings";
.Valuation {
  /* Box Sizing */
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  color: #FFF;
  min-height: 100vh!important;
  /* Style */
  @media (min-width: $bp-desktop) {
    min-height: 85vh!important;
    grid-template-columns: 1fr 1fr; }
  * {
    box-sizing: border-box; }
  &__floating {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-bottom: 100px;
    @media (min-width: $bp-desktop) {
      max-width: 600px;
      padding-right: 3rem;
      margin-left: auto!important; } }
  &__container {
    width: 95%;
    max-width: 100%!important;
    padding: 0 .5rem;
    margin: 0 auto!important;
    @media only screen and (min-width: $bp-desktop) {
      margin: 0!important;
      padding: 0;
      width: 100%;
      max-width: 100%!important;
      margin-left: auto; }
    &__separator {
      border: 1px solid $colorNaranja;
      width: 100%; }
    &__title {
      font-size: 50px;
      font-family: $font-family-titles;
      font-weight: 400;
      color: $colorNaranja;
      line-height: 150%;
      margin-bottom: 1.5rem!important; }
    &__userData {
      width: 100%;
      display: grid;
      grid-template-columns: 66px 1fr;
      grid-template-rows: 1fr 1fr;
      grid-gap: .25rem 1.125rem;
      align-items: center;
      margin-bottom: 1.5rem;
      @media only screen and (min-width: $bp-desktop) {
        margin-bottom: 1rem; }
      &__imgcontainer {
        width: 66px;
        height: 66px;
        border-radius: 50%;
        grid-area: 1 / 1 / span 2 / span 1;
        &__img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          object-fit: cover; } }
      &__name {
        color: $colorNaranja;
        font-size: 1.25rem!important;
        line-height: 150%;
        font-weight: 500;
        align-self: flex-end; }
      &__text {
        color: $colorNaranja;
        font-size: 1rem!important;
        font-weight: 400;
        line-height: 150%;
        align-self: flex-start; }
      &__link {
        color: $colorNaranja!important; } }
    &__ratings {
      margin: 2rem auto 2rem auto!important;
      display: grid;
      grid-template-columns: 1fr!important;
      grid-rows-columns: repeat(6, 1fr)!important;
      grid-gap: 1rem 2rem;
      width: 85%;
      @media only screen and (min-width: $bp-desktop) {
        margin: 1rem 0!important;
        grid-template-columns: repeat(2, 1fr)!important;
        width: 95%; } }
    &__inputContainer {
      @include inputContainerStyle;
      margin-top: 2.5rem!important;
      width: 100% !important;
      @media only screen and (min-width: $bp-desktop) {
        margin-top: 1.5rem!important; }
      &__label {
        @include labelStyle;
        color: $colorNaranja;
        width: 100% !important;
        margin-top: -2.5rem;
        padding: 1rem 0;
        @media only screen and (min-width: $bp-desktop) {
          margin-top: -1.5rem; } }
      &__textarea {
        @include textareaStyle;
        font-size: 1rem!important;
        width: 100%!important;
        color: #000!important;
        padding: .5rem 0;
        @media only screen and (min-width: $bp-desktop) {
          padding: 1rem 0; } }
      &__counter {
        color: #999999;
        font-size: 12px; }
      &__counterLimit {
        color: red!important;
        font-size: 12px; } } }
  &__btn {
    @include btn-primary;
    box-sizing: border-box;
    border: 1px solid #FFFFFF!important;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 25%; }
  &__right {
    display: none;
    position: relative;
    background: linear-gradient(0deg, #00000033, #00000033), url('../../static/edit_user.png') no-repeat;
    background-position: center;
    background-size: cover;
    @media (min-width: $bp-desktop) {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 0!important; } }
  &__tagline {
    font-family: $font-family-titles;
    color: $colorAmarilloNew;
    position: absolute;
    text-align: center;
    font-size: 3rem;
    bottom: 0;
    margin-bottom: 15%; } }
