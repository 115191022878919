@import "../../config";

.EasyHousing {
  width: 100%!important;
  height: 100vh!important;
  color: #ffffff;
  background: $colorGrisNew;
  display: flex;
  flex-flow: column;
  &__headline {
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    @media (min-width: $bp-desktop) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr; }
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      @media (min-width: $bp-desktop) {
        height: auto; } }
    &__article {
      width: 90%;
      @media (min-width: $bp-desktop) {
        width: 66%; }
      &__title {
        font-family: $font-family-titles;
        font-size: 3rem!important;
        line-height: 95%;
        @media (min-width: $bp-desktop) {
          font-size: 4rem!important;
          margin-bottom: 3rem!important; }
        strong {
          font-family: $font-family-titles;
          color: $colorNaranja!important; }
        &__orange {
          color: $colorNaranja!important;
          margin: 0!important;
          @media (min-width: $bp-desktop) {
            margin-bottom: 3rem!important; } } }
      &__cta {
        @include CTAOnLanding;
        font-weight: 400!important;
        padding: 1rem 3.25rem!important;
        display: none;
        @media (min-width: $bp-desktop) {
          display: inline; } } }
    &__quote {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../../static/RentLanding/quote.jpg");
      background-size: cover;
      background-position: center 60%;
      display: flex!important;
      flex-flow: column!important;
      justify-content: center!important;
      align-items: center!important;
      height: 100%;
      @media (min-width: $bp-desktop) {
        height: auto; }
      &__container {
        display: flex!important;
        flex-flow: column!important;
        justify-content: center!important;
        width: 95%;
        @media (min-width: $bp-desktop) {
          width: 66%; }
        &__text {
          font-family: $font-family-titles;
          font-size: 2rem;
          line-height: 95%;
          @media (min-width: $bp-desktop) {
            font-size: 4rem; } }
        &__names {
          font-family: $font-family-base;
          color: $colorNaranja;
          font-size: 1.25rem;
          font-weight: 500;
          @media (min-width: $bp-desktop) {
            margin-top: 20px;
            font-size: 1.75rem; } } } } } }
