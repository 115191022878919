@import "../../../assets/styles/_config.sass";
.reservationsErrorModal {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 120px 50px 0 50px;
    font-size: 18px;
    text-shadow: 1px 1px 5px black;
    @media (max-width: $bp-cell) {
        margin-top: 100px; } }
