@import "../../config";

.EasyHousingNumbers {
  display: flex;
  justify-content: center;
  background: $colorGrisNew;
  color: #fff;
  &__description {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto!important;
    padding: 4rem 0!important;
    @media (min-width: $bp-desktop) {
      padding: 0!important;
      height: 100vh!important; }
    &__container {
      display: flex;
      flex-flow: column;
      width: 90%;
      max-width: 100vw!important;
      @media (min-width: $bp-desktop) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2rem;
        place-items: center;
        width: 92%;
        max-width: 1200px!important; } }
    &__card {
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding-left: 10%!important;
      align-items: flex-start;
      margin-bottom: 2rem;
      @media (min-width: $bp-desktop) {
        align-items: flex-start;
        padding: 0!important; }
      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-family-titles;
        font-size: 6rem;
        border-radius: 100%;
        line-height: 90%;
        padding: 0!important;
        color: $colorNaranja; }
      &-title {
        font-size: 2rem;
        color: $colorNaranja;
        font-family: $font-family-titles;
        line-height: 110%;
        width: 70%;
        margin-bottom: .5rem;
        @media (min-width: $bp-desktop) {
          white-space: nowrap; }
        b {
          font-size: 1.5em;
          color: $colorNaranja;
          font-family: $font-family-titles; } }
      &-desc {
        margin-top: 25px;
        font-family: $font-family-base;
        font-size: 1rem;
        width: 80%;
        text-align: justify;
        font-weight: 400;
        line-height: 150%; } } } }
