
// Google Fonts
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400;1,500);
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');

// Fuentes de Max
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@font-face {
  font-family: 'CastaBold';
  src: local('Casta');
  src: url(../../fonts/Casta/Bold/casta.woff) format('woff');
  src: url(../../fonts/Casta/Bold/casta.woff2) format('woff2'); }

@font-face {
  font-family: 'CastaExtraBold';
  src: local('Casta');
  src: url(../../fonts/Casta/ExtraBold/casta.woff) format('woff');
  src: url(../../fonts/Casta/ExtraBold/casta.woff2) format('woff2'); }

@font-face {
  font-family: 'FontTimes';
  src: local('Times');
  src: url(../../fonts/Times/Times-New-Roman.ttf) format('ttf'); }

@font-face {
  font-family: 'RobotoBold';
  src: local('RobotoBold');
  src: url(../../fonts/Roboto-Bold.ttf) format('ttf');
  src: url(../../fonts/Roboto-Bold.woff) format('woff');
  src: url(../../fonts/Roboto-Bold.woff2) format('woff2'); }
@font-face {
  font-family: 'RobotoRegular';
  src: local('RobotoRegular');
  src: url(../../fonts/Roboto-Bold.ttf) format('ttf');
  src: url(../../fonts/Roboto-Bold.woff) format('woff');
  src: url(../../fonts/Roboto-Bold.woff2) format('woff2'); }

@font-face {
  font-family: 'AloharIcons';
  src: local('AloharIcons');
  src: url('../../fonts/Alohar/AloharIcons.eot') format('embedded-opentype');
  src: url('../../fonts/Alohar/AloharIcons.woff2') format('woff2');
  src: url('../../fonts/Alohar/AloharIcons.woff') format('woff');
  src: url('../../fonts/Alohar/AloharIcons.ttf') format('truetype');
  src: url('../../fonts/Alohar/AloharIcons.svg') format('svg');
  font-weight: normal;
  font-style: normal; }

// Elementos armados
// @include button_orange

// Constantes
$colorNaranja: #FF4E00;
$colorNaranjaHover: #e95211e7;
$colorNaranjaError: #FF3D00;
$colorVioleta: #B580FF;
$colorBlanco: #FFF7E9;
$colorNegro: #2b2b2b;
$colorGris: #999999;
$colorVerde: #4CAF50;
$colorNaranja: #FF4E00;
$colorNaranjaHover: #e95211e7;
$colorNaranjaError: #FF3D00;
$colorVioleta: #B580FF;
$colorBlanco: #FFF7E9;
$colorNegro: #2b2b2b;
$colorGris: #999999;
$colorVerde: #4CAF50;
// Colores Nueva Imágen => Max
$colorNegroNew: #2b2b2b;
$colorGrisNew: #3C3C3B;
$colorAmarilloNew: #FFFFA4;
$colorCianNew: #96F9FF;
$colorNegroNew: #2b2b2b;
$colorGrisNew: #3C3C3B;
$colorAmarilloNew: #FFFFA4;
$colorCianNew: #96F9FF;
// Fuentes Nueva Imágen => Max
$font-family-base: 'RobotoBold', sans-serif;
$font-family-titles: 'CastaExtraBold', sans-serif;
$font-family-times: 'FontTimes';
$font-family-RobotoBold: 'RobotoBold', sans-serif;
// Otros
$border_radius: 100px;
$radio-responsive: calc(2 - 5vh);
$border_radius: 100px;
$radio-responsive: calc(2 - 5vh);
$desktop-height: calc(100vh - 7rem);
$mobile-height: calc(100vh - 65px);

// Colores Overlay
$overlayNaranja40: #FF4E0066;
$overlayNaranja15: #FF4E0024;
$overlayNaranja10: #FF4E0010;
$overlayNaranja40: #FF4E0066;
$overlayNaranja15: #FF4E0024;
$overlayNaranja10: #FF4E0010;

a:visited {
  color: inherit; }

//Breakpoints para media querys
$bp-cell: 600px;
$bp-tablet: 900px;
$bp-desktop: 1024px;
$bp-big-display: 1600px;
$bp-cell: 600px;
$bp-tablet: 900px;
$bp-desktop: 1024px;
$bp-big-display: 1600px;

a {
  &:hover {
    color: initial; } }
.bg-primary {
  background-color: $colorNaranja;
  &:hover {
    background-color: $colorNaranjaHover; } }

.blur {
  filter: blur(10px); }
* {
  margin: 0px;
  padding: 0px;
  font-family: $font-family-base;
  &:focus {
    outline: 0 !important;
    outline: 0 !important; } }

p {
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  color: inherit; }

.visble {
  display: block!important; }

.BlankSpace {
  display: block;
  @media (min-width: $bp-desktop) {
    display: none; } }

input:focus {
  outline: none !important; }

button {
  cursor: pointer;
  outline: 0;
  border: 0;
  outline: 0;
  border: 0;
  background: none; }

input[type="button"]::-moz-focus-inner {
  border: 0;
  border: 0; }

.m_t_200 {
  margin-top: 200px; }

.m_t_150 {
  margin-top: 150px; }

// Mixin CTA on landing ( BUTTON )
@mixin CTAOnLanding {
  background: $colorNaranja;
  font-family: $font-family-base;
  border-radius: 100px;
  margin: 0 auto;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  line-height: 120%;
  padding: 1rem 2rem;
  text-align: center;
  background: $colorNaranja;
  font-family: $font-family-base;
  border-radius: 100px;
  margin: 0 auto;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  line-height: 120%;
  padding: 1rem 2rem;
  text-align: center; }

// Mixin Titles on landing
@mixin titleOnLanding {
  color: $colorNegro;
  font-family: $font-family-titles;
  font-size: 3em;
  line-height: 110%;
  font-weight: 500; }

// Mixin Muted on landing
@mixin mutedOnLanding {
  color: $colorGris;
  font-family: $font-family-base;
  font-size: 1em;
  font-weight: 500;
  letter-spacing: .1em;
  line-height: 1.25em;
  text-transform: uppercase;
  margin-bottom: 1em; }

// Buttons Mixins
@mixin btn {
  padding: 1rem 3rem!important;
  font-size: 1rem!important;
  border-radius: 100px!important;
  font-family: $font-family-base!important;
  font-weight: 500!important; }

@mixin btn-primary {
  @include btn;
  color: #fff!important;
  background: $colorNaranja!important; }

@mixin btn-secondary {
  @include btn;
  color: #666!important;
  border: 2px solid #666!important; }

@mixin btn-primary-props($font-size, $topbottom, $leftright) {
  padding: $topbottom $leftright;
  background: $colorNaranja;
  border: none;
  box-sizing: border-box;
  border-radius: 3rem;
  font-size: $font-size;
  line-height: 120%;
  text-align: center;
  color: #fff; }

@mixin btn-outline($font-size, $topbottom, $leftright) {
  padding: $topbottom $leftright;
  background: none;
  border: 1px solid $colorNaranja!important;
  box-sizing: border-box;
  border-radius: 3rem;
  font-size: $font-size;
  line-height: 120%;
  text-align: center;
  color: $colorNaranja; }

@mixin container {
  box-sizing: border-box!important;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
  padding: 35px 0 74px 0;
  max-width: 100%!important;
  margin: 0 auto;
  width: 95%;
  min-height: 70vh;
  @media (min-width: $bp-desktop) {
    padding: 35px 0 50px 0;
    max-width: 1200px!important;
    min-height: $desktop-height; } }

// Steps Mixins
@mixin stepContainer {
  box-sizing: border-box!important;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  padding: 35px 0 74px 0;
  max-width: 100%!important;
  margin: 0 auto;
  width: 95%;
  min-height: 70vh;
  @media (min-width: $bp-desktop) {
    padding: 35px 0 50px 0;
    max-width: 1200px!important;
    min-height: $desktop-height; } }

@mixin stepTitle {
  font-size: 2em!important;
  font-family: $font-family-base;
  font-weight: 700!important;
  color: $colorNegro;
  line-height: 110%; }

@mixin stepSubtitle {
  font-size: 1.25em;
  font-family: $font-family-base;
  font-weight: 400;
  color: $colorNegro;
  line-height: 110%; }

@mixin stepDescription {
  font-size: 1em;
  font-family: $font-family-base;
  font-weight: normal;
  color: #fff;//$colorGris
  line-height: 150%; }

@mixin normalText($color) {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  color: $color;
  line-height: 150%; }

@mixin titles($color) {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 700;
  font-size: 3.125rem;
  color: $color;
  line-height: 110%; }

@mixin placeholder() {
  input::-webkit-input-placeholder {
    @content; }
  input:-moz-placeholder {
    @content; }
  input::-moz-placeholder {
    @content; }
  input:-ms-input-placeholder {
    @content; } }

// Input Container (General Input as a DIV.inputcontainer > input || > input + label)
@mixin inputContainerStyle {
  position: relative; }

@mixin labelStyle {
  position: absolute;
  top: 0;
  padding: .75rem 1rem;
  font-family: $font-family-base;
  font-style: normal;
  font-weight: bold;
  font-size: .625rem;
  color: currentColor;
  pointer-events: none;
  line-height: 120%;
  letter-spacing: 0.1rem; }

@mixin inputPrefixStyle {
  position: absolute;
  padding: 2rem 1.5rem 1rem 1rem;
  font-family: $font-family-base;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  color: #666;
  pointer-events: none;
  line-height: 120%; }

@mixin inputStyle {
  padding: 2rem 1.5rem 1rem 1rem;
  background: none;
  box-sizing: border-box;
  font-size: 1.25rem;
  color: currentColor!important;
  font-family: $font-family-base;
  border-style: none!important;
  border-bottom: 1px solid #968280!important;
  &:focus {
    border-bottom: 1px solid $colorNaranja!important; }
  &:not([value=""]) {
    border-bottom: 1px solid $colorNaranja!important; }
  &::-webkit-calendar-picker-indicator {
    color: $colorNaranja!important;
    border-radius: 100%; }
  &::placeholder {
    color: #968280!important; } }

@mixin textareaStyle {
  @include inputStyle;
  resize: none;
  overflow: hidden; }

@mixin selectStyle {
  background: url('../static/select.svg') no-repeat right;
  background-color: none!important;
  background-position: calc( 100% - 10px) 50%;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  appearence: none;
  // -webkit-appearance: none;
  padding: 2rem 1.5rem 1rem 1rem;
  border: none;
  border-bottom: 1px solid #968280!important;
  box-sizing: border-box;
  font-size: 1.25rem;
  color: #FFF;
  font-family: $font-family-base;
  min-width: 200px;
  border-style: none!important;
  &:focus {
    border-bottom: 1px solid $colorNaranja!important; }
  &:not([value=""]) {
    border-bottom: 1px solid $colorNaranja!important; }
  option {
    color: #000; } }

// Mixin for simple overlay on 40% $colorNaranja
@mixin overlay($color) {
  background: $color;
  width: 100%!important;
  height: 100%!important; }

.bg-grey {
  background: $colorNegroNew;
  color: #FFF; }

.Offline {
  background: url('../static/Landing/backgrounds/offlineMobile.jpg') no-repeat center center;
  background-color: $colorNaranja;
  background-size: cover;
  box-sizing: border-box;
  max-height: 100vh;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  @media only screen and (min-width: $bp-desktop) {
    background: url('../static/Landing/backgrounds/offline.png') no-repeat; } }

.AloharBButton {
  // position: fixed
  z-index: 100;
  display: grid;
  place-items: center;
  padding: 0;
  background: none;
  color: $colorNaranja;
  @media only screen and (min-width: $bp-desktop) {
    display: none; } }

.display-none {
  display: none!important; }

.SimpleNavbar {
  position: absolute;
  z-index: 200;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  img {
    max-width: 250px;
    margin-top: 50px; }

 }  // Feather Icons

// base class definition
.RobotoBold {
  font-family: $font-family-RobotoBold; }
.feather {}
.feather {
  display: inline-block;
  font: normal normal normal 1em/1 'AloharIcons';
  color: inherit;
  flex-shrink: 0;
  speak: none;
  text-transform: none; }

.feather-sm {}
.feather-sm {
  font-size: 0.8em; }

.feather-lg {}
.feather-lg {
  font-size: 1.2em; }

.feather-16 {
  font-size: 16px; }

.feather-32 {}
.feather-32 {
  font-size: 32px; }

// spinning icons
.feather-is-spinning {
  animation: feather-spin 1s infinite linear;
  animation: feather-spin 1s infinite linear; }

@keyframes feather-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

// rotated/flipped icons

.feather-rotate-90 {
  transform: rotate(90deg); }

.feather-rotate-180 {
  transform: rotate(180deg); }

.feather-rotate-270 {
  transform: rotate(270deg); }

.feather-flip-y {
  transform: scaleY(-1); }

.feather-flip-x {
  transform: scaleX(-1); }

// icons

.icon-lock::before {
  content: "\eb21"; }

.icon-search::before {
  content: "\eb22"; }

.icon-dollars::before {
  content: "\eb23"; }

.icon-home::before {
  content: "\eb24"; }

.icon-user::before {
  content: "\eb25"; }

.icon-facebook::before {
  content: "\eb26"; }

.icon-instagram::before {
  content: "\eb27"; }

.icon-linkedin::before {
  content: "\eb28"; }

.icon-users::before {
  content: "\eb29"; }

.icon-filter::before {
  content: "\eb2a"; }

.icon-x::before {
  content: "\eb2b"; }

.icon-briefcase::before {
  content: "\eb2c"; }

.icon-bell-off::before {
  content: "\eb2d"; }

.icon-edit::before {
  content: "\eb2e"; }

.icon-compass::before {
  content: "\eb2f"; }
