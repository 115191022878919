@import "../../config";

/**

  display: flex

  **/

.PriceFilter {
    background: none;
    text-align: left;
    border-radius: 10px 10px 0 0;
    border: 1px solid transparent!important;
    padding: .5rem 20px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    font-family: $font-family-base;
    font-weight: 700;
    font-size: 14px;
    color: $colorNegro;
    max-width: 100px;
    width: 70px;
    @media only screen and (max-width: $bp-cell) {
        font-weight: 500;
        font-size: 1rem;
        line-height: 18px; }
    &__icon {
        display: none;
        margin-bottom: 2px;
        height: .5rem;
        transition: all 100ms ease-in-out;
        &.up {
            display: block;
            transition: all 150ms ease-in-out;
            transform: rotate(180deg); }
        &.down {
            display: block;
            transition: all 100ms ease-in-out; } } }
