.bankAccount {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    width: 100%;
    &__modal {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 300px;
        &__title {
            text-align: center; } }
    &__title {
        font-size: 34px;
        font-family: "CastaExtraBold"; }
    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center; } }
