@import "../../config";
// .fc .fc-col-header-cell-cushion
//   display: none
// .fc .fc-daygrid-day.fc-day-today
//   background: none
.calendarResponsive {
  background: #fff;
  @media (max-width: $bp-cell) {
    padding-top: 100px; }
  &__containerCalendar {
    width: 100%;
    height: 75vh;
    &__buttons {
      display: flex;
      justify-content: start;
      gap: 20px;
      color: #000; }
    &__fullcalendar {
      margin: 20px 0;
      width: 100%;
      position: relative;
      display: flex;
      flex-flow: column-reverse;
      margin-bottom: 0rem;
      &__buttons {
        position: absolute;
        right: 0;
        bottom: -30%;
        width: 100%;
        padding-bottom: 5%;
        &__btn {
          @include btn-outline(1rem, 1rem, 3rem);
          box-sizing: border-box;
          border: 1px solid $colorNaranja!important;
          color: $colorNaranja;
          background: #fff;
          box-shadow: 0px 4px 6px rgba(43, 43, 43, 0.3);
          width: 100%;
          @media (min-width: $bp-desktop) {
            width: 150px;
            float: right; } } } } }
  &__progresBar {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    @media (min-width: $bp-desktop) {
      padding: 20px 0 0 0; } }
  &__container {
    display: grid;
    max-width: 1200px;
    margin: 0 auto;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    height: 85vh;
    padding: 0 20px;
    @media (min-width: $bp-desktop) {
      padding: 0;
      grid-template-columns: 45% 1fr; }
    &__Calendar {
      position: relative;
      &__titles {
        &__title {
          color: #000;
          font-weight: 700;
          font-family: $font-family-base;
          font-size: 1.5rem!important;
          margin: 0;
          padding: 0; }
        &__description {
          color: #000;
          font-weight: 400;
          font-family: $font-family-base;
          font-size: 1.2|rem; } }
      .displayNone {
        display: none; }
      &__btn-secondary {
        @include btn-outline(.9375rem, .5rem, 1rem);
        margin: 0!important;
        border: 1px solid $colorGris; }
      &__btn-primary {
        @include btn-outline(.9375rem, .5rem, 1rem);
        margin: 0!important;
        margin-right: 15px!important;
        color: $colorBlanco!important;
        background-color: $colorNaranja;
        border-color: $colorNaranja; }

      &__sidebar {
        display: block;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 40px;
        @media (min-width: $bp-desktop) {
          margin-bottom: 0;
          padding-top: 20px;
          width: 100%; }
        &__bar {
          // position: fixed
          position: block;
          // height: 100vh
          // width: 35%
          background: none;
          &__btn {
            margin: 2rem 1.5rem 1rem 1.5rem;
            background: none; }
          &__container {
            display: flex;
            flex-flow: column;
            &__dates,
            &__radiobuttons,
            &__price {
              display: flex;
              flex-flow: column; }
            &__buttons {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 10px;
              align-items: center;
              width: 100%; }
            &__info {
              margin-top: 2rem!important;
              margin-bottom: 1.2rem!important;
              color: $colorNaranja;
              font-size: .8rem; }
            &__price {
              &__ccontainer {
                display: flex;
                grid-gap: 20px;
                @media (max-width: $bp-desktop) {
                  display: grid; }

                &__mobile {
                  display: none !important;
                  margin-bottom: 25px;
                  @media (max-width: $bp-desktop) {
                    display: block !important;
                    width: 100%;
                    min-height: 50px; } } }
              &__ccontainer>div >div>div>div {
                width: 45%;
                @media (min-width: $bp-desktop) {
                  width: 50%; } }
              &__inputContainer {
                @include inputContainerStyle;
                width: 100%;
                margin-bottom: 1.25rem;
                font-size: 1rem;
                &__input {
                  @include inputStyle;
                  width: 100%;
                  color: #000!important;
                  padding: 1.5rem 0 .5rem 0;
                  font-size: 1rem; }
                &__input-webkit-outer-spin-button,
                &__input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0; }
                &__input[type="number"] {
                  padding: 1.8rem 0 .5rem 1rem; }
                &__prefix {
                  @include inputPrefixStyle;
                  padding-left: 0;
                  color: #000;
                  font-size: 1rem; }
                &__label {
                  @include labelStyle;
                  color: $colorNaranja;
                  padding-left: 0;
                  font-size: .8rem;
                  padding: .5rem 0rem;
                  letter-spacing: 0; } } }
            &__title {
              color: #000;
              font-size: 1.5rem; }
            &__containerInputToggle {
              display: grid;
              grid-template-columns: 1fr 1fr;
              &__toogleContainer {
                width: 200px;
                margin-bottom: 1.2rem;
                &__label {
                  display: grid;
                  grid-template-columns: 50px 100px;
                  cursor: pointer;
                  position: relative;
                  width: 45px;
                  height: 24px;
                  grid-gap: .8rem;
                  &__text {
                    color: #000;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    &__title {
                      color: #000;
                      font-size: 1rem;
                      font-weight: 500;
                      font-family: $font-family-base; } }
                  &__inputToggle {
                    opacity: 0;
                    width: 0;
                    height: 0; }
                  &__slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #DDDDDD;
                    -webkit-transition: .4s;
                    border-radius: 24px;
                    transition: .4s;
                    &:before {
                      position: absolute;
                      content: "";
                      height: 24px;
                      width: 23px;
                      left: 3px;
                      bottom: 0;
                      background-color: white;
                      -webkit-transition: .4s;
                      transition: .4s;
                      border-radius: 50%; } }
                  &__inputToggle:not(:checked) + &__slider {
                    &:before {
                      background-color: #fff;
                      border: 1px solid rgba(0, 0, 0, 0.08); } }
                  &__inputToggle:checked + &__slider {
                    background-color: $colorNaranja;
                    &:before {
                      border: 1px solid rgba(0, 0, 0, 0.08);
                      -webkit-transform: translateX(16px);
                      -ms-transform: translateX(16px);
                      transform: translateX(16px); } } } } } } } } } } }

.basePriceCalendar {
  font-size: .6rem;
  color: #bebebe;
  position: absolute;
  left: 30%;
  margin-bottom: 5px;
  bottom: 0;
  @media (min-width: $bp-desktop) {
    font-size: 1rem;
    bottom: 10%; } }
.fc-media-screen {
  height: 80%;
  position: relative!important; }
// titulo del calendario
.fc-toolbar-title {
  color: $colorNaranja; }

// barra padre de atras y siguiente
.fc-header-toolbar {}

// barra atras y siguiente
.fc-daygrid-day {
  height: 30px!important;
  width: 30px!important; }

// celda padre
.fc-scrollgrid-sync-inner {
  position: relative;
  cursor: pointer; }

// celdas
.fc-daygrid-day-number {
  position: absolute;
  color: #000!important;
  font-size: .8rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-5%);

  &__sidebar.activated {
    // visibility: visible
    // opacity: 1
 } }    // transition: visibility 0.2s ease-in-out, opacity .2s ease-in-out
.fc-toolbar-title {
  text-transform: uppercase;
  font-family: $font-family-titles; }
.fc-col-header-cell-cushion {
  text-transform: capitalize;
  color: #9C9C9C; }

.fc-h-event {
  background: $colorVioleta;
  border-color: $colorVioleta; }
.fc-h-event:hover {
  background: #FF3D00;
  border-color: #FF3D00; }
.fc-button.fc-button-primary {
  color: $colorBlanco;
  background-color: $colorNaranja;
  border-color: $colorNaranja; }
.fc-button.fc-button-primary:hover {
  color: $colorBlanco;
  background-color: $colorNaranja;
  border-color: $colorNaranja; }
.fc-button.fc-button-primary:active {
  color: $colorBlanco!important;
  background-color: $colorNaranja!important;
  border-color: $colorNaranja!important; }
.fc .fc-button-primary:focus {
  box-shadow: none!important; }
.fc-scrollgrid-liquid {
  height: 100%!important; }

// titulo del calendario
.fc-toolbar-title {
  color: $colorNaranja; }

// barra padre de atras y siguiente
.fc-header-toolbar {}

// barra atras y siguiente
.fc-daygrid-day {
  height: 20px!important;
  width: 20px!important; }

@keyframes animation-2 {
  0% {
    transform: scaleX(0); }
  100% {
    transform: scaleX(1); } }

.fc .fc-daygrid-day-frame {
  @media (max-width: $bp-desktop) {
    min-height: 55px !important; } }
