@import "../../config";

.PropertyProperties {
  margin: 3rem 0;
  width: 100%;
  &__title {
    @include stepSubtitle;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 160%;
    margin-bottom: 2.5rem; }
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.25rem; } }
