.TextCard {
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.125rem;
    line-height: 120%;
    color: #666; }
