@import "../config";

.Terms {
  @include container;
  &__title {
    font-size: 2rem;
    color: $colorNaranja;
    font-family: $font-family-titles;
    margin-bottom: 1rem;
    @media only screen and (min-width: $bp-desktop) {
      font-size: 3.5rem;
      margin-bottom: 2rem; } }
  &__subtitle {
    font-size: 1.5rem;
    color: $colorNaranja;
    font-family: $font-family-titles;
    margin-bottom: .75rem;
    @media only screen and (min-width: $bp-desktop) {
      font-size: 2rem;
      margin-bottom: 1rem; } }
  &__text {
    font-size: 1rem;
    color: #000;
    margin-bottom: .75rem;
    @media only screen and (min-width: $bp-desktop) {
      margin-bottom: 1rem; }
    b, a, a:visited {
      color: $colorNaranja;
      font-weight: 500; }
    a:hover {
      text-decoration: underline!important; }
    ul {
      margin-top: .75rem;
      list-style-position: inside;
      @media only screen and (min-width: $bp-desktop) {
        margin-top: 1rem; } } } }
